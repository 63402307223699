<script>
import { setupVueI18nMessages } from '/renderer/config/i18n'
import fr from '/renderer/lang/plugins/modal/fr.json'
import en_GB from '/renderer/lang/plugins/modal/en_GB.json'
const translations = { fr, en_GB }

export default {
    i18n: setupVueI18nMessages(translations),
    components: {},
    props: {
        labelField: {
            type: String,
        },
    },
    computed: {
        deleteLabel() {
            return undefined != this.object &&
                undefined != this.object[this.labelField]
                ? this.object[this.labelField]
                : this.$t('modal.confirmation-delete.element')
        },
    },
    methods: {
        onDelete(callback) {
            this.callback = callback
        },
        show(object) {
            this.object = object
            this.$refs.deleteConfirmationModal.show()
        },
        closeModal() {
            this.$refs.deleteConfirmationModal.hide()
        },
        confirmDelete() {
            this.$emit('on-delete', this.object)
            this.callback(this.object)
            this.object = {}
            this.closeModal()
        },
        cancel() {
            this.closeModal()
        },
    },
    data() {
        return {
            object: {},
            callback: () => {},
        }
    },
}
</script>

<template>
    <b-modal
        class="modal__delete"
        :title="$t('modal.confirmation-delete.title')"
        size="sm"
        hide-header-close
        ref="deleteConfirmationModal"
    >
        <div class="h3">
            {{
                $t('modal.confirmation-delete.subtitle', { label: deleteLabel })
            }}
        </div>

        <slot name="content"></slot>

        <template #modal-footer>
            <button type="button" class="btn btn-link" @click.prevent="cancel">
                {{ $t('common.action.cancel') }}
            </button>
            <button
                type="button"
                class="btn btn-danger"
                @click.prevent="confirmDelete"
            >
                {{ $t('modal.confirmation-delete.confirm') }}
            </button>
        </template>
    </b-modal>
</template>

<style lang="scss"></style>
