import Range from './range'

const POOL_SIZE = 10

class RangeManager {
    constructor() {
        this.pool = []
        this.cells = []
        this.grid_size = 0
    }

    generate() {
        this.grid_size = Math.sqrt(this.cells.length) >> 0

        for (let i = 0; i < POOL_SIZE; i++) this.generateOne(i)
    }

    generateOne(index) {
        const range = new Range(index, this.grid_size, this.cells)
        this.pool.push(range)

        return range
    }

    getNextFree() {
        return (
            this.pool.find(range => !range.is_used) ||
            this.generateOne(this.pool.length)
        )
    }
}

export default RangeManager
