<script>
import VSwatches from 'vue-swatches'
import swatchConfig from '/renderer/lib/swatch'

export default {
    components: { VSwatches },
    props: {
        value: { type: String },
        inline: { type: Boolean, default: false },
        forbiddenColors: { type: Array, default: () => [] },
    },
    computed: {
        colors() {
            let colors = []

            this.swatch_config.colors.forEach(group => {
                let g = []

                group.forEach(color => {
                    if (this.forbiddenColors.includes(color))
                        color = { color, disabled: true }
                    g.push(color)
                })

                colors.push(g)
            })

            return colors
        },
    },
    data() {
        return {
            swatch_config: swatchConfig,
        }
    },
}
</script>

<template>
    <VSwatches
        :swatches="colors"
        :swatch-size="swatch_config.swatchSize"
        :spacing-size="swatch_config.spacingSize"
        :row-length="swatch_config.rowLength"
        :show-border="swatch_config.showBorder"
        :swatch-style="swatch_config.watchStyle"
        :trigger-style="swatch_config.triggerStyle"
        :wrapper-style="swatch_config.wrapperStyle"
        :inline="inline"
        :value="value || ''"
        @input="$emit('input', $event)"
    ></VSwatches>
</template>

<style>
@import 'vue-swatches/dist/vue-swatches.css';
</style>
