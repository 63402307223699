import Toasted from 'vue-toasted'

let is_silent = false

const getIconClass = icon => 'mr-2 zmdi zmdi-' + icon,
    infoToastOption = {
        type: 'info',
        duration: 8000,
        icon: getIconClass('info-outline'),
        closeOnSwipe: true,
    },
    NotifyPlugin = {
        install(Vue) {
            Vue.use(Toasted, {
                position: 'bottom-center',
                duration: 3000,
                iconPack: 'custom-class',
            })

            Vue.toasted.register('error', payload => payload, {
                type: 'error',
                duration: 8000,
                closeOnSwipe: true,
                icon: getIconClass('alert-circle-o'),
                action: {
                    icon: getIconClass('close-circle-o'),
                    onClick: (e, toastObject) => toastObject.goAway(0),
                },
            })

            Vue.toasted.register('success', payload => payload, {
                type: 'success',
                icon: getIconClass('check'),
            })

            Vue.toasted.register('info', payload => payload, {
                ...infoToastOption,
                action: [
                    {
                        text: 'OK',
                        onClick: (e, toastObject) => toastObject.goAway(0),
                    },
                ],
            })

            Vue.notify = Vue.prototype.$notify = {
                getOption: type => {
                    switch (type) {
                        case 'info':
                            return infoToastOption
                        default:
                            return undefined
                    }
                },
                show: Vue.toasted.show,
                success: msg => {
                    if (is_silent) {
                        console.debug('Notify is silent, got success:', msg)
                        return
                    }
                    Vue.toasted.global.success(msg)
                },
                error: msg => {
                    if (is_silent) {
                        console.debug('Notify is silent, got error:', msg)
                        return
                    }

                    Vue.toasted.global.error(msg)
                    console.error(msg)
                },
                info: msg => {
                    if (is_silent) {
                        console.debug('Notify is silent, got info:', msg)
                        return
                    }
                    Vue.toasted.global.info(msg)
                },
                mute: () => (is_silent = true),
                unmute: () => (is_silent = false),
            }
        },
    }

export default NotifyPlugin
