/**
 * The file enables to import all vue plugins
 * in a one-shot manner. There should not be any reason to edit this file.
 */

// const files = require.context('.', false, /\.js$/)
// const modules = {}

// files.keys().forEach(key => {
//   if (key === './index.js') return
//   modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
// })

import bootstrap_vue from './bootstrap_vue'
import breadcrumb from './breadcrumb'
import configuration from './configuration'
import counter from './counter'
import custom_badge from './custom_badge'
import data_list from './data_list'
import draggable from './draggable'
import empty_placeholder from './empty_placeholder'
import filters from './filters'
import input_color from './input_color'
import konva_business from './konva_business'
import lang_switcher from './lang_switcher'
import modal from './modal'
import notify from './notify'
import rand_button from './rand_button'
import software_container from './software_container'
import spinner from './spinner'
import vue_konva from './vue_konva'
import vue_luxon from './vue_luxon'
import vue_meta from './vue_meta'
import vue_shortkey from './vue_shortkey'

export default {
    bootstrap_vue,
    breadcrumb,
    configuration,
    counter,
    custom_badge,
    data_list,
    draggable,
    empty_placeholder,
    filters,
    input_color,
    konva_business,
    lang_switcher,
    modal,
    notify,
    rand_button,
    software_container,
    spinner,
    vue_konva,
    vue_luxon,
    vue_meta,
    vue_shortkey,
}

// export default modules
