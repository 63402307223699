<script>
import { ISO_LOCALE_LIST, setLanguage } from '/renderer/config/i18n.js'

export default {
    props: {
        size: {
            type: String,
            default: 'lg',
        },
        withFlag: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        supported_locale_list() {
            return ISO_LOCALE_LIST
        },
        current_locale() {
            return this.$i18n.locale
        },
        locale_text() {
            return this.extractLocaleFromIso(this.current_locale).toUpperCase()
        },
    },
    methods: {
        switchToLocale(locale) {
            setLanguage(locale)
            this.$configuration.set('app_lang', locale)
        },
        extractLocaleFromIso(iso) {
            const isoParts = iso.split('_')
            return isoParts.length > 1 ? isoParts[1] : isoParts[0]
        },
        getClass(iso) {
            const flagLocale = this.extractLocaleFromIso(iso).toLowerCase()
            return `flag-icon-${flagLocale}`
        },
    },
}
</script>

<template>
    <b-dropdown
        class="lang-switcher"
        :size="size"
        variant="light"
        :toggle-class="{ withFlag: withFlag, 'text-decoration-none': true }"
        no-caret
    >
        <template #button-content>
            <span
                v-if="withFlag"
                class="flag-icon"
                :class="getClass(current_locale)"
            ></span>
            <span v-else>{{ locale_text }}</span>
        </template>

        <b-dropdown-item
            v-for="(name, locale) in supported_locale_list"
            :key="locale"
            :disabled="locale === current_locale"
            @click.prevent="switchToLocale(locale)"
        >
            <span class="flag-icon" :class="getClass(locale)"></span>
            {{ name }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<style lang="scss" scoped>
.dropdown-toggle {
    .flag-icon {
        height: 3em;
        width: 3em;
    }
}
</style>

<style lang="scss">
.lang-switcher {
    button.withFlag {
        transform: scale(0.5);
    }
}
</style>
