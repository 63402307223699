<script>
import RangeCellsDataFieldsFragment from '/renderer/apollo/fragments/range_cells_data_fields_fragment.graphql'
import AddRangeCellsDataMutation from '/renderer/apollo/range_cells_data/AddRangeCellsDataMutation.graphql'
import UpdateRangeCellsDataMutation from '/renderer/apollo/range_cells_data/UpdateRangeCellsDataMutation.graphql'

export default {
    props: {
        layer: { required: true, type: Object },
        range: { required: true, type: Object },
        followMouse: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
    },
    computed: {
        layer_node() {
            return this.layer.getNode()
        },
        group_node() {
            return !!this.rangeGrid ? this.rangeGrid.getNode() : null
        },
        title_node() {
            return !!this.rangeGrid ? this.rangeGrid.getTitleNode() : null
        },
        draggable() {
            return (
                !this.can_paint &&
                !this.range.locked &&
                !!this.rangeGrid &&
                this.rangeGrid.is_hovering
            )
        },
        grid_size() {
            return !!this.rangeGrid ? this.rangeGrid.grid_size : 0
        },
        can_paint() {
            return !!this.paint_model
        },
        range_cells_data() {
            // TODO find a way to parse it from graphql with custom type
            return !this.range.cellsData || !this.range.cellsData.data
                ? {}
                : JSON.parse(this.range.cellsData.data)
        },
    },
    methods: {
        setGroupNodeModel(data = this.range) {
            if (
                parseInt(data.id, 10) ===
                parseInt(this.group_node.attrs.model.id, 10)
            )
                this.checkModelUpdates(data)
            this.group_node.setAttr('model', data)
        },
        checkModelUpdates(range) {
            if (!this.rangeGrid) return

            const color_preset_changed =
                parseInt(
                    this.group_node.attrs.model.stack.position.color_preset_id,
                    10
                ) !== parseInt(range.stack.position.color_preset_id, 10)

            if (
                color_preset_changed &&
                range.color_preset_sync &&
                !!range.stack.position.color_preset_id
            ) {
                this.rangeGrid.clearCells()
            } else {
                this.group_node.attrs.model.rangeColors.forEach(old_rc => {
                    const current_rc = range.rangeColors.find(
                        rc => parseInt(old_rc.id, 10) === parseInt(rc.id, 10)
                    )

                    if (!current_rc) this.resetColor(old_rc)
                    else if (current_rc.color !== old_rc.color)
                        this.rangeGrid.replaceColor(
                            old_rc.color,
                            current_rc.color
                        )
                })
            }

            if (this.range.name !== this.group_node.attrs.model.name || this.range.show_prefix !== this.group_node.attrs.model.show_prefix)
                this.rangeGrid.updateTitle(this.range)
        },
        setRangeCellsData(cells_data) {
            // TODO find a way to parse it from graphql with custom type
            const cache = this.$apollo.provider.defaultClient.cache,
                data = Object.assign({}, this.range.cellsData),
                fragment = RangeCellsDataFieldsFragment,
                id = cache.identify(data)

            data.data = JSON.stringify(cells_data)
            cache.writeFragment({ id, fragment, data })
        },
        startListening() {
            this.group_node.on('dragstart', () =>
                this.$emit('onDragStart', this.group_node)
            )
            this.group_node.on('dragmove', () =>
                this.$emit('onDragMove', this.group_node)
            )
            this.group_node.on('dragend', () =>
                this.$emit('onDragEnd', this.group_node)
            )
            this.group_node.on('transformstart', () =>
                this.$emit('onTransformStart', this.group_node)
            )
            this.group_node.on('transform', () =>
                this.$emit('onTransform', this.group_node)
            )
            this.group_node.on('transformend', this.onTransformEnd)
            this.group_node.on('mouseenter', () =>
                this.$emit('onEnter', this.group_node)
            )
            this.group_node.on('mouseleave', this.onMouseLeave)
            this.title_node.on('click', this.onClick)

            this.rangeGrid.onCellMouseEnter = this.onCellMouseEnter
            this.rangeGrid.onCellMouseLeave = this.onCellMouseLeave
            this.rangeGrid.onCellMouseDown = this.onCellMouseDown
            this.rangeGrid.onCellMouseUp = this.onCellMouseUp
            this.rangeGrid.onCellWheel = this.onCellWheel
        },
        onTransformEnd({
            target: {
                attrs: { scaleX, scaleY },
            },
        }) {
            const size = Object.assign({}, this.range.size || {}, {
                x: Math.abs(scaleX) * 100,
                y: Math.abs(scaleY) * 100,
            })

            this.setGroupNodeModel(Object.assign({}, this.range, { size }))
            this.$nextTick(() => this.$emit('onTransformEnd', this.group_node))
        },
        onMouseLeave() {
            this.is_painting = false
            this.$emit('onLeave', this.group_node)
        },
        onClick({ evt: { button } }) {
            if (this.followMouse)
                this.$emit('onCoordinateChoosed', this.group_node)
            else if (button === 0) this.$emit('onLeftClick', this.group_node)
            // TODO : refactor into lib
            else if (button === 2) this.$emit('onRightClick', this.group_node) // TODO : refactor into lib
        },
        onCellMouseEnter(evt, cell) {
            if (!this.is_painting) this.emitCellEnter(cell)
            if (!this.can_paint || !this.is_painting) return

            const paintFunction = this.remove_paint ? this.unpaint : this.paint
            paintFunction(cell.attrs.model)
        },
        onCellMouseLeave() {
            if (!this.is_painting) this.$emit('onCellLeave')
        },
        onCellMouseUp(event) {
            if (!this.can_paint) {
                this.onClick(event)
                return
            }

            this.is_painting = false
        },
        onCellMouseDown(event, cell) {
            if (!this.can_paint) return

            this.remove_paint = event.evt.button === 2 // TODO : refactor into lib
            this.is_painting = true

            this.onCellMouseEnter(event, cell)
        },
        onCellWheel({ evt }) {
            this.$emit('onCellWheel', evt)
        },
        emitCellEnter(cell_node) {
            this.$emit('onCellEnter', cell_node)
        },
        computeMousePosition() {
            if (!this.followMouse || !this.stage) return

            const stage_absolute_position = this.stage.absolutePosition(),
                stage_absolute_scale = this.stage.getAbsoluteScale(),
                half_grid_size = this.grid_size / 2

            let mousePosition = this.stage.getPointerPosition()

            mousePosition = {
                x:
                    (this.offset_positioning +
                        mousePosition.x -
                        stage_absolute_position.x) /
                        stage_absolute_scale.x -
                    half_grid_size * this.group_node.attrs.scaleX,
                y:
                    (this.offset_positioning +
                        mousePosition.y -
                        stage_absolute_position.y) /
                        stage_absolute_scale.y -
                    half_grid_size * this.group_node.attrs.scaleY,
            }

            this.group_node.position(mousePosition)
            this.$emit('onMousePosition', this.group_node)
        },
        mutateRangeCellsData() {
            this.handleSaving()

            const update = !!this.range.cellsData && !!this.range.cellsData.id,
                mutation = update
                    ? UpdateRangeCellsDataMutation
                    : AddRangeCellsDataMutation,
                cellsData = JSON.parse(JSON.stringify(this.range.cellsData))

            delete cellsData.__typename

            this.$apollo
                .mutate({
                    mutation,
                    variables: { input: cellsData },
                    update: (cache, { data }) => {
                        if (update) return

                        const fragment = RangeCellsDataFieldsFragment,
                            id = cache.identify(data.createRangeCellsData)

                        cache.modify({
                            id: `Range:${this.range.id}`,
                            fields: {
                                cellsData() {
                                    return cache.writeFragment({
                                        id,
                                        fragment,
                                        data,
                                    })
                                },
                            },
                        })
                    },
                })
                .then(this.handleSuccess)
                .catch(this.handleMutationError)
        },
        handleSaving() {
            this.$emit('onSaving')
        },
        handleSuccess() {
            this.$emit('onSaveSuccess')
        },
        handleMutationError(err) {
            this.$emit('onSaveFail', err)
        },
        paint({ id, colors }) {
            const range_color_to_paint = Object.assign({}, this.paint_model),
                mode = this.paint_model.pivot.mode,
                border_exist = !!colors.find(rc => rc.pivot.mode === 'border'),
                total_weight = colors.reduce(
                    (total, { id, pivot: { mode, weight } }) => {
                        if (mode === 'border') return total
                        if (
                            parseInt(id, 10) !==
                            parseInt(this.paint_model.id, 10)
                        )
                            total += parseFloat(weight)
                        return total
                    },
                    0
                )

            let weight = parseFloat(this.paint_model.pivot.weight)

            if (
                (mode === 'border' && border_exist) ||
                (mode !== 'border' && total_weight >= 1)
            )
                return
            if (total_weight + weight > 1) weight = 1 - total_weight

            let cells_data = this.range_cells_data

            const model = {
                color: range_color_to_paint.color,
                mode: range_color_to_paint.pivot.mode,
                weight,
            }

            if (!cells_data[id]) cells_data[id] = [model]
            else {
                const index = cells_data[id].findIndex(
                    ({ color }) => color === model.color
                )
                if (index === -1) cells_data[id].push(model)
                else cells_data[id].splice(index, 1, model)
            }

            this.rangeGrid.updateCell({
                cell_id: id,
                cells_colors_data: cells_data,
            })
            this.setRangeCellsData(cells_data)
        },
        paintFromData({ range_color, cells_with_paint_pivot_data }) {
            cells_with_paint_pivot_data.forEach(({ cell_id, mode, weight }) => {
                const cell_node = this.rangeGrid.getCellNode(cell_id)

                this.enablePaint({ range_color, mode, weight })
                this.paint(cell_node.attrs.model)
            })
            this.disablePaint()
        },
        unpaint({ id, colors }, strict = false) {
            let cells_data = this.range_cells_data

            if (
                !cells_data[id] ||
                colors.length === 0 ||
                (strict &&
                    !colors.find(rc => rc.color === this.paint_model.color))
            )
                return

            const index = cells_data[id].findIndex(
                ({ color }) => color === this.paint_model.color
            )

            cells_data[id].splice(index, 1)

            this.rangeGrid.updateCell({
                cell_id: id,
                cells_colors_data: cells_data,
            })
            this.setRangeCellsData(cells_data)
        },
        enablePaint({ range_color, mode, weight }) {
            range_color = JSON.parse(JSON.stringify(range_color))
            range_color.pivot = { mode, weight }

            this.paint_model = range_color
        },
        disablePaint(save = true) {
            this.paint_model = null
            if (!!save) setTimeout(this.mutateRangeCellsData, 500)
        },
        resetColor(range_color, save = true) {
            this.enablePaint({ range_color })
            Object.entries(this.range_cells_data).forEach(([id, colors]) =>
                this.unpaint({ id, colors }, true, false)
            )
            this.disablePaint(save)
        },
        resetAllColor(save = true) {
            this.range.rangeColors.forEach(range_color =>
                this.resetColor(range_color, false)
            )
            if (!!save) setTimeout(this.mutateRangeCellsData, 500)
        },
    },
    watch: {
        range() {
            this.setGroupNodeModel()
        },
        selected(value) {
            if (!this.rangeGrid) return
            this.rangeGrid.setSelected(value)
        },
        draggable(value) {
            if (!this.rangeGrid) return
            this.rangeGrid.setDraggable(value)
        },
        followMouse: {
            handler(followMouse) {
                if (!followMouse) return
                this.$emit('onLeftClick', this.group_node)
            },
            immediate: true,
        },
    },
    data() {
        return {
            offset_positioning: 10,
            is_painting: false,
            remove_paint: false,
            paint_model: null,
            rangeGrid: null,
            stage: null,
        }
    },
    mounted() {
        this.rangeGrid = this.$rangeManager.getNextFree()
        this.rangeGrid.layer = this.layer_node
        this.rangeGrid.render(this.range)

        this.stage = this.layer_node.getStage()
        this.stage.on('mousemove', this.computeMousePosition)

        if (!this.range.size)
            this.setGroupNodeModel(
                Object.assign({}, this.range, { size: { x: 100, y: 100 } })
            )

        this.startListening()
    },
    destroyed() {
        this.rangeGrid.release()
        this.rangeGrid = null
    },
    render() {}, // Renderless component
}
</script>
