export function createKeychainService(service) {
    return {
        value: '',
        save(value) {
            // localStorage.setItem(service, value);
            this.value = value
            return Promise.resolve(value)
        },

        get() {
            return Promise.resolve(this.value)
        },

        clear() {
            this.value = null
            // localStorage.removeItem(service);
            return Promise.resolve()
        },
    }
}
