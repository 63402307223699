const isDevelopment = process.env.NODE_ENV !== 'production',
    api_url = process.env.API_URL,
    connect_src = process.env.CSP_CONNECT_SRC,
    img_src = process.env.CSP_IMG_SRC,
    script_src_elem = process.env.CSP_SCRIPT_SRC_ELEM,
    frame_src = process.env.CSP_FRAME_SRC

export default {
    'http-equiv': 'Content-Security-Policy',
    content: `
        default-src 'self' ${isDevelopment ? "'unsafe-eval'" : ''};
        img-src 'self' data: ${img_src};
        style-src 'self' 'unsafe-inline';
        connect-src 'self' ws: ${api_url} ${api_url}/ ${connect_src};
        script-src-elem 'self' ${script_src_elem};
        frame-src ${frame_src};
    `,
}
