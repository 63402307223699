const ConfigurationPlugin = {
    install(Vue) {
        Vue.configuration = Vue.prototype.$configuration = {
            set: (k, v) => {
                localStorage.setItem(k, JSON.stringify(v))
                return
            },
            get: k => {
                return JSON.parse(localStorage.getItem(k))
            },
            delete: k => {
                localStorage.removeItem(k)
                return
            },
        }
    },
}

export default ConfigurationPlugin
