<script>
import { pickTextColorBasedOnBgColor } from '/renderer/lib/color'

export default {
    props: {
        bgColor: { type: String, required: true },
    },
    computed: {
        style() {
            return {
                backgroundColor: this.bgColor,
                color: pickTextColorBasedOnBgColor(
                    this.bgColor,
                    this.colors.text_light,
                    this.colors.text_dark
                ),
            }
        },
    },
    data() {
        return {
            colors: {
                text_dark: '#000000',
                text_light: '#ffffff',
            },
        }
    },
}
</script>

<template>
    <span class="badge badge-primary" :style="style"><slot></slot></span>
</template>
