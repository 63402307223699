<script>
export default {}
</script>

<template>
    <div>
        <ClipLoader color="#ffffff" size="64px"></ClipLoader>
    </div>
</template>

<style lang="scss" scoped>
div {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
