import Konva from 'konva'
import { pickTextColorBasedOnBgColor } from '/renderer/lib/color'

const NAME = 'rangeNode',
    GRID_MARGIN = 25,
    GRID_PADDING = 13,
    GRID_BASE_SIZE = 600,
    CELL_MARGIN = 1,
    CELL_PADDING = 1,
    CELL_STROKE_WIDTH = 2,
    BG_CONTRAST_MIN_WEIGHT = 0.2,
    PERFORMANCE_CONFIG = {
        shadowForStrokeEnabled: false,
        strokeScaleEnabled: false,
        strokeEnabled: false,
        shadowEnabled: false,
        hitStrokeWidth: 0,
    },
    GRID_BACKGROUNDS = {
        default: {
            src: require('/renderer/assets/img/grid.png'),
            image: new window.Image(),
        },
        hover: {
            src: require('/renderer/assets/img/grid_hover.png'),
            image: new window.Image(),
        },
        selected: {
            src: require('/renderer/assets/img/grid_selected.png'),
            image: new window.Image(),
        },
    },
    TITLE_CONFIG = {
        y: (-25 * 1.25) >> 0,
        height: 25,
        width: GRID_BASE_SIZE,
        text: 'title',
        fontSize: 16,
        fontFamily: 'Oswald',
        fill: 'white',
        align: 'center',
        verticalAlign: 'middle',
        fontStyle: 'Bold',
        ...PERFORMANCE_CONFIG,
    },
    COLORS = {
        border_dark: '#1A1414',
        bg_dark: '#666666',
        bg_light: '#999696',
        text_dark: '#000000',
        text_light: '#ffffff',
    }

GRID_BACKGROUNDS.default.image.src = GRID_BACKGROUNDS.default.src
GRID_BACKGROUNDS.hover.image.src = GRID_BACKGROUNDS.hover.src
GRID_BACKGROUNDS.selected.image.src = GRID_BACKGROUNDS.selected.src

class Range {
    constructor(id, size, globalCellsData) {
        this.id = id
        this.globalCellsData = globalCellsData
        this.cells = []
        this.size = size >> 0
        this.cell_size = ((GRID_BASE_SIZE - GRID_PADDING * 2) / size) >> 0
        this.is_hovering = false
        this.is_selected = false
        this.is_used = false
        this.layer = null

        this.resetCellEventCallback()
        this.generate()
    }

    get grid_size() {
        return GRID_BASE_SIZE
    }

    setSelected(value) {
        this.is_selected = value
        this.updateImage()
    }

    setDraggable(value) {
        this.group.draggable(value)
    }

    getNodeId() {
        return `${NAME}_${this.id}`
    }

    getNode() {
        return this.group
    }

    getTitleNode() {
        return this.title
    }

    getCellNode(cell_id) {
        const cell_index = this.globalCellsData.findIndex(
            c => parseInt(c.id, 10) === parseInt(cell_id, 10)
        )

        if (cell_index === -1) return undefined

        const x = cell_index % this.size,
            y = (cell_index / this.size) >> 0

        return this.cells[y][x]
    }

    getCellTextFill(index, rangecolors) {
        const base_bg =
            index % (this.size + 1) === 0
                ? COLORS.bg_light
                : COLORS.bg_dark,
            highest_weight =
                rangecolors.length > 0
                    ? rangecolors
                        .map(rc => parseFloat(rc.pivot.weight))
                        .reduce((wA, wB) => Math.max(wA, wB), 0)
                    : 0,
            rc = rangecolors.find(
                rc => parseFloat(rc.pivot.weight) === highest_weight
            ),
            bg_color =
                highest_weight > BG_CONTRAST_MIN_WEIGHT && !!rc
                    ? rc.color
                    : base_bg

        return pickTextColorBasedOnBgColor(
            bg_color,
            COLORS.text_light,
            COLORS.text_dark
        )
    }

    resetCellEventCallback() {
        this.onCellMouseEnter = () => { }
        this.onCellMouseLeave = () => { }
        this.onCellMouseDown = () => { }
        this.onCellMouseUp = () => { }
        this.onCellWheel = () => { }
    }

    startListening() {
        this.group.on('xChange', ({ oldVal }) =>
            this.group.setAttr('old_x', oldVal)
        )
        this.group.on('yChange', ({ oldVal }) =>
            this.group.setAttr('old_y', oldVal)
        )

        this.group.on('mouseenter', () => {
            if (this.is_hovering) return
            this.is_hovering = true
            this.updateImage()
        })

        this.group.on('mouseleave', () => {
            if (!this.is_hovering) return
            this.is_hovering = false
            this.updateImage()
        })
    }

    bindCellEvents(cell) {
        cell.on('mouseenter', ev => this.onCellMouseEnter(ev, cell))
        cell.on('mouseleave', ev => this.onCellMouseLeave(ev, cell))
        cell.on('mousedown', ev => this.onCellMouseDown(ev, cell))
        cell.on('mouseup', ev => this.onCellMouseUp(ev, cell))
        cell.on('wheel', ev => this.onCellWheel(ev, cell))
    }

    createCell(index_x, index_y) {
        const global_cell_index = index_y * this.size + index_x,
            cell_data = this.globalCellsData[global_cell_index]

        let cell = new Konva.Group({
            x:
                (GRID_PADDING +
                    CELL_MARGIN +
                    CELL_MARGIN * 2 +
                    this.cell_size * index_x) >>
                0,
            y:
                (GRID_PADDING +
                    CELL_MARGIN +
                    CELL_MARGIN * 2 +
                    this.cell_size * index_y) >>
                0,
        })

        cell.title = new Konva.Text({
            text: cell_data.name,
            width: this.cell_size,
            height: this.cell_size,
            color: 'white',
            fontStyle: 'Bold',
            padding: 5,
            fontSize: 15,
        })

        cell.setAttr('model', cell_data)
        cell.add(cell.title)

        this.bindCellEvents(cell)

        cell.cache()

        return cell
    }

    cache() {
        this.group.cache()
    }

    generate() {
        this.group = new Konva.Group({
            name: NAME,
            id: this.getNodeId(),
        })

        this.image = new Konva.Image({
            ...PERFORMANCE_CONFIG,
            x: GRID_PADDING * 2,
            y: GRID_PADDING * 2,
            offsetX: GRID_MARGIN * 2,
            offsetY: GRID_MARGIN * 2,
            image: GRID_BACKGROUNDS.default.image,
        })

        this.title = new Konva.Text(TITLE_CONFIG)

        this.group.add(this.image, this.title)

        for (let y = 0; y < this.size; y++) {
            let line = []
            for (let x = 0; x < this.size; x++) {
                let cell = this.createCell(x, y)
                this.group.add(cell)
                line.push(cell)
            }
            this.cells.push(line)
        }

        this.group.cache()
    }

    render(range_data) {
        const {
            size,
            coordinate: { x, y },
        } = range_data

        this.is_used = true

        this.group.setAttrs({
            x,
            y,
            old_x: x,
            old_y: y,
            scaleX: (!!size ? size.x : 100) / 100,
            scaleY: (!!size ? size.y : 100) / 100,
            cells: this.globalCellsData,
            model: range_data,
        })

        this.updateTitle(range_data)

        this.globalCellsData.forEach((cellData, index) =>
            this.updateCell({
                cell_id: cellData.id,
                cell_index: index,
                batch_draw: false,
            })
        )

        this.startListening()
        this.group.clearCache()
        this.layer.add(this.group)
    }

    releaseCell(cell_node) {
        cell_node.colors.forEach(color_node => color_node.destroy())
    }

    releaseCells() {
        for (let i = 0; i < this.cells.length; i++)
            for (let j = 0; j < this.cells[i].length; j++)
                this.releaseCell(this.cells[i][j])
    }

    release() {
        this.group.setAttr('model', null)
        this.group.remove()
        this.layer = null

        this.releaseCells()

        this.is_used = false
        this.resetCellEventCallback()
    }

    updateTitle(range_data) {
        const { stack, name, show_prefix } = range_data
            , prefix = show_prefix ? `${stack.position.name} ${stack.name} ` : ''

        this.title.text(prefix + name)
    }

    updateImage() {
        let img = GRID_BACKGROUNDS.default.image
        if (this.is_selected) img = GRID_BACKGROUNDS.selected.image
        else if (this.is_hovering) img = GRID_BACKGROUNDS.hover.image

        this.image.image(img)
        this.layer.batchDraw()
    }

    updateCell({ cell_id, cell_index, batch_draw, cells_colors_data }) {
        const { cellsData, rangeColors } = this.group.attrs.model

        batch_draw = !batch_draw ? true : false
        cells_colors_data = !cells_colors_data
            ? JSON.parse(cellsData.data)
            : cells_colors_data
        cell_index =
            cell_index === undefined
                ? this.globalCellsData.findIndex(
                    c => parseInt(c.id, 10) === parseInt(cell_id, 10)
                )
                : cell_index

        const x = cell_index % this.size,
            y = (cell_index / this.size) >> 0,
            inner_cell_size =
                (this.cell_size - CELL_MARGIN * 2 - CELL_PADDING * 2) >> 0,
            cell_node = this.cells[y][x],
            cell_colors_data = cells_colors_data[cell_id] || [],
            cell_color_config = {
                x: CELL_PADDING,
                y: CELL_PADDING,
                height: inner_cell_size,
                ...PERFORMANCE_CONFIG,
            },
            colors = []

        if (!!cell_node.colors && cell_node.colors.length > 0)
            this.releaseCell(cell_node)

        cell_colors_data.forEach(({ color, mode, weight }) => {
            weight = parseFloat(weight)

            let range_color = rangeColors.find(rc => rc.color === color)

            if (!range_color) return

            colors.push({ ...range_color, pivot: { mode, weight } })
        })

        cell_node.title.fill(this.getCellTextFill(cell_index, colors))
        cell_node.colors = []

        cell_node.setAttr(
            'model',
            Object.assign({}, cell_node.attrs.model, { colors })
        )

        colors.forEach(({ color, pivot: { mode, weight } }) => {
            const width = inner_cell_size * weight,
                rect = new Konva.Rect(
                    Object.assign({}, cell_color_config, {
                        ...(mode === 'fill' && { fill: color, width }),
                        ...(mode === 'border' && {
                            x: 0,
                            width: inner_cell_size,
                            stroke: color,
                            strokeWidth: CELL_STROKE_WIDTH,
                            strokeEnabled: true,
                        }),
                    })
                )

            cell_node.colors.push(rect)
            cell_node.add(rect)

            cell_color_config.x += width
        })

        cell_node.title.moveToTop()

        if (batch_draw) this.layer.batchDraw()
    }

    clearCell(cell_node, batch_draw = true) {
        const cell_index = this.globalCellsData.findIndex(c => parseInt(c.id, 10) === parseInt(cell_node.attrs.model.id, 10))
            , colors = []

        this.releaseCell(cell_node)

        cell_node.title.fill(this.getCellTextFill(cell_index, colors))
        cell_node.colors = colors
        cell_node.setAttr(
            'model',
            Object.assign({}, cell_node.attrs.model, { colors })
        )

        if (batch_draw) this.layer.batchDraw()
    }

    clearCells() {
        for (let i = 0; i < this.cells.length; i++)
            for (let j = 0; j < this.cells[i].length; j++)
                this.clearCell(this.cells[i][j], false)
    }

    replaceColor(old_color, new_color) {
        this.globalCellsData.forEach((_, index) => {
            const x = index % this.size,
                y = (index / this.size) >> 0,
                cell_node = this.cells[y][x],
                model = cell_node.attrs.model,
                color_index = model.colors.findIndex(
                    c => c.color === old_color
                ),
                color_rect = cell_node.colors.find(
                    color_rect => color_rect.fill() === old_color
                )

            if (color_index !== -1)
                model.colors.splice(
                    color_index,
                    1,
                    Object.assign({}, model.colors[color_index], {
                        color: new_color,
                    })
                )
            if (!!color_rect) {
                color_rect.fill(new_color)
                cell_node.title.fill(this.getCellTextFill(index, model.colors))
            }

            cell_node.setAttr('model', model)
        })

        this.layer.batchDraw()
    }
}

export default Range
