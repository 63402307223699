const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    sendRequest = (url, options) => {
        options.headers = {
            ...headers,
            ...(!!options.token && {
                Authorization: `Bearer ${options.token}`,
            }),
        }

        delete options.token

        return new Promise((resolve, reject) => {
            fetch(url, options)
                .then(response => {
                    response.json().then(data => {
                        if (!!response.ok) resolve(data)
                        else reject(!!data.message ? data.message : data)
                    })
                })
                .catch(reject)
        })
    }

export { sendRequest }
