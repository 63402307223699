<script>
import { setLanguage, setupVueI18nMessages } from '../config/i18n'
import csp from '../config/csp'

const title = process.env.APP_NAME

export default {
    name: 'App',
    metaInfo: { title, meta: [csp] },
    i18n: setupVueI18nMessages(),
    computed: {},
    methods: {
        /**
         * @deprecated since web app
         */
        //     askDownloadUpdate() {
        //       this.$notify.show(this.$t("common.update.available"), {
        //         ...this.$notify.getOption("info"),
        //         action: [
        //           {
        //             text: this.$t("common.yes"),
        //             onClick: (e, toastObject) => {
        //               toastObject.goAway(0);
        //               this.$ipc.send("download-update");
        //             },
        //           },
        //           {
        //             text: this.$t("common.later"),
        //             onClick: (e, toastObject) => toastObject.goAway(0),
        //           },
        //         ],
        //       });
        //     },
        //     askInstallUpdate() {
        //       this.$notify.show(this.$t("common.update.install"), {
        //         ...this.$notify.getOption("info"),
        //         action: [
        //           {
        //             text: this.$t("common.yes"),
        //             onClick: () => this.$ipc.send("install-update"),
        //           },
        //           {
        //             text: this.$t("common.later"),
        //             onClick: (e, toastObject) => toastObject.goAway(0),
        //           },
        //         ],
        //       });
        //     },
        //     showProgress({ bytesPerSecond, percent }) {
        //       bytesPerSecond = (parseFloat(bytesPerSecond) / Math.pow(10, 6)).toFixed(
        //         2
        //       );
        //       percent = parseFloat(percent).toFixed(2);
        //       const text = `${this.$t(
        //         "common.update.progress"
        //       )}: ${percent}%, ${bytesPerSecond} Mo/s`;
        //       if (!this.notify_progress_object)
        //         this.notify_progress_object = this.$notify.show(text, {
        //           ...this.$notify.getOption("info"),
        //           duration: null,
        //           closeOnSwipe: false,
        //         });
        //       else this.notify_progress_object.text(text);
        //       if (parseInt(percent, 10) === 100)
        //         this.notify_progress_object.goAway(1000);
        //     },
        //   },
        //   data() {
        //     return {
        //       notify_progress_object: null,
        //     };
        //   }
    },
    created() {
        setLanguage(this.$configuration.get('app_lang'))
    },
    mounted() {
        document.addEventListener('contextmenu', event =>
            event.preventDefault()
        )
        /**
         * @deprecated since 1.0.0-beta.35
         */
        // this.$ipc.on("update-available", () => this.askDownloadUpdate());
        // this.$ipc.on("update-downloaded", () => this.askInstallUpdate());
        // this.$ipc.on("download-progress", (event, progress_info) =>
        //   this.showProgress(progress_info)
        // );
    },
}
</script>

<template>
    <router-view></router-view>
</template>

<style lang="scss">
@import '/renderer/assets/theme/scss/app.scss';
@import '/renderer/assets/scss/transitions.scss';
@import 'node_modules/bootstrap-vue/src/index.scss';
</style>

<style>
@import 'animate.css/animate.css';
</style>
