<script>
import { validate, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

import { login } from '/renderer/lib/api/auth'
import tokenService from '/renderer/lib/keychain/token'

import { setupVueI18nMessages } from '/renderer/config/i18n'

import fr from '/renderer/lang/components/screens/authentification/login/fr.json'
import en_GB from '/renderer/lang/components/screens/authentification/login/en_GB.json'
const translations = { fr, en_GB }

extend('email', email)
extend('required', required)

export default {
    i18n: setupVueI18nMessages(translations),
    methods: {
        handleSubmit() {
            if (this.processing) return
            this.processing = true

            let payload = { password: this.model.password }
            const promise = validate(this.model.username, 'required|email')

            promise.then(({ valid }) => {
                payload[valid ? 'email' : 'username'] = this.model.username
                login(payload)
                    .then(({ token, user }) => {
                        this.$configuration.set('app_user', user)
                        return tokenService.save(token)
                    })
                    .then(() => {
                        this.model.username = null
                        this.model.password = null
                        this.$router.push({ name: 'dashboard' })
                    })
                    .catch(err => this.$notify.error(err))
                    .finally(() => (this.processing = false))
            })
        },
    },
    mounted() {
        const message = this.$route.query.message
        if (!!message) {
            this.$notify.unmute()
            this.$notify.error(this.$t(message))
        }

        navigator.permissions.query({
            name: 'clipboard-read',
            allowWithoutGesture: false,
        })
    },
    data() {
        return {
            processing: false,
            model: {
                username: '',
                password: '',
            },
        }
    },
}
</script>

<template>
    <main id="screen__login" class="main" data-sa-theme="11">
        <section class="login">
            <div class="login__block active">
                <img
                    class="img img-responsive img-logo-full"
                    width="70%"
                    src="/renderer/assets/img/logo_full.png"
                />
                <div class="login__block__header">
                    {{ $t('login-screen.title') }}
                </div>

                <div class="login__block__body">
                    <form @submit.prevent="handleSubmit">
                        <div class="form-group">
                            <input
                                type="text"
                                class="form-control text-center"
                                :placeholder="$t('login-screen.form.username')"
                                v-model="model.username"
                                name="email"
                                id="email"
                                autocomplete="email"
                            />
                        </div>

                        <div class="form-group">
                            <input
                                type="password"
                                class="form-control text-center"
                                :placeholder="$t('login-screen.form.password')"
                                v-model="model.password"
                                name="password"
                                id="password"
                                autocomplete="password"
                            />
                        </div>
                        <button
                            class="btn btn--icon btn-light login__block__btn"
                            type="submit"
                        >
                            <transition name="fade" mode="out-in">
                                <i
                                    v-if="!processing"
                                    class="zmdi zmdi-long-arrow-right"
                                ></i>
                                <PulseLoader
                                    color="#fff"
                                    size="5px"
                                    v-else
                                ></PulseLoader>
                            </transition>
                        </button>
                    </form>
                </div>
            </div>
        </section>
    </main>
</template>

<style lang="scss" scoped>
.lang-switcher {
    position: absolute;
    right: 10px;
    top: 10px;
}
</style>
