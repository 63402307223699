<script>
export default {
    props: {
        parent_color: { type: String },
        stack: { required: true, type: Object },
        base_config: { required: true, type: Object },
        base_config_text: { required: true, type: Object },
        selected: { type: Boolean, default: false },
    },
    computed: {
        id() {
            return `stack_${this.stack.id}`
        },
        color() {
            return !!this.parent_color
                ? this.parent_color
                : this.stack.color || this.default_color
        },
        color_config() {
            return {
                fill: this.color + '66',
                stroke: this.color + 'aa',
                strokeWidth: 1,
            }
        },
        coordinate_y() {
            const { order } = this.stack
            return this.base_config.height * order + this.margin * order
        },
        shadow_config() {
            if (!this.selected && !this.is_hovering) return {}

            const shadowColor = '#ffffff',
                shadowBlur = this.selected ? 8 : 10,
                shadowOpacity = this.selected ? 0.7 : 0.5

            return { shadowColor, shadowBlur, shadowOpacity }
        },
        config() {
            return {
                ...this.base_config,
                y: this.coordinate_y,
            }
        },
        config_text() {
            return {
                ...this.base_config_text,
                fontStyle: 'normal',
            }
        },
    },
    methods: {
        onMouseEnter() {
            this.is_hovering = true
            this.$emit('onEnter', this.group_node)
        },
        onMouseLeave() {
            this.is_hovering = false
            this.$emit('onLeave', this.group_node)
        },
        onClick({ evt: { button } }) {
            if (button === 0) this.$emit('onLeftClick', this.group_node)
            else if (button === 2) this.$emit('onRightClick', this.group_node)
        },
        setGroupNodeModel() {
            this.group_node.setAttr('model', this.stack)
        },
    },
    watch: {
        stack() {
            this.setGroupNodeModel()
        },
    },
    data() {
        return {
            default_color: '#000000',
            is_hovering: false,
            mouse_position: null,
            group_node: null,
            margin: 3,
        }
    },
    mounted() {
        this.group_node = this.$refs.groupNode.getNode()
        this.setGroupNodeModel()
    },
}
</script>

<template>
    <v-group
        ref="groupNode"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
        @click="onClick"
        :config="{ id, name: 'stackNode' }"
    >
        <v-rect
            :config="{
                ...config,
                ...color_config,
                ...shadow_config,
            }"
        ></v-rect>

        <v-text
            :config="{
                ...config,
                ...config_text,
                listening: false,
                text: stack.name,
                fill: 'white',
            }"
        ></v-text>
    </v-group>
</template>
