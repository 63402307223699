<script>
import { setupVueI18nMessages } from '/renderer/config/i18n'
import ColorPresetTab from './tabs/ColorPreset'

import fr from '/renderer/lang/components/screens/software/setting/fr.json'
import en_GB from '/renderer/lang/components/screens/software/setting/en_GB.json'
const translations = { fr, en_GB }

export default {
    i18n: setupVueI18nMessages(translations),
    components: { ColorPresetTab },
    mounted() {
        setTimeout(() => this.$softwareContainer.$emit('forceResize'), 800)
    },
}
</script>

<template>
    <section id="settings">
        <header class="content__title">
            <h1>
                <i class="zmdi zmdi-settings"></i>
                {{ $t('setting.title') }}
            </h1>
        </header>

        <SoftwareContainer class="card ml-3 mr-3">
            <div id="settings__tabs" class="card-body">
                <b-tabs>
                    <ColorPresetTab active></ColorPresetTab>
                </b-tabs>
            </div>
        </SoftwareContainer>
    </section>
</template>

<style lang="scss" scoped>
#settings {
    height: 100%;
    position: relative;
}
</style>

<style lang="scss">
#settings .tab-content {
    padding-bottom: 0;
}
</style>
