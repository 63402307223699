<script>
import { setupVueI18nMessages } from '/renderer/config/i18n'
// import translations from  '/renderer/lang/components/screens/software/gameboard/messages'

import StackFieldsFragment from '/renderer/apollo/fragments/stack_fields_fragment.graphql'
import GetPositionsQuery from '/renderer/apollo/position/GetPositionsQuery.graphql'
import AddPositionMutation from '/renderer/apollo/position/AddPositionMutation.graphql'
import UpdatePositionMutation from '/renderer/apollo/position/UpdatePositionMutation.graphql'
import DeletePositionMutation from '/renderer/apollo/position/DeletePositionMutation.graphql'
import AddStackMutation from '/renderer/apollo/stack/AddStackMutation.graphql'
import UpdateStackMutation from '/renderer/apollo/stack/UpdateStackMutation.graphql'
import UpStackMutation from '/renderer/apollo/stack/UpStackMutation.graphql'
import DownStackMutation from '/renderer/apollo/stack/DownStackMutation.graphql'
import DeleteStackMutation from '/renderer/apollo/stack/DeleteStackMutation.graphql'
import GetRangesQuery from '/renderer/apollo/range/GetRangesQuery.graphql'
import AddRangeMutation from '/renderer/apollo/range/AddRangeMutation.graphql'
import UpdateRangeMutation from '/renderer/apollo/range/UpdateRangeMutation.graphql'
import DeleteRangeMutation from '/renderer/apollo/range/DeleteRangeMutation.graphql'
import GetColorPresetsQuery from '/renderer/apollo/color_preset/GetColorPresetsQuery.graphql'
import ExportDuplicateDataPositionQuery from '/renderer/apollo/data/ExportDuplicateDataPositionQuery.graphql'
import ImportDataGameboardMutation from '/renderer/apollo/data/ImportDataGameboardMutation.graphql'

import CanvasLayout from '../../partials/canvas/Layout'
import CanvasToolbar from '../../partials/canvas/Toolbar'
import CanvasRangeColorPalette from '../../partials/canvas/range/ColorPalette'
import {
    PositionNode,
    StackNode,
    RangeNode,
} from '../../partials/canvas/nodes/index'

import { processPositionData } from '/renderer/lib/dataImporter'
import fr from '/renderer/lang/components/screens/software/gameboard/fr.json'
import en_GB from '/renderer/lang/components/screens/software/gameboard/en_GB.json'
const translations = { fr, en_GB }

const SAVE_STATUS = { SAVED: 0, SAVING: 1, ERROR: 2 },
    NODE_TYPE = { POSITION: 'Position', STACK: 'Stack', RANGE: 'Range' },
    MIN_TRANSFORMER_BOUND_BOX = 50,
    MIN_TRANSFORMER_BOUND_BOX_POSITION_HEIGHT = 20

export default {
    i18n: setupVueI18nMessages(translations),
    components: {
        CanvasLayout,
        CanvasToolbar,
        CanvasRangeColorPalette,
        PositionNode,
        StackNode,
        RangeNode,
    },
    props: {
        id: { required: true },
    },
    apollo: {
        exportData: {
            query: ExportDuplicateDataPositionQuery,
            manual: true,
            fetchPolicy: 'no-cache',
            variables() {
                if (!this.position_id_duplicate) return
                return { position_id: parseInt(this.position_id_duplicate, 10) }
            },
            skip() {
                return !this.position_id_duplicate
            },
            result(payload) {
                this.mutatePositionImportData(payload)
            },
        },
        color_presets: { query: GetColorPresetsQuery },
        positions: {
            query: GetPositionsQuery,
            variables() {
                return { gameboard_id: parseInt(this.id, 10) }
            },
        },
        ranges: {
            query: GetRangesQuery,
            variables() {
                return { stack_id: parseInt(this.stack_id_for_range, 10) }
            },
            skip() {
                return this.stack_id_for_range === null
            },
        },
    },
    computed: {
        NODE_TYPE() {
            return NODE_TYPE
        },
        is_premium_gameboard() {
            return this.$store.state.is_premium_gameboard
        },
        transformer_select() {
            return this.$refs.transformerSelect.getNode()
        },
        transformer_hover() {
            return this.$refs.transformerHover.getNode()
        },
        is_one_node_selected() {
            return this.nodes_selected.length === 1
        },
        is_nodes_selected() {
            return this.nodes_selected.length > 0
        },
        is_nodes_selected_same_type() {
            if (!this.is_nodes_selected) return false

            for (let i = 0; i < this.nodes_selected.length; i++) {
                if (i === 0) continue
                if (
                    this.getNodeEntityName(this.nodes_selected[i]) !==
                    this.getNodeEntityName(this.nodes_selected[i - 1])
                )
                    return false
            }

            return true
        },
        is_nodes_selected_position() {
            if (!this.is_nodes_selected) return false
            return this.nodes_selected.reduce(
                (is_same_name, node) =>
                    is_same_name && node.attrs.name === 'positionNode',
                true
            )
        },
        is_nodes_selected_stack() {
            if (!this.is_nodes_selected) return false
            return this.nodes_selected.reduce(
                (is_same_name, node) =>
                    is_same_name && node.attrs.name === 'stackNode',
                true
            )
        },
        is_nodes_selected_range() {
            if (!this.is_nodes_selected) return false
            return this.nodes_selected.reduce(
                (is_same_name, node) =>
                    is_same_name && node.attrs.name === 'rangeNode',
                true
            )
        },
        is_node_positioning() {
            return !!this.node_positioning_name
        },
        is_node_positioning_position() {
            if (!this.is_node_positioning) return false
            return this.node_positioning_name === NODE_TYPE.POSITION
        },
        is_node_positioning_range() {
            if (!this.is_node_positioning) return false
            return this.node_positioning_name === NODE_TYPE.RANGE
        },
        is_nodes_dragging() {
            return Object.keys(this.nodes_dragging).length > 0
        },
        is_one_node_dragging() {
            return Object.keys(this.nodes_dragging).length === 1
        },
        is_range_show() {
            return !!this.stack_id_for_range
        },
        is_range_painting() {
            return !!this.range_id_for_paint
        },
        show_range_color_palette() {
            return (
                this.hide_toolbar_items_premium_gameboard ||
                (!!this.nodes_selected[0] && !this.nodes_selected[0].attrs.model.locked) 
            ) &&
                this.show_action_buttons &&
                this.is_one_node_selected &&
                this.is_nodes_selected_range &&
                !this.is_node_positioning &&
                !this.is_nodes_dragging
        },
        hide_toolbar_items_premium_gameboard() {
            return !this.$app.user.is_admin && this.is_premium_gameboard
        },
        show_toolbar() {
            return (
                this.show_action_buttons &&
                this.is_nodes_selected &&
                this.is_nodes_selected_same_type &&
                !this.is_node_positioning &&
                !this.is_range_painting
            )
        },
        show_toolbar_cell() {
            return (
                !!this.node_cell &&
                !this.is_nodes_dragging &&
                !this.is_range_painting
            )
        },
    },
    methods: {
        transformerSelectBoundBox(oldBoundBox, newBoundBox) {
            if (this.is_nodes_selected_position) {
                const element_height = parseInt(newBoundBox.height, 10) / (this.nodes_selected[0].attrs.model.stacks.length + 1)
                return newBoundBox.width <= MIN_TRANSFORMER_BOUND_BOX || element_height <= MIN_TRANSFORMER_BOUND_BOX_POSITION_HEIGHT ? oldBoundBox : newBoundBox
            }
            return Math.min(newBoundBox.width, newBoundBox.height) <= MIN_TRANSFORMER_BOUND_BOX ? oldBoundBox : newBoundBox
        },
        resetRange() {
            this.stack_id_for_range = null
            this.ranges = []
            this.$apollo.provider.defaultClient.cache.gc()
        },
        updateNodeSelectedModel(data) {
            if (!this.is_nodes_selected) return

            const nodes_selected = this.nodes_selected,
                index = nodes_selected.findIndex(
                    node =>
                        parseInt(node.attrs.model.id, 10) ===
                        parseInt(data.id, 10)
                )

            if (index === -1) return

            nodes_selected[index].setAttr(
                'model',
                Object.assign({}, nodes_selected[index].attrs.model, data)
            )

            this.nodes_selected = [] // Reactivity issue even with this.$set, so we force the update
            this.nodes_selected = nodes_selected
        },
        updateTransformerOptions() {
            this.transformer_select.resizeEnabled(
                this.isTransformerSelectResizeEnabled()
            )
            this.transformer_select.enabledAnchors(this.getTransformerAnchors())
            this.transformer_select.keepRatio(this.getKeepRatio())
        },
        getNodeEntityName(node) {
            const entity = node.attrs.name.replace('Node', '')
            return entity.charAt(0).toUpperCase() + entity.slice(1)
        },
        getKeepRatio() {
            return this.is_nodes_selected_position ? false : true
        },
        getMinCoordinate() {
            return this.positions.reduce((coordinates, position) => {
                const { x, y } = position.coordinate
                if (!coordinates) return { x, y }

                if (parseFloat(x) < parseFloat(coordinates.x)) coordinates.x = x
                if (parseFloat(y) < parseFloat(coordinates.y)) coordinates.y = y

                return coordinates
            }, null)
        },
        getTransformerAnchors() {
            // Must be an method because of reactivity issues
            let anchors = [
                'top-left',
                'top-right',
                'bottom-left',
                'bottom-right',
            ]
            if (this.is_nodes_selected_position)
                anchors.push(
                    'top-center',
                    'bottom-center',
                    'middle-left',
                    'middle-right'
                )
            return anchors
        },
        isTransformerSelectResizeEnabled() {
            // Must be an method because of reactivity issues
            return (
                this.is_one_node_selected &&
                this.show_action_buttons &&
                (this.is_nodes_selected_position ||
                    this.is_nodes_selected_range) &&
                !this.nodes_selected[0].attrs.model.locked
            )
        },
        isPositionLocked(position_id) {
            const position = this.positions.find(
                p => parseInt(p.id, 10) === parseInt(position_id, 10)
            )
            return !!position && position.locked
        },
        isLastStack(model) {
            if (!model) return false

            const { position_id, order } = model,
                position = this.positions.find(
                    p => parseInt(p.id, 10) === parseInt(position_id, 10)
                )

            if (!position || position.stacks.length === 0) return false

            return parseInt(order, 10) === position.stacks.length
        },
        isNodeSelected(node) {
            return !!this.nodes_selected.find(n => n._id === node._id)
        },
        isModelExistInNodesSelected(id, node_type_name) {
            return !!this.nodes_selected.find(
                n =>
                    this.getNodeEntityName(n) === node_type_name &&
                    parseInt(n.attrs.model.id, 10) === parseInt(id, 10)
            )
        },
        removeNodeDragging(node) {
            const nodes_dragging = this.nodes_dragging
            delete nodes_dragging[node._id]
            this.nodes_dragging = Object.assign({}, nodes_dragging)

            if (this.primary_node_dragging_id === node._id)
                this.primary_node_dragging_id = null
        },
        onStageClick() {
            if (this.save_status === SAVE_STATUS.SAVING) return

            if (this.is_node_positioning && this.is_one_node_selected) {
                this.$refs.canvas_layout.disableGrid()
                this.updateTransform().then(() => {
                    this.updateToolbarCoordinate()

                    if (this.node_positioning_name !== NODE_TYPE.RANGE)
                        this[`show${this.node_positioning_name}EditForm`](
                            this.nodes_selected[0].attrs.model
                        )

                    this.node_positioning_name = null
                })
            } else if (this.is_nodes_selected) this.unselectNodes()
        },
        onStageToggleDrag() {
            this.unselectNodes()
        },
        onStageDragMove() {
            this.updateToolbarCoordinate()
            this.unsetTransformerNodes(this.transformer_hover)
            if (this.is_nodes_selected)
                this.switchTransformerSelection(this.nodes_selected) // Bug when using stage drag & scale
        },
        onStageDragEnd(canvas) {
            const { x, y } = canvas.attrs
            this.$configuration.set(`gameboard.${this.id}.position`, { x, y })
        },
        onStageScale(scale) {
            this.$configuration.set(`gameboard.${this.id}.scaling`, scale)
            this.updateToolbarCoordinate()
            this.unsetTransformerNodes(this.transformer_hover)
            if (this.is_nodes_selected)
                this.switchTransformerSelection(this.nodes_selected) // Bug when using stage drag & scale
        },
        onNodeEnter(node) {
            if (this.isNodeSelected(node)) return
            this.setTransformerNodes(node, this.transformer_hover)
        },
        onNodeLeave(node) {
            if (this.isNodeSelected(node)) return

            this.unsetTransformerNodes(this.transformer_hover)
            if (this.is_nodes_selected)
                this.switchTransformerSelection(this.nodes_selected) // Bug with transformer_hover when using stage drag & scale
        },
        onNodeDragStart(node) {
            this.$refs.canvas_layout.enableGrid()
            this.nodes_dragging = Object.assign({}, this.nodes_dragging, {
                [node._id]: node,
            })
        },
        onNodeDragMove(node) {
            if (
                this.nodes_selected.length > 1 &&
                (this.primary_node_dragging_id === node._id ||
                    !this.primary_node_dragging_id)
            ) {
                if (!this.primary_node_dragging_id)
                    this.primary_node_dragging_id = node._id
                this.$refs.canvas_layout.updateNodeBelongGrid(
                    this.nodes_selected
                )
            } else if (
                this.is_one_node_dragging &&
                this.nodes_selected.length <= 1
            )
                this.$refs.canvas_layout.updateNodeBelongGrid(node)

            if (this.isNodeSelected(node)) this.updateToolbarCoordinate()
        },
        onNodeDragEnd(node) {
            this.$refs.canvas_layout.disableGrid()

            if (!this.isNodeSelected(node)) this.selectNodes(node)
            this.removeNodeDragging(node)
            this.updateTransform()
        },
        onNodeTransformStart() {
            // this.$refs.canvas_layout.enableGrid()
        },
        onNodeTransform() {
            // this.$refs.canvas_layout.updateNodeBelongGrid(node)
            this.updateToolbarCoordinate()
        },
        onNodeTransformEnd() {
            // this.$refs.canvas_layout.disableGrid()
            this.updateTransform()
        },
        onNodeMousePositionUpdated(node) {
            this.$refs.canvas_layout.updateNodeBelongGrid(node)
        },
        updateNodeDefaultSize(model) {
            if (!this.is_one_node_selected) return

            this.nodes_selected[0].attrs.model = model
            this.$refs.modalConfirmSizeConfiguration.show()
            this.$refs.modalConfirmSizeConfiguration.onConfirm(() => {
                const { x, y } = model.size
                this.$configuration.set(this.nodes_selected[0].attrs.name, {
                    x,
                    y,
                })
            })
        },
        updateRangeDefaultPos(model) {
            if (!this.is_one_node_selected) return

            this.nodes_selected[0].attrs.model = model
            this.$refs.modalConfirmRangePosConfiguration.show()
            this.$refs.modalConfirmRangePosConfiguration.onConfirm(() => {
                const { x, y } = model.coordinate
                this.$configuration.set(this.nodes_selected[0].attrs.name + '-pos', {
                    x,
                    y,
                })
            })
        },
        onStageSelection({ is_right_click, nodes }) {
            if (this.is_node_positioning) return

            const actions_buttons_showed = this.show_action_buttons
            this.unselectNodes()

            if (is_right_click || actions_buttons_showed)
                this.show_action_buttons = true
            this.selectNodes(nodes)
        },
        onNodeSelect(node) {
            if (this.is_node_positioning) this.$refs.canvas_layout.enableGrid()
            if (this.is_nodes_selected && this.is_node_positioning) return
            if (this.isNodeSelected(node)) this.unselectNodes()
            else {
                const nodes = this.$refs.canvas_layout.ctrl_pushed
                    ? this.nodes_selected.concat(node)
                    : [node]
                this.selectNodes(nodes)
            }
        },
        onNodeSelectWithActionPanel(node) {
            if (this.is_node_positioning) return

            const sameNode = this.isNodeSelected(node)

            if (!sameNode) {
                this.show_action_buttons = true
                const nodes = this.$refs.canvas_layout.ctrl_pushed
                    ? this.nodes_selected.concat(node)
                    : [node]
                this.selectNodes(nodes)
            } else if (sameNode && this.show_action_buttons)
                this.unselectNodes()
            else this.show_action_buttons = true
        },
        onRangeCellEnter(node) {
            const { colors } = node.attrs.model

            if (colors.length === 0) return

            const { x, y } = node.getAbsoluteTransform().point({ x: 0, y: 0 })

            this.toolbar_cell_coordinate = { x, y }
            this.node_cell = node
        },
        onRangeCellLeave() {
            this.node_cell = null
        },
        onRangeCellWheel(event) {
            if (!this.$refs.rangeColorPalette) return
            this.$refs.rangeColorPalette.handleWheel(event)
        },
        switchTransformerSelection(toNode) {
            this.unsetTransformerNodes(this.transformer_select)
            this.setTransformerNodes(toNode, this.transformer_select)
        },
        selectNodes(nodes) {
            if (!Array.isArray(nodes)) nodes = [nodes]

            this.nodes_selected = nodes

            this.updateToolbarCoordinate()
            this.unsetTransformerNodes(this.transformer_hover)
            this.setTransformerNodes(nodes, this.transformer_select)

            if (this.is_range_painting) this.handleStopPaint()
            if (this.is_nodes_selected_stack) {
                this.stack_id_for_range = nodes[0].attrs.model.id
            }
        },
        unselectNodes() {
            if (this.is_range_painting) this.handleStopPaint()

            this.unsetTransformerNodes(this.transformer_select)
            this.nodes_selected = []
            this.show_action_buttons = false
        },
        setTransformerNodes(nodes, transformer) {
            if (!Array.isArray(nodes)) nodes = [nodes]
            transformer.nodes(nodes)
            this.batchDraw()
        },
        unsetTransformerNodes(transformer) {
            transformer.nodes([])
            this.batchDraw()
        },
        batchDraw() {
            this.$refs.canvas_layout.stage.batchDraw()
        },
        updateToolbarCoordinate() {
            if (!this.is_nodes_selected) return

            const transform = this.nodes_selected[0].getAbsoluteTransform()
            const { x, y } = transform.point({ x: 0, y: 0 })

            this.toolbar_coordinate = Object.assign({}, { x, y })
        },
        updateTransform() {
            if (!this.is_nodes_selected) return Promise.resolve

            const promises = []
            this.nodes_selected.forEach(node => {
                let { x, y } = node.attrs
                let { id, size, coordinate, ...model } = node.attrs.model

                x = parseInt(x, 10)
                y = parseInt(y, 10)
                coordinate = {
                    update: Object.assign({}, { id: coordinate.id }, { x, y }),
                }

                if (!!size) {
                    const size_info = {
                        x: parseInt(size.x, 10),
                        y: parseInt(size.y, 10),
                    }
                    size = !!size.id
                        ? {
                              update: Object.assign(
                                  {},
                                  { id: size.id },
                                  size_info
                              ),
                          }
                        : { create: size_info }
                } else size = undefined

                promises.push(
                    this[`mutate${this.getNodeEntityName(node)}Update`]({
                        id,
                        size,
                        coordinate,
                        ...model,
                    })
                )
            })

            return Promise.all(promises)
        },
        updateColor(color) {
            if (!this.is_nodes_selected) return
            if (color.length === 0) color = null

            this.nodes_selected.forEach(node => {
                const model = node.attrs.model
                this[`mutate${this.getNodeEntityName(node)}Update`]({
                    ...model,
                    color,
                })
            })
        },
        toggleLock() {
            if (!this.is_nodes_selected) return

            this.nodes_selected.forEach(node => {
                const { id, locked, ...model } = node.attrs.model
                this[`mutate${this.getNodeEntityName(node)}Update`]({
                    id,
                    locked: !locked,
                    ...model,
                }).then(this.updateTransformerOptions)
            })
        },
        toggleColorChild({ color_child }) {
            if (!this.is_nodes_selected_position) return
            this.nodes_selected.forEach(node =>
                this.mutatePositionUpdate({
                    id: node.attrs.model.id,
                    color_child: !color_child,
                })
            )
        },
        duplicatePosition({ id }) {
            this.position_id_duplicate = parseInt(id, 10)
            this.unselectNodes()
            this.handleSaving()
        },
        mutatePositionImportData({ data: { position } }) {
            this.$apollo
                .mutate({
                    mutation: ImportDataGameboardMutation,
                    variables: {
                        input: {
                            id: this.id,
                            positions: {
                                create: [processPositionData(position)],
                            },
                        },
                    },
                })
                .then(() => {
                    this.position_id_duplicate = null
                    return this.$apollo.queries.positions.refetch()
                })
                .then(() => {
                    this.handleSaveSuccess()
                    this.node_positioning_name = NODE_TYPE.POSITION
                })
                .catch(this.handleSaveError)
        },
        mutatePositionAdd() {
            this.unselectNodes()
            this.handleSaving()

            const size = this.$configuration.get('positionNode')

            if (!!size) {
                size.x = parseInt(size.x, 10)
                size.y = parseInt(size.y, 10)
            }

            this.$apollo
                .mutate({
                    mutation: AddPositionMutation,
                    variables: {
                        input: {
                            ...(!!size && { size: { create: size } }),
                            gameboard: { connect: parseInt(this.id, 10) },
                            coordinate: { create: { x: 0, y: 0 } },
                            name: this.$t('gameboard.position.name'),
                        },
                    },
                    update: (cache, { data }) => {
                        const query = GetPositionsQuery

                        cache.modify({
                            fields: {
                                positions(existingItems = []) {
                                    const newItemRef = cache.writeQuery({
                                        query,
                                        data,
                                    })
                                    return [...existingItems, newItemRef]
                                },
                            },
                        })
                    },
                })
                .then(({ data: { createPosition } }) => {
                    this.handleSaveSuccess()
                    this.mutateStackAdd(createPosition.id, true)
                    // It takes time for data to be reactively updated from cache.modify
                    // Todo, maybe manually setting a variable isCreating and watch positions data changes
                    // before set node_positioning_name
                    setTimeout(
                        () => (this.node_positioning_name = NODE_TYPE.POSITION),
                        500
                    )
                })
                .catch(this.handleSaveError)
        },
        mutatePositionUpdate({
            id,
            name,
            color,
            color_child,
            locked,
            size,
            coordinate,
            color_preset,
        }) {
            this.handleSaving()

            const rangesToRefresh = []

            if (!!color_preset) {
                const { data } = this.$apollo.provider.defaultClient.cache.data

                Object.keys(data)
                    .filter(cache_key => cache_key.includes('Range:'))
                    .forEach(cache_key => {
                        const range = data[cache_key],
                            stack_id = parseInt(range.stack_id, 10),
                            position_id = parseInt(range.stack.position_id, 10)

                        if (
                            !!rangesToRefresh.find(
                                r => r.variables.stack_id === stack_id
                            ) ||
                            position_id !== parseInt(id, 10)
                        )
                            return
                        rangesToRefresh.push({
                            query: GetRangesQuery,
                            variables: { stack_id },
                        })
                    })
            }

            const promise = this.$apollo.mutate({
                mutation: UpdatePositionMutation,
                variables: {
                    input: {
                        id,
                        name,
                        color,
                        color_child,
                        locked,
                        ...(!!color_preset && { color_preset }),
                        ...(!!coordinate &&
                            !!coordinate.update && { coordinate }),
                        ...(!!size &&
                            (!!size.update || !!size.create) && { size }),
                    },
                },
                awaitRefetchQueries: rangesToRefresh.length > 0,
                refetchQueries: rangesToRefresh,
            })

            promise
                .then(({ data: { updatePosition } }) => {
                    if (this.is_nodes_selected_position)
                        this.updateNodeSelectedModel(updatePosition)
                    if (!color_preset && this.is_range_show)
                        this.$apollo.queries.ranges.refetch()

                    this.handleSaveSuccess()
                })
                .catch(this.handleSaveError)

            return promise
        },
        mutatePositionDelete({ id }) {
            this.handleSaving()

            this.$apollo
                .mutate({
                    mutation: DeletePositionMutation,
                    variables: { id },
                    update: cache => {
                        cache.modify({
                            fields: {
                                positions(existingItemRefs, { readField }) {
                                    return existingItemRefs.filter(
                                        itemRef =>
                                            id !== readField('id', itemRef)
                                    )
                                },
                            },
                        })
                    },
                })
                .then(() => {
                    this.handleSaveSuccess()
                    this.unselectNodes()
                    this.resetRange()
                })
                .catch(this.handleSaveError)
        },
        mutateStackAdd(position_id, dontEdit, name, callback) {
            this.handleSaving()
            this.$apollo
                .mutate({
                    mutation: AddStackMutation,
                    variables: {
                        input: {
                            position: { connect: parseInt(position_id, 10) },
                            name: name || this.$t('gameboard.stack.name'),
                        },
                    },
                    update: (cache, { data }) => {
                        const fragment = StackFieldsFragment,
                            id = cache.identify(data.createStack)

                        cache.modify({
                            id: `Position:${position_id}`,
                            fields: {
                                stacks(existingItemRefs = []) {
                                    const newRef = cache.writeFragment({
                                        id,
                                        fragment,
                                        data,
                                    })
                                    return [...existingItemRefs, newRef]
                                },
                            },
                        })
                    },
                })
                .then(({ data: { createStack } }) => {
                    this.handleSaveSuccess()
                    if(!dontEdit) {
                        this.switchTransformerSelection(this.nodes_selected[0])
                        this.showStackEditForm(createStack)
                    }
                    this.mutateRangeAdd(createStack.id, true)
                    if(callback) callback()
                })
                .catch(this.handleSaveError)
        },
        mutateStackUpdate({ id, name, color }) {
            this.handleSaving()

            const promise = this.$apollo.mutate({
                mutation: UpdateStackMutation,
                variables: { input: { id, name, color } },
            })

            promise
                .then(({ data: { updateStack } }) => {
                    if (this.is_nodes_selected_stack)
                        this.updateNodeSelectedModel(updateStack)
                    if (
                        parseInt(this.stack_id_for_range, 10) ===
                        parseInt(id, 10)
                    )
                        this.$apollo.queries.ranges.refetch()
                    this.handleSaveSuccess()
                })
                .catch(this.handleSaveError)

            return promise
        },
        mutateStackDelete({ id, position_id, order }) {
            this.handleSaving()

            this.$apollo
                .mutate({
                    mutation: DeleteStackMutation,
                    variables: { id },
                    update: cache => {
                        cache.modify({
                            id: `Position:${position_id}`,
                            fields: {
                                stacks(existingItemRefs, { readField }) {
                                    const newItemRefs = existingItemRefs.filter(
                                        itemRef =>
                                            id !== readField('id', itemRef)
                                    )

                                    newItemRefs.forEach(ref => {
                                        // Update order for all next ones
                                        if (order > readField('order', ref))
                                            return

                                        const fragment = StackFieldsFragment,
                                            id = cache.identify(ref),
                                            old_data = cache.readFragment({
                                                id,
                                                fragment,
                                            }),
                                            data = Object.assign({}, old_data, {
                                                order: old_data.order - 1,
                                            })

                                        cache.writeFragment({
                                            id,
                                            fragment,
                                            data,
                                        })
                                    })

                                    return newItemRefs
                                },
                            },
                        })
                    },
                })
                .then(() => {
                    this.handleSaveSuccess()
                    this.unselectNodes()
                    if (
                        parseInt(this.stack_id_for_range, 10) ===
                        parseInt(id, 10)
                    )
                        this.resetRange()
                })
                .catch(this.handleSaveError)
        },
        mutateStackUpdateOrder({ id, position_id }, up = true) {
            this.handleSaving()

            const mutation = up ? UpStackMutation : DownStackMutation,
                promise = this.$apollo.mutate({
                    mutation,
                    variables: { id },
                    update: (cache, { data }) => {
                        const { order } = up
                                ? data['upStack']
                                : data['downStack'],
                            position = this.positions.find(
                                position =>
                                    parseInt(position.id, 10) ===
                                    parseInt(position_id, 10)
                            ),
                            stack_to_switch = position.stacks.find(
                                stack =>
                                    parseInt(id, 10) !==
                                        parseInt(stack.id, 10) &&
                                    parseInt(order, 10) ===
                                        parseInt(stack.order, 10)
                            ),
                            fragment = StackFieldsFragment,
                            new_stack = Object.assign({}, stack_to_switch, {
                                order: stack_to_switch.order + (up ? 1 : -1),
                            })

                        cache.writeFragment({
                            id: cache.identify(stack_to_switch),
                            fragment,
                            data: new_stack,
                        })
                    },
                })

            promise
                .then(({ data }) => {
                    if (this.is_nodes_selected_stack)
                        this.updateNodeSelectedModel(
                            up ? data['upStack'] : data['downStack']
                        )
                    this.handleSaveSuccess()
                    this.switchTransformerSelection(this.nodes_selected[0])
                })
                .catch(this.handleSaveError)

            return promise
        },
        mutateRangeAdd(stack_id, dontEdit) {
            if(!dontEdit) {
              this.unselectNodes()
            }

            this.handleSaving()

            stack_id = parseInt(stack_id, 10)

            const size = this.$configuration.get('rangeNode')
            const pos = this.$configuration.get('rangeNode-pos')
            const rangesOfStack = this.ranges.filter(range => range.stack_id == stack_id)

            if (!!size) {
                size.x = parseInt(size.x, 10)
                size.y = parseInt(size.y, 10)
            }
            if(!!pos) {
              pos.x = parseInt(pos.x, 10)
              pos.y = parseInt(pos.y, 10)

              pos.x += rangesOfStack.length * 20
              pos.y += rangesOfStack.length * 20
            }

            const finalPos = pos || { x: 0, y: 0 }

            this.$apollo
                .mutate({
                    mutation: AddRangeMutation,
                    variables: {
                        input: {
                            ...(!!size && { size: { create: size } }),
                            stack: { connect: stack_id },
                            coordinate: { create: finalPos },
                            cellsData: { create: { data: JSON.stringify({}) } },
                            name: '', // Empty name by default
                        },
                    },
                    update: (cache, { data }) => {
                        const query = GetRangesQuery

                        cache.modify({
                            fields: {
                                ranges(existingItems = []) {
                                    const newItemRef = cache.writeQuery({
                                        query,
                                        data,
                                    })
                                    return [...existingItems, newItemRef]
                                },
                            },
                        })
                    },
                })
                .then(() => {
                    this.handleSaveSuccess()
                    if(!dontEdit && !pos) {
                        // It takes time for data to be reactively updated from cache.modify
                        // Todo, maybe manually setting a variable isCreating and watch positions data changes
                        // before set node_positioning_name
                        setTimeout(
                            () => (this.node_positioning_name = NODE_TYPE.RANGE),
                            500
                        )
                    }
                })
                .catch(this.handleSaveError)
        },
        mutateRangeUpdate({ id, name, show_prefix, locked, size, coordinate }) {
            this.handleSaving()

            const promise = this.$apollo.mutate({
                mutation: UpdateRangeMutation,
                variables: {
                    input: {
                        id,
                        name,
                        show_prefix,
                        locked,
                        ...(!!coordinate &&
                            !!coordinate.update && { coordinate }),
                        ...(!!size &&
                            (!!size.update || !!size.create) && { size }),
                    },
                },
            })

            promise
                .then(({ data: { updateRange } }) => {
                    if(!this.$configuration.get("rangeNode-pos")) {
                      const {x, y} = updateRange.coordinate
                      this.$configuration.set("rangeNode-pos", {x, y})
                    }
                    if (this.is_nodes_selected_range)
                        this.updateNodeSelectedModel(updateRange)
                    this.handleSaveSuccess()
                })
                .catch(this.handleSaveError)

            return promise
        },
        mutateRangeDelete({ id }) {
            this.handleSaving()
            this.unselectNodes()

            this.$apollo
                .mutate({
                    mutation: DeleteRangeMutation,
                    variables: { id },
                    update: cache => {
                        cache.modify({
                            fields: {
                                ranges(existingItemRefs, { readField }) {
                                    return existingItemRefs.filter(
                                        itemRef =>
                                            id !== readField('id', itemRef)
                                    )
                                },
                            },
                        })
                    },
                })
                .then(this.handleSaveSuccess)
                .catch(this.handleSaveError)
        },
        handleSaving() {
            this.save_status = SAVE_STATUS.SAVING
        },
        handleSaveSuccess() {
            this.save_status = SAVE_STATUS.SAVED
        },
        handleSaveError(error) {
            this.save_status = SAVE_STATUS.ERROR
            this.$notify.error(error)
        },
        handleColorPaletteSaveSuccess({ id }) {
            if (this.is_nodes_selected_range)
                this.updateNodeSelectedModel(
                    this.ranges.find(
                        range => parseInt(range.id, 10) === parseInt(id, 10)
                    )
                )
            this.handleSaveSuccess()
        },
        handlePaint(paint_options) {
            const { range_color } = paint_options
            this.range_id_for_paint = range_color.range_id
            this.$refs[`rangeNode#${range_color.range_id}`][0].enablePaint(
                paint_options
            ) // /!\ https://v3.vuejs.org/guide/migration/array-refs.html
        },
        handleStopPaint() {
            this.$refs[`rangeNode#${this.range_id_for_paint}`][0].disablePaint() // /!\ https://v3.vuejs.org/guide/migration/array-refs.html
            this.range_id_for_paint = null
        },
        handleResetColor(range_color) {
            this.$refs[`rangeNode#${range_color.range_id}`][0].resetColor(
                range_color
            )
        },
        handleImportClipboard({ range_color, cells_with_paint_pivot_data }) {
            this.$refs[`rangeNode#${range_color.range_id}`][0].paintFromData({
                range_color,
                cells_with_paint_pivot_data,
            })
        },
        confirmDeletion() {
            if (!this.is_nodes_selected) return

            this.$refs.modalDelete.show()
            this.$refs.modalDelete.onDelete(() =>
                this.nodes_selected.forEach(node =>
                    this[`mutate${this.getNodeEntityName(node)}Delete`](
                        node.attrs.model
                    )
                )
            )
        },
        showPositionEditForm({ name, color_preset_id }) {
            if (!this.is_nodes_selected) return
            if (!color_preset_id) color_preset_id = ''

            const old_color_preset_id = parseInt(color_preset_id, 10)

            this.$refs.modalEditForm.show({ name, color_preset_id })
            this.$refs.modalEditForm.onSubmit(({ name, color_preset_id }) => {
                let color_preset
                color_preset_id = parseInt(color_preset_id, 10)

                const color_preset_different =
                    color_preset_id !== old_color_preset_id

                if (color_preset_different)
                    color_preset = isNaN(color_preset_id)
                        ? { disconnect: true }
                        : { connect: color_preset_id }

                this.nodes_selected.forEach(node => {
                    const id = node.attrs.model.id
                    this.mutatePositionUpdate({ id, name, color_preset })
                })
                this.$refs.modalEditForm.close()
            })
        },
        showAddStackForm(position_id) {
            if (!this.is_nodes_selected) return

            this.$refs.modalAddStackForm.show({ quantity: 1, startVal: 20, padding: -1 }, false)
            this.$refs.modalAddStackForm.onSubmit(({ quantity, startVal, padding }) => {
                let position = this.positions.find(pos => pos.id == position_id)
                this.recursiveAddStack(position, 0, parseInt(quantity), parseInt(startVal), parseInt(padding))
                this.$refs.modalAddStackForm.close()
            })
        },
        recursiveAddStack(position, i, quantity, startVal, padding) {
          if(i >= quantity || !quantity) return;

          this.mutateStackAdd(position.id, true, startVal.toString(), () => {
            this.recursiveAddStack(position, ++i, quantity, startVal + padding, padding)
          })
        },
        showStackEditForm({ id, position_id, name }) {
            this.$refs.modalEditForm.show({ name })
            this.$refs.modalEditForm.onSubmit(({ name }) =>
                this.mutateStackUpdate({ id, position_id, name }).then(() =>
                    this.$refs.modalEditForm.close()
                )
            )
        },
        confirmRangeReset() {
            if (!this.is_nodes_selected_range) return
            this.$refs.modalConfirm.show()
            this.$refs.modalConfirm.onConfirm(() =>
                this.nodes_selected.forEach(node =>
                    this.$refs[
                        `rangeNode#${node.attrs.model.id}`
                    ][0].resetAllColor()
                )
            )
        },
        showRangeEditForm({ id, stack_id, name, show_prefix }) {
            this.$refs.modalEditForm.show({ name, show_prefix })
            this.$refs.modalEditForm.onSubmit(({ name, show_prefix }) =>
                this.mutateRangeUpdate({ id, stack_id, name, show_prefix }).then(() =>
                    this.$refs.modalEditForm.close()
                )
            )
        },
        layoutSelectionCallBack(shape) {
            return ['positionNode', 'rangeNode'].includes(shape.attrs.name)
        },
    },
    watch: {
        id() {
            this.unselectNodes()
            this.resetRange()
        },
        '$route.params.refresh': {
            handler() {
                this.$apollo.queries.positions.refetch()
            },
        },
        nodes_selected() {
            this.updateTransformerOptions()
        },
        show_action_buttons() {
            this.updateTransformerOptions()
        },
    },
    data() {
        return {
            SAVE_STATUS_CONST: SAVE_STATUS,
            save_status: SAVE_STATUS.SAVED,
            toolbar_coordinate: { x: 0, y: 0 },
            toolbar_cell_coordinate: { x: 0, y: 0 },
            show_action_buttons: false,
            node_positioning_name: null,
            node_cell: null,
            position_id_duplicate: null, // Launch apollo query
            stack_id_for_range: null, // For ranges display
            range_id_for_paint: null, // For cell painting
            primary_node_dragging_id: null, // For multi item dragging on grid
            nodes_dragging: {},
            nodes_selected: [],
            ranges: [],
            color_presets: [],
        }
    },
    beforeRouteLeave(_, __, next) {
        // Do not remove @see https://trello.com/c/ChFNlrPS
        this.unselectNodes()
        this.resetRange()
        this.$nextTick(() => next())
    },
    destroyed() {
        const apolloClient = this.$apollo.provider.defaultClient
        apolloClient.resetStore().then(() => apolloClient.cache.gc())
    },
}
</script>

<template>
    <section
        id="gameboard"
        :class="{ 'gameboard-cursor-target': is_node_positioning }"
    >
        <CanvasLayout
            ref="canvas_layout"
            :scale="this.$configuration.get(`gameboard.${this.id}.scaling`)"
            :position="this.$configuration.get(`gameboard.${this.id}.position`)"
            :selectionCallback="layoutSelectionCallBack"
            :centerCustomPositionCallback="getMinCoordinate"
            @onClick="onStageClick"
            @onToggleDrag="onStageToggleDrag"
            @onDragMove="onStageDragMove"
            @onDragEnd="onStageDragEnd"
            @onScaleChange="onStageScale"
            @onSelection="onStageSelection"
        >
            <template #top_action_bar_content_before>
                <div class="toolbar main-toolbar">
                    <transition name="fade" mode="out-in">
                        <i
                            class="
                                zmdi zmdi-hc-2x zmdi-check-circle
                                text-success
                            "
                            v-b-popover.hover.bottom="
                                $t('gameboard.status.saved')
                            "
                            v-if="save_status === SAVE_STATUS_CONST.SAVED"
                        ></i>
                        <i
                            class="zmdi zmdi-hc-2x zmdi-circle text-warning"
                            v-b-popover.hover.bottom="
                                $t('gameboard.status.saving')
                            "
                            v-else-if="save_status === SAVE_STATUS_CONST.SAVING"
                        ></i>
                        <i
                            class="
                                zmdi zmdi-hc-2x zmdi-minus-circle
                                text-danger
                            "
                            v-b-popover.hover.bottom="
                                $t('gameboard.status.error')
                            "
                            v-else-if="save_status === SAVE_STATUS_CONST.ERROR"
                        ></i>
                    </transition>

                    <span class="separator"></span>

                    <transition name="fade" mode="out-in">
                        <a
                            href="#"
                            class="btn btn-light btn-sm mr-2"
                            @click.prevent="mutatePositionAdd"
                            v-if="$app.user.is_admin || !is_premium_gameboard"
                        >
                            <i class="zmdi zmdi-playlist-plus"></i>
                            {{ $t('gameboard.position.add') }}
                        </a>
                    </transition>
                </div>
            </template>

            <v-layer>
                <PositionNode
                    v-for="(position, index) in positions"
                    :key="`position#${position.id}`"
                    :position="position"
                    :followMouse="
                        is_node_positioning_position &&
                        index === positions.length - 1
                    "
                    :selected="
                        isModelExistInNodesSelected(
                            position.id,
                            NODE_TYPE.POSITION
                        )
                    "
                    @onEnter="onNodeEnter"
                    @onLeave="onNodeLeave"
                    @onDragStart="onNodeDragStart"
                    @onDragMove="onNodeDragMove"
                    @onDragEnd="onNodeDragEnd"
                    @onTransformStart="onNodeTransformStart"
                    @onTransform="onNodeTransform"
                    @onTransformEnd="onNodeTransformEnd"
                    @onMousePosition="onNodeMousePositionUpdated"
                    @onCoordinateChoosed="onStageClick"
                    @onLeftClick="onNodeSelect"
                    @onRightClick="onNodeSelectWithActionPanel"
                >
                    <template #default="{ config, config_text, color }">
                        <StackNode
                            v-for="stack in position.stacks"
                            :key="`stack#${stack.id}`"
                            :stack="stack"
                            :parent_color="position.color_child ? color : null"
                            :base_config="config"
                            :base_config_text="config_text"
                            :selected="
                                isModelExistInNodesSelected(
                                    stack.id,
                                    NODE_TYPE.STACK
                                ) || stack.id === stack_id_for_range
                            "
                            @onEnter="onNodeEnter"
                            @onLeave="onNodeLeave"
                            @onLeftClick="onNodeSelect"
                            @onRightClick="onNodeSelectWithActionPanel"
                        ></StackNode>
                    </template>
                </PositionNode>

                <!-- Transformers had no borders, the visual selection is made 
                by nodes itselves (selected component attribute) -->
                <v-transformer
                    ref="transformerHover"
                    :config="{
                        resizeEnabled: false,
                        rotateEnabled: false,
                        borderStrokeWidth: 0,
                    }"
                ></v-transformer>

                <v-transformer
                    ref="transformerSelect"
                    :config="{
                        boundBoxFunc: transformerSelectBoundBox,
                        resizeEnabled: isTransformerSelectResizeEnabled(),
                        enabledAnchors: getTransformerAnchors(),
                        keepRatio: getKeepRatio(),
                        borderStrokeWidth: 0,
                        centeredScaling: true,
                        rotateEnabled: false,
                        anchorStroke: '#eaeaea',
                        anchorFill: '#343a40',
                        anchorSize: 8,
                        anchorCornerRadius: 100,
                        padding: 10,
                    }"
                ></v-transformer>
                <!-- End Transformers -->
            </v-layer>

            <v-layer ref="rangeLayer">
                <RangeNode
                    v-for="(range, index) in ranges"
                    :ref="`rangeNode#${range.id}`"
                    :key="`range#${range.id}`"
                    :layer="$refs.rangeLayer"
                    :range="range"
                    :followMouse="
                        is_node_positioning_range && index === ranges.length - 1
                    "
                    :selected="
                        isModelExistInNodesSelected(range.id, NODE_TYPE.RANGE)
                    "
                    @onEnter="onNodeEnter"
                    @onLeave="onNodeLeave"
                    @onCellEnter="onRangeCellEnter"
                    @onCellLeave="onRangeCellLeave"
                    @onCellWheel="onRangeCellWheel"
                    @onDragStart="onNodeDragStart"
                    @onDragMove="onNodeDragMove"
                    @onDragEnd="onNodeDragEnd"
                    @onTransformStart="onNodeTransformStart"
                    @onTransform="onNodeTransform"
                    @onTransformEnd="onNodeTransformEnd"
                    @onMousePosition="onNodeMousePositionUpdated"
                    @onCoordinateChoosed="onStageClick"
                    @onLeftClick="onNodeSelect"
                    @onRightClick="onNodeSelectWithActionPanel"
                    @onSaving="handleSaving"
                    @onSaveSuccess="handleSaveSuccess"
                    @onSaveFail="handleSaveError"
                ></RangeNode>
            </v-layer>
        </CanvasLayout>

        <!-- TOOLBAR -->
        <CanvasToolbar
            :show="show_toolbar"
            :coordinate="toolbar_coordinate"
            :node="nodes_selected[0]"
        >
            <!-- POSITION TOOLBAR -->
            <template v-if="is_nodes_selected_position">

                <template v-if="!nodes_selected[0].attrs.model.locked">
                    <InputColor
                        popover-x="left"
                        v-b-popover.hover.top="$t('gameboard.toolbar.color')"
                        :value="nodes_selected[0].attrs.model.color"
                        @input="updateColor($event)"
                    ></InputColor>

                    <transition name="fade" mode="out-in">
                        <i
                            class="actions__item zmdi zmdi-square-down"
                            :class="
                                nodes_selected[0].attrs.model.color_child
                                    ? 'text-success'
                                    : 'text-danger'
                            "
                            @click="
                                toggleColorChild(nodes_selected[0].attrs.model)
                            "
                            v-b-popover.hover.top="
                                $t(
                                    nodes_selected[0].attrs.model.color_child
                                        ? 'gameboard.toolbar.notColorChild'
                                        : 'gameboard.toolbar.colorChild'
                                )
                            "
                        ></i>
                    </transition>

                    <template v-if="!this.hide_toolbar_items_premium_gameboard">
                        <span class="separator"></span>

                        <i
                            v-if="is_one_node_selected"
                            v-b-popover.hover.top="$t('gameboard.toolbar.addStack')"
                            class="actions__item zmdi zmdi-playlist-plus"
                            @click="
                                showAddStackForm(nodes_selected[0].attrs.model.id)
                            "
                        ></i>

                        <i
                            v-b-popover.hover.top="$t('gameboard.toolbar.edit')"
                            class="actions__item zmdi zmdi-edit"
                            @click="
                                showPositionEditForm(nodes_selected[0].attrs.model)
                            "
                        ></i>

                        <i
                            v-if="is_one_node_selected"
                            v-b-popover.hover.top="
                                $t('gameboard.toolbar.duplicate')
                            "
                            class="actions__item zmdi zmdi-plus-circle-o-duplicate"
                            @click="
                                duplicatePosition(nodes_selected[0].attrs.model)
                            "
                        ></i>

                        <i
                            v-b-popover.hover.top="$t('gameboard.toolbar.delete')"
                            class="actions__item zmdi text-danger zmdi-delete"
                            @click="confirmDeletion"
                        ></i>
                    </template>

                    <span class="separator"></span>

                    <i
                        v-if="is_one_node_selected && !this.hide_toolbar_items_premium_gameboard"
                        v-b-popover.hover.top="
                            $t('gameboard.toolbar.set_default_size')
                        "
                        class="actions__item zmdi zmdi-aspect-ratio-alt"
                        @click="
                            updateNodeDefaultSize(nodes_selected[0].attrs.model)
                        "
                    ></i>
                </template>

                <!-- LOCK -->
                <transition name="fade" mode="out-in">
                    <i
                        class="actions__item zmdi"
                        :class="
                            nodes_selected[0].attrs.model.locked
                                ? 'zmdi-lock text-danger'
                                : 'zmdi-lock-open text-success'
                        "
                        @click="toggleLock"
                        v-b-popover.hover.top="
                            $t(
                                nodes_selected[0].attrs.model.locked
                                    ? 'gameboard.toolbar.unlock'
                                    : 'gameboard.toolbar.lock'
                            )
                        "
                    ></i>
                </transition>
            </template>
            <!-- STACK TOOLBAR -->
            <template v-else-if="is_one_node_selected && is_nodes_selected_stack">
                <template
                    v-if="
                        !isPositionLocked(
                            nodes_selected[0].attrs.model.position_id
                        )
                    "
                >
                    <InputColor
                        popover-x="left"
                        v-b-popover.hover.top="$t('gameboard.toolbar.color')"
                        :value="nodes_selected[0].attrs.model.color"
                        @input="updateColor($event)"
                    ></InputColor>

                    <template v-if="!this.hide_toolbar_items_premium_gameboard">
                        <span class="separator"></span>

                        <i
                            v-b-popover.hover.top="$t('gameboard.toolbar.addRange')"
                            class="actions__item zmdi zmdi-playlist-plus"
                            @click="
                                mutateRangeAdd(nodes_selected[0].attrs.model.id)
                            "
                        ></i>

                        <i
                            v-b-popover.hover.top="$t('gameboard.toolbar.edit')"
                            class="actions__item zmdi zmdi-edit"
                            @click="
                                showStackEditForm(nodes_selected[0].attrs.model)
                            "
                        ></i>

                        <i
                            v-b-popover.hover.top="$t('gameboard.toolbar.delete')"
                            class="actions__item zmdi text-danger zmdi-delete"
                            @click="confirmDeletion"
                        ></i>

                        <span class="separator"></span>

                        <i
                            class="actions__item zmdi zmdi-long-arrow-up"
                            v-b-popover.hover.top="$t('gameboard.toolbar.up')"
                            v-show="nodes_selected[0].attrs.model.order !== 1"
                            @click="
                                mutateStackUpdateOrder(
                                    nodes_selected[0].attrs.model
                                )
                            "
                        ></i>

                        <i
                            class="actions__item zmdi zmdi-long-arrow-down"
                            v-b-popover.hover.top="$t('gameboard.toolbar.down')"
                            v-show="!isLastStack(nodes_selected[0].attrs.model)"
                            @click="
                                mutateStackUpdateOrder(
                                    nodes_selected[0].attrs.model,
                                    false
                                )
                            "
                        ></i>
                    </template>
                </template>
                <span v-else class="text-warning">
                    <i class="zmdi zmdi-alert-triangle"></i>
                    {{ $t('gameboard.toolbar.unlockPosition') }}
                </span>
            </template>

            <!-- RANGE TOOLBAR -->
            <template v-if="is_nodes_selected_range">
                <template v-if="!nodes_selected[0].attrs.model.locked && !this.hide_toolbar_items_premium_gameboard">
                    <i
                        v-if="is_one_node_selected"
                        v-b-popover.hover.top="$t('gameboard.toolbar.edit')"
                        class="actions__item zmdi zmdi-edit"
                        @click="
                            showRangeEditForm(nodes_selected[0].attrs.model)
                        "
                    ></i>

                    <i
                        v-b-popover.hover.top="$t('gameboard.toolbar.reset')"
                        class="
                            actions__item
                            zmdi zmdi-format-color-reset
                            text-warning
                        "
                        @click="confirmRangeReset"
                    ></i>

                    <i
                        v-b-popover.hover.top="$t('gameboard.toolbar.delete')"
                        class="actions__item zmdi text-danger zmdi-delete"
                        @click="confirmDeletion"
                    ></i>

                    <span class="separator"></span>

                    <i
                        v-if="is_one_node_selected"
                        v-b-popover.hover.top="
                            $t('gameboard.toolbar.set_default_size')
                        "
                        class="actions__item zmdi zmdi-aspect-ratio-alt"
                        @click="
                            updateNodeDefaultSize(nodes_selected[0].attrs.model)
                        "
                    ></i>

                    <i
                        v-if="is_one_node_selected"
                        v-b-popover.hover.top="
                            $t('gameboard.toolbar.set_default_pos')
                        "
                        class="actions__item zmdi zmdi-arrows"
                        @click="
                            updateRangeDefaultPos(nodes_selected[0].attrs.model)
                        "
                    ></i>
                </template>

                <!-- LOCK -->
                <transition name="fade" mode="out-in">
                    <i
                        class="actions__item zmdi"
                        :class="
                            nodes_selected[0].attrs.model.locked
                                ? 'zmdi-lock text-danger'
                                : 'zmdi-lock-open text-success'
                        "
                        @click="toggleLock"
                        v-b-popover.hover.top="
                            $t(
                                nodes_selected[0].attrs.model.locked
                                    ? 'gameboard.toolbar.unlock'
                                    : 'gameboard.toolbar.lock'
                            )
                        "
                    ></i>
                </transition>
            </template>
        </CanvasToolbar>

        <!-- CELL HOVER TOOLBAR -->
        <CanvasToolbar
            v-show="show_toolbar_cell"
            :coordinate="toolbar_cell_coordinate"
            :node="node_cell"
        >
            <template v-if="!!node_cell">
                <CustomBadge
                    v-for="range_color in node_cell.attrs.model.colors"
                    :key="`custombadge#${range_color.id}`"
                    :bgColor="range_color.color"
                >
                    {{ range_color.legend }}
                    <span v-if="range_color.pivot.mode === 'fill'">
                        ({{
                            (
                                parseFloat(range_color.pivot.weight) * 100
                            ).toFixed(2)
                        }}%)
                    </span>
                </CustomBadge>
            </template>
        </CanvasToolbar>

        <CanvasRangeColorPalette
            v-if="show_range_color_palette"
            ref="rangeColorPalette"
            :node="nodes_selected[0]"
            :range="nodes_selected[0].attrs.model"
            :cells="nodes_selected[0].attrs.cells"
            @onSaving="handleSaving"
            @onSaveSuccess="handleColorPaletteSaveSuccess"
            @onSaveFail="handleSaveError"
            @onPaint="handlePaint"
            @onStopPaint="handleStopPaint"
            @onResetColor="handleResetColor"
            @onImportClipboard="handleImportClipboard"
        ></CanvasRangeColorPalette>

        <ModalForm ref="modalEditForm" :title="$t('gameboard.modal.title')">
            <template #form-content="{ model }">
                <div class="form-group" v-if="is_one_node_selected">
                    <label>{{ $t('common.name') }}</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="model.name"
                    />
                </div>

                <div class="form-group" v-if="is_nodes_selected_range">

                    <label class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            v-model="model.show_prefix"
                        />
                        <span class="custom-control-indicator"></span>
                        <span class="custom-control-description">
                            {{ $t('gameboard.form.edit.show_prefix') }}
                        </span>
                    </label>
                </div>

                <div
                    class="form-group"
                    v-if="model.color_preset_id !== undefined"
                >
                    <label>
                        {{ $t('gameboard.form.edit.color_palette_preset') }}
                    </label>
                    <div class="select">
                        <select
                            class="form-control"
                            v-model="model.color_preset_id"
                        >
                            <option value="">
                                {{ $t('common.action.choose') }}
                            </option>
                            <option
                                v-for="color_preset in color_presets"
                                :key="'color_preset_' + color_preset.id"
                                :value="color_preset.id"
                            >
                                {{ color_preset.name }}
                            </option>
                        </select>
                    </div>

                    <div class="alert alert-warning p-2 mt-3">
                        <i class="zmdi zmdi zmdi-alert-triangle"></i>&nbsp;
                        {{ $t('gameboard.form.edit.alert') }}
                    </div>
                </div>
            </template>
        </ModalForm>

        <ModalForm ref="modalAddStackForm" :title="$t('gameboard.form.addStack.title')">
            <template #form-content="{ model }">
                <div class="form-group" v-if="is_one_node_selected">
                    <label>{{ $t('common.quantity') }}</label>
                    <input
                        type="number"
                        class="form-control"
                        min="1"
                        max="25"
                        step="1"
                        v-model="model.quantity"
                    />
                    <label>{{ $t('gameboard.form.addStack.startVal') }}</label>
                    <input
                        type="number"
                        class="form-control"
                        min="1"
                        max="25"
                        step="1"
                        v-model="model.startVal"
                    />
                    <label>{{ $t('gameboard.form.addStack.padding') }}</label>
                    <input
                        type="number"
                        class="form-control"
                        min="-5"
                        max="5"
                        step="1"
                        v-model="model.padding"
                    />
                </div>
            </template>
        </ModalForm>

        <ModalDeleteConfirmation ref="modalDelete"></ModalDeleteConfirmation>
        <ModalConfirmation
            ref="modalConfirmSizeConfiguration"
            :title="$t('gameboard.modal.resize.title')"
        >
            <template #label>
                {{ $t('gameboard.modal.resize.label') }}
            </template>
        </ModalConfirmation>
        <ModalConfirmation ref="modalConfirmRangePosConfiguration" :title="$t('gameboard.modal.rangePos.title')">
            <template #label>
                {{ $t('gameboard.modal.rangePos.label') }}
            </template>
        </ModalConfirmation>
        <ModalConfirmation ref="modalConfirm" type="danger"></ModalConfirmation>
    </section>
</template>

<style lang="scss" scoped>
#gameboard {
    height: 100%;
    position: relative;
}

.gameboard-cursor-target {
    cursor: crosshair;
}

.main-toolbar {
    height: 3rem;
    padding: 0.05rem 1rem 0;
    margin-bottom: 0;
    background: none;

    .zmdi-hc-2x {
        font-size: 1.5em;
    }

    .separator {
        margin: 0 1.2rem;
    }
}

.separator {
    display: inline-block;
    width: 2px;
    background-color: rgba(255, 255, 255, 0.4);
    height: 50%;
    margin: 0 1.8rem;
    border-radius: 20%;
}
</style>
