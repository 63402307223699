/**
 * Choose light or dark color based on bg color
 * @see https://www.w3.org/TR/WCAG20/#relativeluminancedef
 */
export function pickTextColorBasedOnBgColor(bgColor, lightColor, darkColor) {
    const L_CONSTANT = 0.213, // Originally was 0.185
        color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor,
        r = parseInt(color.substring(0, 2), 16), // hexToR
        g = parseInt(color.substring(2, 4), 16), // hexToG
        b = parseInt(color.substring(4, 6), 16), // hexToB
        uicolors = [r / 255, g / 255, b / 255],
        c = uicolors.map(col =>
            col <= 0.03928 ? col / 12.92 : Math.pow((col + 0.055) / 1.055, 2.4)
        ),
        L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2] // Luminance

    return L > L_CONSTANT ? darkColor : lightColor
}
