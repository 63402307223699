<script>
import { setupVueI18nMessages } from '/renderer/config/i18n'
import fr from '/renderer/lang/plugins/modal/fr.json'
import en_GB from '/renderer/lang/plugins/modal/en_GB.json'
const translations = { fr, en_GB }

export default {
    i18n: setupVueI18nMessages(translations),
    props: {
        title: {
            type: String,
        },
        type: {
            type: String,
            default: 'primary',
        },
        header: {
            type: Boolean,
            default: true,
        },
    },
    components: {},
    computed: {
        modal_title() {
            return this.title || this.$t('modal.confirmation.title')
        },
    },
    methods: {
        onConfirm(callback) {
            this.callback = callback
        },
        show() {
            this.showModal = true
        },
        closeModal() {
            this.showModal = false
        },
        confirm() {
            this.$emit('on-confirm')
            this.callback()
            this.closeModal()
        },
        cancel() {
            this.closeModal()
        },
    },
    data() {
        return {
            showModal: false,
            callback: () => {},
        }
    },
}
</script>

<template>
    <b-modal
        class="modal__confirmation"
        :title="modal_title"
        v-model="showModal"
        size="sm"
        hide-header-close
    >
        <div class="h3" v-if="header">
            <slot name="label">
                {{ $t('modal.confirmation.subtitle') }}
            </slot>
        </div>

        <slot name="content"></slot>

        <template #modal-footer>
            <button type="button" class="btn btn-link" @click.prevent="cancel">
                {{ $t('common.action.cancel') }}
            </button>
            <button
                type="button"
                :class="`btn btn-${type}`"
                @click.prevent="confirm"
            >
                {{ $t('common.action.confirm') }}
            </button>
        </template>
    </b-modal>
</template>

<style lang="scss"></style>
