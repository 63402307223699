export default [
    {
        name: 'default',
    },
    {
        color: '#000A12',
        name: 'blue',
    },
    {
        color: '#020012',
        name: 'indigo',
    },
    {
        color: '#E8DA00',
        name: 'mustard',
        mode: 'color-dodge',
        opacity: 0.5,
    },
    {
        color: '#D1BC13',
        name: 'ochre',
        mode: 'soft-light',
        opacity: 0.5,
    },
    {
        color: '#27000B',
        name: 'bordeaux',
        opacity: 0.5,
    },
    {
        color: '#0F2600',
        name: 'green',
        opacity: 0.45,
    },
    {
        color: '#261500',
        name: 'brown',
        opacity: 0.2,
    },
    {
        color: '#eaeaea',
        name: 'grey',
        mode: 'soft-light',
    },
]
