<script>
import { setupVueI18nMessages } from '/renderer/config/i18n'

import fr from '/renderer/lang/components/screens/error/notFound/fr.json'
import en_GB from '/renderer/lang/components/screens/error/notFound/en_GB.json'
const translations = { fr, en_GB }

export default {
    i18n: setupVueI18nMessages(translations),
}
</script>

<template>
    <div id="error__404_content">
        <h1>{{ $t('common.oops') }}... :-(</h1>
        <h2>{{ $t('error-screen.not-found') }}</h2>
        <p>
            <button class="btn btn-primary" @click.prevent="$router.push('/')">
                <i class="zmdi zmdi-arrow-home"></i>
                {{ $t('common.action.back-main') }}
            </button>
        </p>
    </div>
</template>
