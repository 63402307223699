// import { remote, ipcRenderer } from 'electron'
// import os from 'os'
// import ip from 'ip'
import Vue from 'vue'
import axios from 'axios'

import router from './config/router'
import i18n from './config/i18n'
import store from './store'
import apolloProvider from './config/apollo'
import plugins from './plugins'

import env from '../common/env'

import Main from './applications/Main'

/**
 * @deprecated since 1.0.0-beta.35
 */
// loggerInit("rendererProcessLogger")

/**
 * @deprecated since 1.0.0-beta.35
 */
// const app_component_name = process.argv.includes("--loader-window") ? "Loader" : "Main"
// , { app } = remote

Vue.config.productionTip = env.isProduction()
Vue.config.devtools = env.isDevelopment() || env.isDebugBuild()

/**
 * @deprecated since 1.0.0-beta.35
 */
// Vue.os = Vue.prototype.$os = os
// Vue.ip = Vue.prototype.$ip = ip
// Vue.shell = Vue.prototype.$shell = remote.shell
// Vue.ipc = Vue.prototype.$ipc = ipcRenderer
Vue.app = Vue.prototype.$app = {
    version: process.env.APP_VERSION || '0.0.0',
    name: process.env.APP_NAME || app.name,
    user: { is_admin: false }, // todo export to vuex
    getPath: () => { '' },
}
Vue.axios = Vue.prototype.$axios = axios

Object.keys(plugins).forEach(key => Vue.use(plugins[key]))

new Vue({
    render: h => {
        /**
         * @deprecated since 1.0.0-beta.35
         */
        // const app = require(`./applications/${app_component_name}`).default
        // console.log(app)
        return h(Main)
    },
    router,
    i18n,
    store,
    apolloProvider,
}).$mount('#app')