const processGameboardData = model => {
    delete model.__typename

    if (!!model.positions)
        model.positions = { create: model.positions.map(processPositionData) }

    return model
}

const processPositionData = model => {
    delete model.__typename
    delete model.id

    if (!!model.coordinate)
        model.coordinate = processHasOneData(model.coordinate)
    else delete model.coordinate

    if (!!model.size) model.size = processHasOneData(model.size)
    else delete model.size

    if (!!model.stacks && model.stacks.length > 0)
        model.stacks = { create: model.stacks.map(processStackData) }
    else delete model.stacks

    return model
}

const processStackData = model => {
    delete model.__typename
    delete model.id
    delete model.order // Always sorted by order by default, so it will not be a problem when importing data

    if (!!model.ranges && model.ranges.length > 0)
        model.ranges = { create: model.ranges.map(processRangeData) }
    else delete model.ranges

    return model
}

const processRangeData = model => {
    delete model.__typename
    delete model.id

    if (!!model.coordinate)
        model.coordinate = processHasOneData(model.coordinate)
    else delete model.coordinate

    if (!!model.size) model.size = processHasOneData(model.size)
    else delete model.size

    if (!!model.rangeColors && model.rangeColors.length > 0)
        model.rangeColors = {
            create: model.rangeColors.map(processRangeColorData),
        }
    else delete model.rangeColors

    if (!!model.cellsData)
        model.cellsData = { create: processRangeCellsData(model.cellsData) }

    return model
}

const processRangeColorData = model => {
    delete model.__typename
    delete model.id

    return model
}

const processRangeCellsData = model => {
    delete model.__typename
    delete model.id
    delete model.range_id

    return model
}

const processHasOneData = model => {
    delete model.__typename
    delete model.id

    return { create: model }
}

export {
    processGameboardData,
    processPositionData,
    processStackData,
    processRangeData,
}
