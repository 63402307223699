import { ModalPlugin as ModalBootstrapVuePlugin } from 'bootstrap-vue'
import ModalConfirmation from './Modal/Confirmation'
import ModalDeleteConfirmation from './Modal/DeleteConfirmation'
import ModalForm from './Modal/Form'

const ModalPlugin = {
    install(Vue) {
        // Dependancy
        Vue.use(ModalBootstrapVuePlugin)
        // Custom component
        Vue.component('ModalConfirmation', ModalConfirmation)
        Vue.component('ModalDeleteConfirmation', ModalDeleteConfirmation)
        Vue.component('ModalForm', ModalForm)
    },
}

export default ModalPlugin
