<script>
import { setupVueI18nMessages } from '/renderer/config/i18n'
import fr from '/renderer/lang/components/partials/canvas/fr.json'
import en_GB from '/renderer/lang/components/partials/canvas/en_GB.json'
const translations = { fr, en_GB }

const GRID_BASE_SCALE_FACTOR = 1.5

export default {
    i18n: setupVueI18nMessages(translations),
    props: {
        scale: {
            type: Number,
            default: null,
        },
        position: {
            type: Object,
            default: null,
        },
        selectionCallback: {
            type: Function,
            default: () => true,
        },
        centerCustomPositionCallback: {
            type: Function,
            default: () => {},
        },
    },
    computed: {
        scale_value() {
            return parseInt(this.config.canvas.scaleX * 100, 10)
        },
        stage() {
            return this.$refs.mainStage.getStage()
        },
    },
    methods: {
        buildGrid() {
            if (!this.stage) return

            this.$refs.gridGroup.getNode().clearCache()

            this.grid_lines = []

            const width = window.innerWidth,
                height = window.innerHeight,
                scale_factor =
                    GRID_BASE_SCALE_FACTOR +
                    (1 - this.config.canvas.scaleX + 1),
                stroke_width_list = [1, 0.5],
                stroke = '#ffffff22',
                stage_x = this.stage.x(),
                stage_y = this.stage.y(),
                start_x =
                    Math.floor(
                        (-Math.abs(stage_x) - width * scale_factor) /
                            this.grid_line_spacing
                    ) * this.grid_line_spacing,
                end_x = -start_x,
                start_y =
                    Math.floor(
                        (-Math.abs(stage_y) - height * scale_factor) /
                            this.grid_line_spacing
                    ) * this.grid_line_spacing,
                end_y = -start_y,
                nb_lines_vertical = (end_x - start_x) / this.grid_line_spacing,
                nb_lines_horizontal = (end_y - start_y) / this.grid_line_spacing

            for (let i = 0; i < nb_lines_vertical; i++) {
                const x = Math.round(start_x + i * this.grid_line_spacing),
                    strokeWidth = stroke_width_list[i % 2]
                this.grid_lines.push({
                    stroke,
                    strokeWidth,
                    points: [x, start_y, x, end_y],
                })
            }

            for (let j = 0; j < nb_lines_horizontal; j++) {
                const y = Math.round(start_y + j * this.grid_line_spacing),
                    strokeWidth = stroke_width_list[j % 2]
                this.grid_lines.push({
                    stroke,
                    strokeWidth,
                    points: [start_x, y, end_x, y],
                })
            }

            this.$refs.gridGroup.getNode().cache()

            //             console.debug(
            //                 `
            // stage_x ${stage_x}
            // stage_y ${stage_y}
            // scale_factor ${scale_factor}
            // scale_width_factor ${scale_width_factor}
            // scale_height_factor ${scale_height_factor}
            // start_x ${start_x}
            // end_x ${end_x}
            // end_y ${end_y}
            //                 `
            //             )
        },
        centerCanvas() {
            const pos = this.centerCustomPositionCallback()

            if (!!pos) {
                pos.x = 0 - pos.x
                pos.y = 0 - pos.y
            }

            this.setCanvasPosition(pos) // if not pos default is auto x:0;y:0
            this.$emit('onDragEnd', this.stage)
        },
        resizeCanvas() {
            this.config.canvas.width = this.$el.clientWidth
            this.config.canvas.height = this.$el.clientHeight
        },
        setCanvasPosition(pos) {
            if (!pos) pos = { x: 0, y: 0 }
            this.stage.position(pos) // Not with config, it will not center the canvas if we change the config with same values
            this.batchDraw()
        },
        scaleCanvas(scale) {
            if (
                scale <= this.config.scaler.min ||
                scale > this.config.scaler.max
            )
                return

            scale = scale / 100

            this.$set(this.config.canvas, 'scaleX', scale)
            this.$set(this.config.canvas, 'scaleY', scale)

            this.$emit('onScaleChange', scale)

            if (!!this.build_grid_timeout) clearTimeout(this.build_grid_timeout)
            this.build_grid_timeout = setTimeout(() => {
                this.buildGrid()
                this.build_grid_timeout = null
            }, 500)
        },
        scaleUpCanvas() {
            let step =
                this.scale_value <
                    this.config.scaler.min + this.config.scaler.step ||
                this.scale_value >=
                    this.config.scaler.max - this.config.scaler.step
                    ? this.config.scaler.step_micro
                    : this.config.scaler.step
            this.scaleCanvas(this.scale_value + step)
        },
        scaleDownCanvas() {
            let step =
                this.scale_value <=
                    this.config.scaler.min + this.config.scaler.step ||
                this.scale_value >
                    this.config.scaler.max - this.config.scaler.step
                    ? this.config.scaler.step_micro
                    : this.config.scaler.step
            this.scaleCanvas(this.scale_value - step)
        },
        toggleStageDraggable() {
            this.$set(
                this.config.canvas,
                'draggable',
                !this.config.canvas.draggable
            )
        },
        toggleStageDraggableAndLocking() {
            this.drag_locked = !this.config.canvas.draggable
            this.toggleStageDraggable()
            this.$emit('onToggleDrag', this.drag_locked)
        },
        handleMiddleMouseDownOnStage(ev) {
            if (this.drag_locked) return

            if (this.simulate_middle_click) {
                this.simulate_middle_click = false
                return
            }

            this.toggleStageDraggable()

            setTimeout(() => {
                this.simulate_middle_click = true
                ev.target.dispatchEvent(ev)
            }, 100)
        },
        handleMiddleMouseUpOnStage() {
            if (this.drag_locked) return
            this.toggleStageDraggable()
        },
        handleWheel(event) {
            if (!this.ctrl_pushed) return
            event.preventDefault()
            if (event.deltaY < 0) this.scaleUpCanvas()
            else this.scaleDownCanvas()
        },
        handleShortkey({ srcKey }) {
            switch (srcKey) {
                case 'space':
                    this.toggleStageDraggableAndLocking()
                    break
                case 'ctrl':
                    this.ctrl_pushed = !this.ctrl_pushed
                    break
            }
        },
        onDragMove({ target }) {
            this.is_selecting = false
            this.$emit('onDragMove', target)
        },
        onDragEnd({ target }) {
            this.buildGrid()
            this.$emit('onDragEnd', target)
        },
        onClick(ev) {
            if (this.is_selecting) return
            this.$emit('onClick', ev)
        },
        onMouseDown({ evt: { button } }) {
            if (!this.stage || button === 1) return // 1 = middle mouse button click

            this.is_mouse_down = true
            this.is_right_click = button === 2

            const pointer_position = this.stage.getPointerPosition(),
                stage_absolute_position = this.stage.absolutePosition(),
                stage_absolute_scale = this.stage.getAbsoluteScale()

            this.pointer.x1 =
                (pointer_position.x - stage_absolute_position.x) /
                stage_absolute_scale.x
            this.pointer.x2 =
                (pointer_position.x - stage_absolute_position.x) /
                stage_absolute_scale.x
            this.pointer.y1 =
                (pointer_position.y - stage_absolute_position.y) /
                stage_absolute_scale.y
            this.pointer.y2 =
                (pointer_position.y - stage_absolute_position.y) /
                stage_absolute_scale.y
        },
        onMouseMove() {
            if (!this.is_mouse_down) return
            if (!this.is_selecting) this.is_selecting = true

            const pointer_position = this.stage.getPointerPosition(),
                stage_absolute_position = this.stage.absolutePosition(),
                stage_absolute_scale = this.stage.getAbsoluteScale()

            this.pointer.x2 =
                (pointer_position.x - stage_absolute_position.x) /
                stage_absolute_scale.x
            this.pointer.y2 =
                (pointer_position.y - stage_absolute_position.y) /
                stage_absolute_scale.y

            this.config.selecter = {
                ...this.config.selecter,
                x: Math.min(this.pointer.x1, this.pointer.x2),
                y: Math.min(this.pointer.y1, this.pointer.y2),
                width: Math.abs(this.pointer.x2 - this.pointer.x1),
                height: Math.abs(this.pointer.y2 - this.pointer.y1),
            }
        },
        onMouseUp() {
            this.is_mouse_down = false

            if (!this.is_selecting) return

            const box = this.$refs.selectionRect.getNode().getClientRect()
            const nodes = this.stage
                .find(this.selectionCallback)
                .filter(node =>
                    Konva.Util.haveIntersection(box, node.getClientRect())
                )

            this.config.selecter.width = 0
            this.config.selecter.height = 0
            setTimeout(() => (this.is_selecting = false)) // is_selecting can be check in Click event

            this.$emit('onSelection', {
                is_right_click: this.is_right_click,
                nodes,
            })
        },
        enableGrid() {
            if (!this.use_grid) return
            this.config.grid_group.opacity = 1
            this.batchDraw()
        },
        disableGrid() {
            if (!this.use_grid) return
            this.hideGrid()
            this.batchDraw()
        },
        hideGrid() {
            this.config.grid_group.opacity = 0
        },
        convertCoordinate(value) {
            return (
                Math.round(value / this.grid_line_spacing) *
                this.grid_line_spacing
            )
        },
        updateNodeBelongGrid(nodes) {
            if (!this.use_grid) return

            if (!Array.isArray(nodes)) nodes = [nodes]

            let diff_pos = null

            nodes.forEach(node => {
                // console.debug("NODE", node._id, node.attrs.model.name)
                // console.debug("ORIGINAL OLD", node.attrs.old_x, node.attrs.old_y)
                // console.debug("ORIGINAL NEW", node.attrs.x, node.attrs.y)

                const pos = {
                        x: this.convertCoordinate(node.attrs.x),
                        y: this.convertCoordinate(node.attrs.y),
                    },
                    old_pos = {
                        x: this.convertCoordinate(node.attrs.old_x),
                        y: this.convertCoordinate(node.attrs.old_y),
                    }

                if (!diff_pos)
                    // /!\ old_x and old_y are custom !!! See nodes component which attach xChange / yChange event
                    diff_pos = { x: pos.x - old_pos.x, y: pos.y - old_pos.y }

                const final_pos = {
                    x: old_pos.x + diff_pos.x,
                    y: old_pos.y + diff_pos.y,
                }

                // console.debug("after conversion", old_pos, pos, diff_pos)
                // console.debug("new_pos", final_pos)
                // console.debug("====================")

                node.position(final_pos)

                this.batchDraw()
            })
        },
        batchDraw() {
            if (!this.stage) return
            this.stage.batchDraw()
        },
    },
    watch: {
        scale: {
            handler(scale) {
                this.$nextTick(() => this.scaleCanvas((scale || 1) * 100))
            },
            immediate: true,
        },
        position: {
            handler(pos) {
                this.$nextTick(() => this.setCanvasPosition(pos))
            },
            immediate: true,
        },
    },
    data() {
        return {
            build_grid_timeout: null,
            is_mouse_down: false,
            is_right_click: false,
            is_selecting: false,
            ctrl_pushed: false,
            simulate_middle_click: false,
            drag_locked: false,
            use_grid: true,
            grid_line_spacing: 15,
            grid_lines: [],
            selected_shapes: [],
            pointer: { x1: 0, x2: 0, y1: 0, y2: 0 },
            config: {
                canvas: {
                    width: 0,
                    height: 0,
                    scaleX: 1,
                    scaleY: 1,
                    draggable: false,
                },
                grid: {
                    listening: false,
                    shadowForStrokeEnabled: false,
                    strokeScaleEnabled: false,
                    strokeEnabled: false,
                    shadowEnabled: false,
                    hitStrokeWidth: 0,
                },
                grid_group: { opacity: 0 },
                scaler: { step: 5, step_micro: 1, min: 0, max: 400 },
                selecter: { fill: '#00000077', stroke: '#000000' },
            },
        }
    },
    mounted() {
        this.resizeCanvas()
        this.buildGrid()

        window.addEventListener('resize', this.resizeCanvas)
        window.addEventListener('resize', this.buildGrid)
    },
    destroyed() {
        window.removeEventListener('resize', this.resizeCanvas)
        window.removeEventListener('resize', this.buildGrid)
    },
}
</script>

<template>
    <div
        class="canvas_layout"
        :class="{ 'canvas_layout__on-stage-dragging': config.canvas.draggable }"
        v-shortkey.push="{ space: ['space'], ctrl: ['ctrl'] }"
        @shortkey="handleShortkey"
        @mousedown.middle="handleMiddleMouseDownOnStage"
        @mouseup.middle="handleMiddleMouseUpOnStage"
        @wheel="handleWheel"
    >
        <!-- TOP BAR ACTIONS -->
        <div class="canvas_layout__top_action_bar container">
            <slot name="top_action_bar_content_before"></slot>

            <div>
                <!-- ZOOM CONTROL -->
                <div
                    class="btn-group canvas_layout__scale_group"
                    ref="canvas_layout__scale_buttons"
                >
                    <span class="btn btn-sm btn-light" @click="scaleDownCanvas">
                        <i class="zmdi zmdi-minus"></i>
                    </span>

                    <span class="btn btn-sm btn-light">
                        {{ scale_value }}%
                    </span>

                    <span class="btn btn-sm btn-light" @click="scaleUpCanvas">
                        <i class="zmdi zmdi-plus"></i>
                    </span>
                </div>
                <!-- DRAG CONTROL -->
                <span
                    class="btn btn-sm btn-light"
                    ref="canvas_layout__draggable_btn"
                    :class="{ active: config.canvas.draggable }"
                    @mouseup.left="toggleStageDraggableAndLocking"
                >
                    <i class="zmdi zmdi-arrows"></i>
                </span>
                <!-- CENTER CANVAS -->
                <span
                    class="btn btn-sm btn-light"
                    ref="canvas_layout__center_btn"
                    @click="centerCanvas"
                >
                    <i class="zmdi zmdi-center-focus-strong"></i>
                </span>
                <!-- GRID -->
                <span
                    class="btn btn-sm btn-light"
                    ref="canvas_layout__enable_grid_btn"
                    :class="{ active: use_grid }"
                    @click="use_grid = !use_grid"
                >
                    <i v-if="use_grid" class="zmdi zmdi-grid"></i>
                    <i v-else class="zmdi zmdi-grid-off"></i>
                </span>
            </div>

            <slot name="top_action_bar_content_after"></slot>
        </div>

        <!-- CANVAS -->
        <v-stage
            ref="mainStage"
            :config="config.canvas"
            @dragmove.self="onDragMove"
            @dragEnd.self="onDragEnd"
            @click.self="onClick"
            @mousedown.self="onMouseDown"
            @mousemove="onMouseMove"
            @mouseup="onMouseUp"
        >
            <v-layer ref="gridLayer" :config="config.grid">
                <v-group ref="gridGroup" :config="config.grid_group">
                    <v-line
                        v-for="config in grid_lines"
                        :config="config"
                        :key="config.points.join('_')"
                    ></v-line>
                </v-group>
            </v-layer>

            <slot></slot>

            <v-layer ref="layoutLayer">
                <v-rect
                    ref="selectionRect"
                    v-if="is_selecting"
                    :config="config.selecter"
                ></v-rect>
            </v-layer>
        </v-stage>

        <!-- BOTTOM BAR ACTIONS -->
        <div class="canvas_layout__bottom_action_bar">
            <slot name="bottom_action_bar_content_before"></slot>
            <slot name="bottom_action_bar_content_after"></slot>
        </div>

        <!-- POPOVERS -->
        <b-popover
            :target="() => $refs.canvas_layout__center_btn"
            triggers="hover"
            placement="top"
        >
            <template #title>
                {{ $t('canvas.action.center') }}
            </template>
        </b-popover>

        <b-popover
            :target="() => $refs.canvas_layout__draggable_btn"
            triggers="hover"
            placement="top"
        >
            <template #title>
                {{ $t('canvas.action.pan') }}
                <div>
                    <span class="badge badge-light">{{
                        $t('canvas.shortcut.space')
                    }}</span>

                    {{ $t('common.or') }}

                    <span class="badge badge-light">{{
                        $t('canvas.shortcut.middle-mouse')
                    }}</span>
                </div>
            </template>
        </b-popover>

        <b-popover
            :target="() => $refs.canvas_layout__enable_grid_btn"
            triggers="hover"
            placement="top"
        >
            <template #title>
                <template v-if="use_grid">
                    {{ $t('canvas.action.grid_disable') }}
                </template>
                <template v-else>
                    {{ $t('canvas.action.grid_enable') }}
                </template>
            </template>
        </b-popover>

        <b-popover
            :target="() => $refs.canvas_layout__scale_buttons"
            triggers="hover"
            placement="top"
        >
            <template #title>
                {{ $t('canvas.action.zoom') }}
                <div>
                    <span class="badge badge-light">CTRL</span>
                    +
                    <span class="badge badge-light">{{
                        $t('canvas.shortcut.scroll')
                    }}</span>
                </div>
            </template>
        </b-popover>
    </div>
</template>

<style lang="scss" scoped>
.canvas_layout {
    height: 100%;
    position: relative;

    &.canvas_layout__on-stage-dragging {
        cursor: grabbing;
    }

    .canvas_layout__top_action_bar {
        position: absolute;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        top: -42px;
        left: 0;
        right: 0;
        z-index: 100;

        > * {
            margin: 0;
            margin-left: 30px;
            background: none;
        }
    }

    .canvas_layout__bottom_action_bar {
        position: absolute;
        z-index: 1;
        display: flex;
        bottom: 15px;
        right: 0;

        > * {
            margin-right: 30px;
        }
    }

    .canvas_layout__scale_group {
        > .btn:nth-child(2) {
            width: 80px;
        }
    }
}
</style>
