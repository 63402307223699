import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state() {
        return {
            is_premium_gameboard: false
        }
    },
    getters: {},
    mutations: {
        SET_GAMEBOARD_PREMIUM(state, is_premium) {
            state.is_premium_gameboard = is_premium
        }
    },
    actions: {},
    strict: process.env.NODE_ENV !== 'production'
})

export default store