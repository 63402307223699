<script>
export default {
    props: {
        node: { type: Object },
        show: { type: Boolean, default: false },
        marginBottom: { type: Number, default: 15 },
        coordinate: {
            type: Object,
            required: true,
        },
    },
    computed: {
        canvas_size() {
            const layer = this.node.getLayer()

            if (!layer) return { width: 0, height: 0 }

            let { width, height, pixelRatio } = layer.getCanvas()

            width = width / pixelRatio
            height = height / pixelRatio

            return { width, height }
        },
        compute_position() {
            if (!this.node) return

            let { x, width } = this.node.getClientRect()

            x =
                this.coordinate.x + width > this.canvas_size.width
                    ? this.coordinate.x - this.offsetX
                    : this.coordinate.x

            return {
                left: `${Math.max(0, x)}px`,
                top: `${Math.max(
                    0,
                    this.coordinate.y - this.offsetHeight - this.marginBottom
                )}px`,
            }
        },
    },
    data() {
        return {
            offsetHeight: 60, // TODO: use offsetHeight or toolbar element, however it's still 0 when it appear with v-if or v-show (without transition too)
            offsetX: 120,
        }
    },
}
</script>

<template>
    <transition name="fade" mode="out-in">
        <div v-show="show" :style="compute_position" class="toolbar">
            <slot></slot>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
.toolbar {
    background-color: rgba(0, 0, 0, 0.97);
    position: absolute;
    z-index: 2;
    margin: 0;
    left: 0;
    top: 0;
}
</style>
