<script>
import { setupVueI18nMessages } from '/renderer/config/i18n'
import fr from '/renderer/lang/components/screens/software/dashboard/fr.json'
import en_GB from '/renderer/lang/components/screens/software/dashboard/en_GB.json'
const translations = { fr, en_GB }

export default {
    i18n: setupVueI18nMessages(translations),
    computed: {
        isDashboardRoute() {
            return this.$route.name === 'dashboard'
        },
    },
    methods: {},
}
</script>

<template>
    <div id="dashboard">
        <transition name="fade">
            <div v-if="isDashboardRoute" class="empty">
                <i class="zmdi zmdi-mood-bad"></i>
                {{ $t('dashboard.empty.title') }}
                <span>{{ $t('dashboard.empty.subtitle') }}</span>
            </div>
            <router-view v-else></router-view>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
#dashboard {
    height: 100%;

    .empty {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        color: transparent;
        text-shadow: 0px 0px 7px rgba(109, 109, 109, 0.1),
            1px 1px 0px rgba(0, 0, 0, 0.7);

        i {
            font-size: 10rem;
        }

        > span {
            font-size: 2rem;
        }
    }
}
</style>
