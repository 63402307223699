<script>
/**
 * Default values along grid system spacing
 * @see src\renderer\components\partials\canvas\Layout.vue -> grid_line_spacing
 */
const DEFAULT_WIDTH = 195,
    DEFAULT_HEIGHT = 30

export default {
    props: {
        position: { required: true, type: Object },
        followMouse: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
    },
    computed: {
        color() {
            return this.position.color || this.default_color
        },
        shadow_config() {
            if (!this.selected && !this.is_hovering_group) return {}

            const shadowColor = '#ffffff',
                shadowBlur = this.selected ? 8 : 10,
                shadowOpacity = this.selected ? 0.7 : 0.5

            return { shadowColor, shadowBlur, shadowOpacity }
        },
        base_config() {
            const { x, y } = this.group_scale
            return { width: x, height: y }
        },
        color_config() {
            return {
                fill: this.color + 'aa',
                // stroke: this.color + "ee",
                // strokeWidth: 4
            }
        },
    },
    methods: {
        computeMousePosition() {
            if (!this.followMouse) return

            const stage_absolute_position = this.stage.absolutePosition(),
                stage_absolute_scale = this.stage.getAbsoluteScale(),
                { width, height } = this.base_config

            let mousePosition = this.stage.getPointerPosition()

            mousePosition = {
                x:
                    (this.offset_positioning +
                        mousePosition.x -
                        stage_absolute_position.x) /
                        stage_absolute_scale.x -
                    width / 2,
                y:
                    (this.offset_positioning +
                        mousePosition.y -
                        stage_absolute_position.y) /
                        stage_absolute_scale.y -
                    height / 2,
            }

            this.group_node.position(mousePosition)
            this.$emit('onMousePosition', this.group_node)
        },
        onMouseEnter() {
            this.is_hovering = true
            this.is_hovering_group = true
            this.$emit('onEnter', this.group_node)
        },
        onMouseLeave() {
            this.is_hovering = false
            this.is_hovering_group = false
            this.$emit('onLeave', this.group_node)
        },
        onMouseSubGroupEnter() {
            this.is_hovering = true
        },
        onMouseSubGroupLeave() {
            this.is_hovering = false
        },
        onDragStart() {
            this.$emit('onDragStart', this.group_node)
        },
        onDragMove() {
            this.$emit('onDragMove', this.group_node)
        },
        onDragEnd() {
            this.$emit('onDragEnd', this.group_node)
        },
        onTransformStart() {
            this.$emit('onTransformStart', this.group_node)
        },
        onTransform({ target }) {
            const { scaleX, scaleY } = target.attrs

            this.group_scale.x = Math.abs(this.group_scale.x * scaleX)
            this.group_scale.y = Math.abs(this.group_scale.y * scaleY)

            const size = Object.assign({}, this.position.size || {}, {
                x: this.group_scale.x,
                y: this.group_scale.y,
            })

            this.setGroupNodeModel(Object.assign({}, this.position, { size }))

            this.$nextTick(() => {
                target.scale({ x: 1, y: 1 })
                this.$emit('onTransform', this.group_node)
            })
        },
        onTransformEnd() {
            this.$emit('onTransformEnd', this.group_node)
        },
        onClick({ evt: { button } }) {
            if (this.followMouse)
                this.$emit('onCoordinateChoosed', this.group_node)
            else if (button === 0) this.$emit('onLeftClick', this.group_node)
            // TODO : refactor into lib
            else if (button === 2) this.$emit('onRightClick', this.group_node) // TODO : refactor into lib
        },
        setGroupNodeModel(data = this.position) {
            this.group_node.setAttr('model', data)
        },
    },
    watch: {
        position() {
            this.setGroupNodeModel()
        },
        followMouse: {
            handler(followMouse) {
                if (!followMouse) return
                this.$emit('onLeftClick', this.group_node)
            },
            immediate: true,
        },
    },
    data() {
        return {
            base_config_text: {
                fontSize: 18,
                fontStyle: 'bold',
                fontFamily: 'Oswald',
                align: 'center',
                verticalAlign: 'middle',
            },
            group_scale: { x: DEFAULT_WIDTH, y: DEFAULT_HEIGHT },
            offset_positioning: 10,
            is_hovering: false,
            is_hovering_group: false,
            name: 'positionNode',
            default_color: '#000000',
            old_position: null,
            group_node: null,
            stage: null,
        }
    },
    mounted() {
        if (!!this.position.size) {
            const { x, y } = this.position.size
            this.group_scale = { x, y }
        }

        this.$nextTick(() => {
            this.group_node = this.$refs.groupNode.getNode()
            this.group_node.setAttr('old_x', this.group_node.attrs.x)
            this.group_node.setAttr('old_y', this.group_node.attrs.y)
            this.group_node.on('xChange', ({ oldVal }) =>
                this.group_node.setAttr('old_x', oldVal)
            )
            this.group_node.on('yChange', ({ oldVal }) =>
                this.group_node.setAttr('old_y', oldVal)
            )

            this.stage = this.group_node.getStage()
            this.stage.on('mousemove', this.computeMousePosition)

            if (!this.position.size)
                this.setGroupNodeModel(
                    Object.assign({}, this.position, { size: this.group_scale })
                )
            else this.setGroupNodeModel()
        })
    },
}
</script>

<template>
    <v-group
        ref="groupNode"
        @dragstart="onDragStart"
        @dragmove="onDragMove"
        @dragEnd="onDragEnd"
        @transformstart="onTransformStart"
        @transform="onTransform"
        @transformend="onTransformEnd"
        :config="{
            draggable: !position.locked && is_hovering,
            name,
            ...position.coordinate,
        }"
    >
        <v-rect
            :config="{
                ...base_config,
                ...color_config,
                ...shadow_config,
            }"
            @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave"
            @click="onClick"
        ></v-rect>

        <v-text
            :config="{
                ...base_config,
                ...base_config_text,
                text: position.name,
                fill: 'white',
            }"
            @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave"
            @click="onClick"
        ></v-text>

        <v-group
            @mouseenter="onMouseSubGroupEnter"
            @mouseleave="onMouseSubGroupLeave"
        >
            <slot
                :color="color"
                :config="base_config"
                :config_text="base_config_text"
            ></slot>
        </v-group>
    </v-group>
</template>
