import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    createHttpLink,
    from,
} from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import Vue from 'vue'
import VueApollo from 'vue-apollo'
import router from './router'

import tokenService from '../lib/keychain/token'

Vue.use(VueApollo)

const uri = process.env.API_URL,
    cache = new InMemoryCache(),
    httpLink = createHttpLink({ uri, headers: { accept: 'application/json' } }),
    loggerLink = new ApolloLink((operation, forward) => {
        return forward(operation).map(data => {
            console.debug('Apollo.operation', {
                variables: operation.variables,
                operationName: operation.operationName,
            })
            console.debug('Apollo.response', data)

            return data
        })
    }),
    authorizationLink = setContext((_, { headers }) => {
        const promise = new Promise((resolve, reject) =>
            tokenService.get().then(resolve).catch(reject)
        )
        return promise.then(token => ({
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        }))
    }),
    networkErrorLink = onError(error => {
        console.debug(`Apollo.error`, error)

        if (!error.networkError || !error.networkError.statusCode) return

        const { statusCode } = error.networkError

        if (statusCode < 400 && statusCode >= 500) return

        Vue.notify.mute()
        tokenService
            .clear()
            .then(() =>
                router.push({
                    name: 'login',
                    query: { message: 'common.error.logout' },
                })
            )
    }),
    link = from([loggerLink, authorizationLink, networkErrorLink, httpLink]),
    client = new ApolloClient({ uri, cache, link })

export default new VueApollo({ defaultClient: client })
export { client }
