<script>
import { setupVueI18nMessages } from 'rm-config/i18n'

import {
    importFromClipboard,
    exportToClipboard,
} from '/renderer/lib/pioConverter'

import RangeColorFieldsFragment from '/renderer/apollo/fragments/range_color_fields_fragment.graphql'
import AddRangecolorMutation from '/renderer/apollo/rangecolor/AddRangecolorMutation.graphql'
import UpdateRangecolorMutation from '/renderer/apollo/rangecolor/UpdateRangecolorMutation.graphql'
import DeleteRangecolorMutation from '/renderer/apollo/rangecolor/DeleteRangecolorMutation.graphql'
import UpdateRangeCellsDataMutation from '/renderer/apollo/range_cells_data/UpdateRangeCellsDataMutation.graphql'

import fr from '/renderer/lang/components/partials/canvas/range/colorpalette/fr.json'
import en_GB from '/renderer/lang/components/partials/canvas/range/colorpalette/en_GB.json'
const translations = { fr, en_GB }

const CELL_VALUE = {
    DEFAULT: { value: 5.88 },
    OFFSUIT: { match: 'o', value: 70.59 },
    SUITED: { match: 's', value: 23.53 },
}

export default {
    i18n: setupVueI18nMessages(translations),
    props: {
        node: {
            type: Object,
            required: true,
        },
        range: {
            type: Object,
            required: true,
        },
        cells: {
            type: Array,
            required: true,
        },
    },
    computed: {
        action_element_is_disabled() {
            return !this.$app.user.is_admin && this.$store.state.is_premium_gameboard
        },
        is_painting() {
            return !!this.range_color_paint
        },
        range_colors() {
            return this.range.rangeColors
        },
        range_cells_data() {
            // TODO find a way to parse it from graphql with custom type
            return !this.range.cellsData || !this.range.cellsData.data
                ? {}
                : JSON.parse(this.range.cellsData.data)
        },
        cells_count() {
            return this.cells.length
        },
        grid_size() {
            return this.cells_count / Math.sqrt(this.cells_count)
        },
        number_cells_without_diagonal() {
            return (this.grid_size * (this.grid_size - 1)) / 2
        },
        canvas_size() {
            let { width, height, pixelRatio } = this.node.getLayer().getCanvas()

            width = width / pixelRatio
            height = height / pixelRatio

            return { width, height }
        },
        cells_with_color_data() {
            // Same on Range.vue
            let cells = JSON.parse(JSON.stringify(this.cells))

            cells.forEach(cell => {
                const { id } = cell

                cell.colors = []

                const range_cell_data = this.range_cells_data[id] || []

                range_cell_data.forEach(({ color, mode, weight }) => {
                    weight = parseFloat(weight)

                    let range_color = this.range_colors.find(
                        rc => rc.color === color
                    )
                    if (!range_color) return

                    cell.colors.push({
                        ...range_color,
                        pivot: { mode, weight },
                    })
                })
            })

            return cells
        },
    },
    methods: {
        computePosition() {
            let { x, y, width } = this.node.getClientRect(),
                element_bound = this.$el.getBoundingClientRect()

            x =
                x + width + element_bound.width > this.canvas_size.width
                    ? x - element_bound.width - this.margin
                    : x + width + this.margin

            this.$el.style.left = `${Math.max(0, x)}px`
            this.$el.style.top = `${Math.max(0, y)}px`
        },
        addRangeColor() {
            this.$refs.modalForm.show()
            this.$refs.modalForm.onSubmit(this.mutateRangecolorAdd)
        },
        updateRangeColor(range_color) {
            this.$refs.modalForm.show(range_color)
            this.$refs.modalForm.onSubmit(model => {
                this.mutateRangeCellsData(range_color.color, model.color).then(
                    () => this.mutateRangecolorUpdate(model)
                )
            })
        },
        confirmResetRangeColor(range_color) {
            this.$refs.modalConfirm.show()
            this.$refs.modalConfirm.onConfirm(() => this.reset(range_color))
        },
        confirmDeleteRangeColor(range_color) {
            this.$refs.modalDelete.show(range_color)
            this.$refs.modalDelete.onDelete(range_color =>
                this.mutateRangeColorDelete(range_color)
            )
        },
        mutateRangecolorAdd(model) {
            this.handleSaving()

            const range = { connect: parseInt(this.range.id, 10) }

            this.$apollo
                .mutate({
                    mutation: AddRangecolorMutation,
                    variables: { input: { range, ...model } },
                    update: (cache, { data }) => {
                        const fragment = RangeColorFieldsFragment,
                            id = cache.identify(data.createRangecolor)

                        cache.modify({
                            id: `Range:${this.range.id}`,
                            fields: {
                                rangeColors(existingItemRefs = []) {
                                    const newRef = cache.writeFragment({
                                        id,
                                        fragment,
                                        data,
                                    })
                                    return [...existingItemRefs, newRef]
                                },
                            },
                        })
                    },
                })
                .then(this.handleSuccess)
                .catch(this.handleMutationError)
        },
        mutateRangecolorUpdate({ id, legend, color }) {
            this.handleSaving()

            const promise = this.$apollo.mutate({
                mutation: UpdateRangecolorMutation,
                variables: { input: { id, legend, color } },
            })

            promise.then(this.handleSuccess).catch(this.handleMutationError)

            return promise
        },
        mutateRangeColorDelete({ id }) {
            this.handleSaving()

            this.$apollo
                .mutate({
                    mutation: DeleteRangecolorMutation,
                    variables: { id },
                    update: cache => {
                        cache.modify({
                            id: `Range:${this.range.id}`,
                            fields: {
                                rangeColors(existingItemRefs, { readField }) {
                                    return existingItemRefs.filter(
                                        itemRef =>
                                            id !== readField('id', itemRef)
                                    )
                                },
                            },
                        })
                    },
                })
                .then(this.handleSuccess)
                .catch(this.handleMutationError)
        },
        mutateRangeCellsData(old_color, new_color) {
            if (!this.range.cellsData || !this.range.cellsData.data) return

            this.handleSaving()

            const cellsData = JSON.parse(JSON.stringify(this.range.cellsData))
            cellsData.data = cellsData.data.replace(
                new RegExp(old_color, 'gm'),
                new_color
            )

            delete cellsData.__typename

            const promise = this.$apollo.mutate({
                mutation: UpdateRangeCellsDataMutation,
                variables: { input: cellsData },
            })
            promise.catch(this.handleMutationError)

            return promise
        },
        handleSaving() {
            this.$emit('onSaving')
        },
        handleSuccess() {
            let timeout = 0

            if (this.range.color_preset_sync) {
                timeout = 500 // Problem with cache update reactivity

                const cache = this.$apollo.provider.defaultClient.cache
                cache.modify({
                    id: cache.identify(this.range),
                    fields: {
                        color_preset_sync(value) {
                            return !value
                        },
                    },
                })
            }

            this.$refs.modalForm.close()
            setTimeout(() => {
                this.$emit('onSaveSuccess', this.range)
            }, timeout)
        },
        handleMutationError(err) {
            this.$emit('onSaveFail', err)
        },
        getCellValue(id) {
            const cell = this.cells.find(
                c => parseInt(c.id, 10) === parseInt(id, 10)
            )

            if (!cell) return 0

            if (cell.name.includes(CELL_VALUE.OFFSUIT.match))
                return (
                    CELL_VALUE.OFFSUIT.value /
                    this.number_cells_without_diagonal
                )
            if (cell.name.includes(CELL_VALUE.SUITED.match))
                return (
                    CELL_VALUE.SUITED.value / this.number_cells_without_diagonal
                )

            return CELL_VALUE.DEFAULT.value / this.grid_size
        },
        getFilledValue({ color }) {
            const total = Object.keys(this.range_cells_data).reduce(
                (total, cell_id) => {

                    const range_cell = this.range_cells_data[cell_id]

                    if (!range_cell) return total

                    const range_cell_data = range_cell.find(data => data.color === color)

                    return !!range_cell_data && range_cell_data.mode !== 'border'
                        ? total + this.getCellValue(cell_id) * parseFloat(range_cell_data.weight)
                        : total
                },
                0
            )

            return (Math.round(total*100) / 100).toFixed(2)
        },
        getForbiddenColors({ color }) {
            return this.range_colors
                .filter(rc => rc.color !== color)
                .map(rc => rc.color)
        },
        onColorClick(range_color) {
            if (this.action_element_is_disabled) return
            if (!!this.range_color_paint && this.isPainting(range_color)) {
                this.range_color_paint = null
                this.$emit('onStopPaint')
            } else this.paint(range_color)
        },
        paint(range_color) {
            if (this.range.locked) return

            this.range_color_paint = range_color
            const mode = !!this.paint_border_checkbox_list[range_color.id]
                ? 'border'
                : 'fill'

            this.$emit('onPaint', { range_color, mode, weight: this.weight })
        },
        reset(range_color) {
            this.$emit('onResetColor', range_color)
        },
        isPainting({ id }) {
            if (!this.range_color_paint) return false
            return parseInt(id, 10) === parseInt(this.range_color_paint.id, 10)
        },
        isColorHovering({ id }) {
            if (!this.range_color_hover) return false
            return parseInt(id, 10) === parseInt(this.range_color_hover.id, 10)
        },
        upWeight() {
            if (parseFloat(this.weight) === this.weight_config.max) return
            this.weight = (parseFloat(this.weight) + this.step).toFixed(2)
        },
        downWeight() {
            if (parseFloat(this.weight) === this.weight_config.min) return
            this.weight = (parseFloat(this.weight) - this.step).toFixed(2)
        },
        handleWheel({ deltaY }) {
            if (!this.is_painting) return
            if (deltaY < 0) this.upWeight()
            else this.downWeight()
        },
        handleShortKey({ srcKey }) {
            if (!this.is_painting) return

            const range_color = this.range_color_paint

            switch (srcKey) {
                case 'copy':
                    this.exportToClipboard(range_color)
                    break
                case 'paste':
                    this.importFromClipboard(range_color)
                    break
            }
        },
        exportToClipboard(range_color) {
            exportToClipboard(this.cells_with_color_data, range_color)
            this.$notify.success(this.$t('colorPalette.success.copy'))
        },
        importFromClipboard(range_color) {
            navigator.clipboard
                .readText()
                .then(data => {
                    let cells_with_paint_pivot_data

                    try {
                        cells_with_paint_pivot_data = importFromClipboard(
                            this.cells,
                            data
                        )
                    } catch (error) {
                        console.error(error)
                    }

                    if (!cells_with_paint_pivot_data) {
                        this.$notify.error(this.$t('colorPalette.error.paste'))
                        return
                    }

                    this.$emit('onImportClipboard', {
                        range_color,
                        cells_with_paint_pivot_data,
                    })
                })
                .catch(error => {
                    console.error(error)
                })
        },
        handleWeightInputChange() {
            if (this.weight < this.weight_config.min) {
                this.weight = this.weight_config.min
            } else if (this.weight > this.weight_config.max) {
                this.weight = this.weight_config.max
            }
        },
    },
    watch: {
        weight() {
            if (!this.range_color_paint) return
            this.paint(this.range_color_paint)
        },
        range() {
            this.computePosition()
        },
    },
    data() {
        return {
            margin: 15,
            weight: 1,
            step: 0.05,
            weight_config: { min: 0.05, max: 1 },
            paint_border_checkbox_list: {},
            range_color_paint: null,
            range_color_hover: null,
        }
    },
    mounted() {
        this.$nextTick(() => this.computePosition()) // NextTick fix this.$el is null issue
    },
}
</script>

<template>
    <transition name="fade">
        <div
            class="card color-palette"
            v-shortkey.once="{ copy: ['ctrl', 'c'], paste: ['ctrl', 'v'] }"
            @shortkey="handleShortKey"
            @wheel="handleWheel"
        >
            <div class="card-body">
                <!-- <span class="card-title">
                    {{ $t('colorPalette.title') }}
                </span> -->

                <transition name="fade" mode="out-in">
                    <span class="weight_slider" v-if="!range.locked && !action_element_is_disabled" >
                        <label>
                            {{ $t('common.weight') }}
                        </label>
                        <input
                            type="range"
                            class="form-range"
                            v-model="weight"
                            :step="step"
                            :min="weight_config.min"
                            :max="weight_config.max"
                        />

                        <div
                            class="btn-group weight_btn_group ml-2"
                            ref="weight_btn_btns"
                        >
                            <span
                                class="btn btn-sm btn-light"
                                @click="downWeight"
                            >
                                <i class="zmdi zmdi-minus"></i>
                            </span>

                            <input
                                type="number"
                                class="form-control"
                                v-model="weight"
                                :step="step"
                                :min="weight_config.min"
                                :max="weight_config.max"
                                @change="handleWeightInputChange"
                            />

                            <span
                                class="btn btn-sm btn-light"
                                @click="upWeight"
                            >
                                <i class="zmdi zmdi-plus"></i>
                            </span>
                        </div>
                    </span>
                </transition>

                <div class="actions" v-if="!action_element_is_disabled">
                    <transition name="fade" mode="out-in">
                        <a
                            href="#"
                            class="btn btn-light btn-sm"
                            v-if="!range.locked"
                            @click="addRangeColor"
                        >
                            <i class="zmdi zmdi-plus"></i>
                            {{ $t('common.action.add') }}
                        </a>
                    </transition>
                </div>

                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>{{ $t('colorPalette.table.color') }}</th>
                            <th v-if="!action_element_is_disabled">{{ $t('colorPalette.table.border') }}</th>
                            <th>{{ $t('colorPalette.table.legend') }}</th>
                            <th>
                                <!-- % {{ $t('colorPalette.table.fill') }} -->
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="range_color in range_colors"
                            :key="range_color.id"
                            :class="{ selected: isPainting(range_color) }"
                            @mouseover="range_color_hover = range_color"
                            @mouseleave="range_color_hover = null"
                        >
                            <td
                                class="text-center"
                                @click="onColorClick(range_color)"
                            >
                                <div
                                    class="btn btn-link btn-color"
                                    :style="{
                                        backgroundColor: range_color.color,
                                    }"
                                ></div>
                            </td>
                            <td v-if="!action_element_is_disabled" class="text-center">
                                <input
                                    type="checkbox"
                                    v-model="
                                        paint_border_checkbox_list[
                                            range_color.id
                                        ]
                                    "
                                    @change="paint(range_color)"
                                />
                            </td>
                            <td
                                class="text-center"
                                @click="onColorClick(range_color)"
                            >
                                {{ range_color.legend }}
                            </td>
                            <td
                                class="text-center"
                                @click="onColorClick(range_color)"
                            >
                                <span class="badge badge badge-dark">
                                    {{ getFilledValue(range_color) }}%
                                </span>
                            </td>
                            <td class="text-center">
                                <transition name="fade" mode="out-in">
                                    <b-dropdown
                                        v-if="!range.locked &&!action_element_is_disabled"
                                        variant="link"
                                        toggle-class="text-decoration-none"
                                        no-caret
                                    >
                                        <template
                                            #button-content
                                            class="text-white"
                                        >
                                            <i class="zmdi zmdi-more-vert"></i>
                                        </template>

                                        <b-dropdown-item
                                            href="#"
                                            @click="
                                                updateRangeColor(range_color)
                                            "
                                        >
                                            {{ $t('common.action.edit') }}
                                            <i
                                                class="
                                                    zmdi zmdi-edit
                                                    float-right
                                                "
                                            ></i>
                                        </b-dropdown-item>

                                        <b-dropdown-divider></b-dropdown-divider>

                                        <b-dropdown-item
                                            href="#"
                                            @click="
                                                exportToClipboard(range_color)
                                            "
                                        >
                                            {{ $t('colorPalette.action.copy') }}
                                            <i
                                                class="
                                                    zmdi zmdi-upload
                                                    float-right
                                                "
                                            ></i>
                                        </b-dropdown-item>

                                        <b-dropdown-item
                                            href="#"
                                            @click="
                                                importFromClipboard(range_color)
                                            "
                                        >
                                            {{
                                                $t('colorPalette.action.paste')
                                            }}
                                            <i
                                                class="
                                                    zmdi zmdi-download
                                                    float-right
                                                "
                                            ></i>
                                        </b-dropdown-item>

                                        <b-dropdown-divider></b-dropdown-divider>

                                        <b-dropdown-item
                                            href="#"
                                            @click="
                                                confirmResetRangeColor(
                                                    range_color
                                                )
                                            "
                                        >
                                            {{ $t('common.action.reset') }}
                                            <i
                                                class="
                                                    zmdi zmdi-format-color-reset
                                                    text-warning
                                                    float-right
                                                "
                                            ></i>
                                        </b-dropdown-item>

                                        <b-dropdown-item
                                            href="#"
                                            @click="
                                                confirmDeleteRangeColor(
                                                    range_color
                                                )
                                            "
                                        >
                                            {{ $t('common.action.delete') }}
                                            <i
                                                class="
                                                    zmdi zmdi-delete
                                                    text-danger
                                                    float-right
                                                "
                                            ></i>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </transition>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <transition v-if="!action_element_is_disabled" name="fade" mode="out-in">
                    <div v-if="range.color_preset_sync" class="text-success">
                        <i class="zmdi zmdi-refresh-sync"></i>
                        {{ $t('colorPalette.success.sync') }}
                    </div>
                    <div v-else class="text-danger">
                        <i class="zmdi zmdi-refresh-sync-off"></i>
                        {{ $t('colorPalette.error.sync') }}
                    </div>
                </transition>
            </div>

            <!-- TODO : To refactor with Setting ColorPreset.vue component -->
            <ModalForm
                ref="modalForm"
                size="lg"
                :title="$t('colorPalette.form')"
            >
                <template #form-content="{ model }">
                    <div class="row">
                        <div class="col-6">
                            <label>{{ $t('common.legend') }}</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="model.legend"
                            />
                        </div>
                        <div class="col-6">
                            <!-- <label>{{ $t("common.color") }}</label> -->
                            <InputColor
                                v-model="model.color"
                                :forbidden-colors="getForbiddenColors(model)"
                                inline
                            ></InputColor>
                        </div>
                    </div>
                </template>
            </ModalForm>

            <ModalDeleteConfirmation
                ref="modalDelete"
            ></ModalDeleteConfirmation>
            <ModalConfirmation
                ref="modalConfirm"
                type="danger"
            ></ModalConfirmation>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
.card {
    background-color: rgba(0, 0, 0, 0.97);
    position: absolute;
    z-index: 2;
    margin: 0;
    left: 0;
    top: 0;

    .actions {
        position: static;
        float: right;
    }
}

tr.selected {
    background-color: rgba(255, 255, 255, 0.2);
    border: #fff solid 2px;
}

.weight_slider {
    position: relative;

    label,
    .value {
        display: inline-block;
        text-align: center;
    }

    label {
        width: 50px;
    }

    .value {
        width: 30px;
    }

    input {
        width: 60px;
    }
}

.table {
    td {
        padding: 0rem 0.5rem;
        vertical-align: middle;

        .btn-link {
            padding: 0rem 0.5rem;
        }
    }
}

.btn-color {
    height: 25px;
    width: 100%;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    text-align: center;
    height: 27px;
    -moz-appearance: textfield;
}
</style>
