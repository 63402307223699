import { sendRequest } from './fetch'

const url = `${process.env.API_URL}/${process.env.AUTH_PATH}`,
    login = credentials => {
        return new Promise((resolve, reject) => {
            sendRequest(`${url}/login`, {
                method: 'POST',
                body: JSON.stringify(credentials),
            })
                .then(({ token, user }) => resolve({ token, user }))
                .catch(reject)
        })
    },
    logout = token => {
        return new Promise((resolve, reject) => {
            sendRequest(`${url}/logout`, { token }).then(resolve).catch(reject)
        })
    }

export { login, logout }
