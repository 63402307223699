<script>
export default {}
</script>

<template>
    <main id="screen__error" class="main" data-sa-theme="11">
        <section class="error">
            <div class="error__inner">
                <router-view></router-view>
            </div>
        </section>
    </main>
</template>

<style lang="scss">
#screen__error {
    .content {
        & > div {
            // Pour la transition slide left/right
            transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
        }
    }
}
</style>
