/**
 * @deprecated since 1.0.0-beta.35
 */
// import * as electron_log from 'electron-log'
import env from './env'

const level = env.isDevelopment() || env.isDebugBuild() ? 'silly' : 'info'
// , defaultLogger = electron_log.create("DefaultLogger")

// defaultLogger.transports.file.level = level
// defaultLogger.transports.console.level = level

export const log = console.log //electron_log
export const init = name => {
    // const logger = !!name ? electron_log.create(name) : defaultLogger

    // logger.transports.file.level = level
    // logger.transports.console.level = level

    // Object.assign(console, logger.functions)

    return console.log
}
