<script>
import { DateTime } from 'luxon'
import { log } from '/common/logger'
import env from '/common/env'

import { setupVueI18nMessages } from '/renderer/config/i18n'

import { logout } from '/renderer/lib/api/auth'
import { is_valid_subscription } from '/renderer/lib/api/subscription'
import tokenService from '/renderer/lib/keychain/token'
import themeColorList from '/renderer/lib/appTheme'

import GetCellsQuery from '/renderer/apollo/GetCellsQuery.graphql'

export default {
    i18n: setupVueI18nMessages(),
    apollo: {
        cells: {
            manual: true,
            query: GetCellsQuery,
            fetchPolicy: 'no-cache',
            skip() {
                return !this.preloading
            },
            result({ data: { cells } }) {
                this.$rangeManager.cells = cells
                this.$rangeManager.generate()
                this.preloading = false
            },
        },
    },
    beforeRouteUpdate(to, from, next) {
        const toDepth = to.path.split('/').length
        const fromDepth = from.path.split('/').length
        this.transitionName = 'fade'

        if (toDepth !== fromDepth)
            this.transitionName =
                toDepth < fromDepth ? 'slide-right' : 'slide-left'

        this.checkDisplay(to)

        next()
    },
    computed: {
        current_year() {
            return DateTime.local().toFormat('yyyy')
        },
        log_path() {
            return log.transports.file.getFile().path
        },
        app_user() {
            return this.$configuration.get('app_user')
        },
        style_theme() {
            if (!this.theme || !this.theme.color) return

            const { color, mode, opacity } = this.theme

            return {
                backgroundColor: color,
                mixBlendMode: mode || 'color',
                opacity: opacity || 1,
            }
        },
    },
    methods: {
        checkDisplay(route) {
            this.displayHeader =
                undefined === route.meta.displayHeader
                    ? this.displayHeader
                    : route.meta.displayHeader
            this.displayFooter =
                undefined === route.meta.displayFooter
                    ? this.displayFooter
                    : route.meta.displayFooter
        },
        installUpdate() {
            this.$ipc.send('install-update')
        },
        importFeedbackScript() {
            const script = document.createElement('script')
            script.setAttribute('type', 'text/javascript')
            script.setAttribute('src', process.env.FEEDBACK_SCRIPT_URL)
            document.body.appendChild(script)
        },
        openReleasePage() {}, // @deprecated since beginning
        openLogsFolder() {}, // @deprecated since 1.0.0-beta.35
        logout() {
            tokenService
                .get()
                .then(logout)
                .then(tokenService.clear())
                .then(() => this.$router.push({ name: 'login' }))
                .catch(this.$notify.error)
        },
        setTheme(theme) {
            this.$configuration.set('theme', theme)
            this.theme = theme
        },
        previewTheme(theme) {
            this.theme = theme
        },
        unPreviewTheme() {
            this.theme = this.$configuration.get('theme')
        },
        checkSubscription() {
            let interval = setInterval(() => {
                console.debug('Software.checkSubscription')

                tokenService
                    .get()
                    .then(is_valid_subscription)
                    .catch(() => {
                        clearInterval(interval)
                        tokenService
                            .clear()
                            .then(() =>
                                this.$router.push({
                                    name: 'login',
                                    query: { message: 'common.error.logout' },
                                })
                            )
                    })
            }, process.env.APP_CHECK_SUBSCRIPTION_INTERVAL || 5000)
        },
        onlineStatusChanged() {
            if (navigator.onLine) {
                clearTimeout(this.offlineTimeout)
                return
            } else if (!!this.offlineTimeout) return

            this.offlineTimeout = setTimeout(() => {
                tokenService
                    .clear()
                    .then(() =>
                        router.push({
                            name: 'login',
                            query: { message: 'common.error.logout' },
                        })
                    )
            }, process.env.APP_OFFLINE_MAX_TIME || 5000)
        },
    },
    data() {
        return {
            offlineTimeout: null,
            theme: null,
            themeList: themeColorList,
            transitionName: 'fade',
            displayHeader: true,
            displayFooter: true,
            menuOpen: false,
            preloading: true,
            isPbe: env.isPbe()
        }
    },
    created() {
        this.theme = this.$configuration.get('theme')
    },
    mounted() {
        this.checkDisplay(this.$route)
        this.importFeedbackScript()
        this.checkSubscription()

        this.$app.user = this.app_user

        window.addEventListener('online', this.onlineStatusChanged)
        window.addEventListener('offline', this.onlineStatusChanged)
    },
    destroyed() {
        window.removeEventListener('online', this.onlineStatusChanged)
        window.removeEventListener('offline', this.onlineStatusChanged)
    },
}
</script>

<template>
    <main id="screen__software" class="main" data-sa-theme="11">
        <div id="screen__overlay" :style="style_theme"></div>

        <!-- Header -->
        <header class="header" v-if="displayHeader">
            <div class="navigation-trigger" @click="menuOpen = !menuOpen">
                <i class="zmdi zmdi-menu"></i>
            </div>

            <div class="logo">
                <router-link to="/">
                    <img
                        class="img img-responsive img-logo"
                        src="/renderer/assets/img/logo.png"
                    />
                    <img
                        class="img img-responsive img-logo-full"
                        src="/renderer/assets/img/logo_full.png"
                    />
                </router-link>
                <span v-if="isPbe" class="badge badge-warning ml-3">PBE</span>
            </div>

            <breadcrumb></breadcrumb>

            <div class="top-nav">
                <LangSwitcher size="sm"></LangSwitcher>

                <b-dropdown
                    class="theme-switcher"
                    size="sm"
                    variant="light"
                    toggle-class="text-decoration-none"
                    no-caret
                >
                    <template #button-content>
                        <i class="zmdi zmdi-palette"></i>
                    </template>

                    <b-dropdown-item
                        v-for="theme in themeList"
                        :key="theme.color"
                        @click="setTheme(theme)"
                    >
                        <span
                            class="dropdown-item-theme"
                            @mouseenter="previewTheme(theme)"
                            @mouseleave="unPreviewTheme"
                        >
                            <template v-if="!theme.color">{{
                                $t(`common.default`)
                            }}</template>
                            <template v-else>
                                <i
                                    class="zmdi zmdi-circle"
                                    :style="{
                                        color: theme.color,
                                        border: 'white solid 2px',
                                        borderRadius: '100%',
                                    }"
                                ></i>
                                {{ $t(`common.colorList.${theme.name}`) }}
                            </template>
                        </span>
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </header>

        <!-- Sidebar -->
        <aside class="sidebar" :class="menuOpen ? 'toggled' : ''">
            <div class="scroll-wrapper scrollbar-inner">
                <div class="user">
                    <b-dropdown
                        size="lg"
                        variant="dark"
                        toggle-class="text-decoration-none"
                        no-caret
                    >
                        <template #button-content>
                            <div
                                class="user__info"
                                data-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img
                                    v-if="!!app_user.avatar"
                                    class="user__img"
                                    :src="app_user.avatar"
                                />
                                <i
                                    v-else
                                    class="
                                        zmdi zmdi-account-circle zmdi-hc-3x
                                        mt-2
                                        user__img
                                    "
                                ></i>

                                <div>
                                    <div class="user__name">
                                        {{ app_user.name }}
                                        <span v-if="app_user.is_admin" class="badge badge-warning">ADMIN</span>
                                    </div>
                                    <!-- <div class="user__email"></div> -->
                                </div>
                            </div>
                        </template>

                        <b-dropdown-item href="#" @click="logout">
                            {{ $t('common.action.logout') }}
                            <i
                                class="zmdi zmdi-power text-danger float-right"
                            ></i>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>

                <router-view name="menu"></router-view>
            </div>

            <!-- Footer -->
            <footer class="footer" v-if="displayFooter">
                <div class="row">
                    <div class="col-sm-12 mb-3">
                        <button
                            class="btn btn-sm btn-light btn--icon-text"
                            @click="openReleasePage"
                        >
                            v{{ $app.version }}
                        </button>
                        <!-- <button class="btn btn-sm btn-light btn--icon-text" @click="openLogsFolder">
                            <i class="zmdi zmdi-open-in-new"></i>
                            Logs
                        </button> -->
                    </div>
                    <div class="col-sm-12">
                        {{ current_year }} © {{ $app.name }}
                        <span class="text-red">&#10084;</span>
                        <a
                            id="k_logo"
                            href="https://www.malt.fr/profile/kevinunfricht"
                            target="_blank"
                        >
                            <img
                                src="/renderer/assets/img/k.png"
                                alt="Kevin UNFRICHT"
                            />
                        </a>
                    </div>
                </div>
            </footer>
        </aside>

        <!-- Content -->
        <div class="content" @click="menuOpen = menuOpen ? false : menuOpen">
            <transition :name="transitionName" mode="out-in" appear>
                <div class="preloading" v-if="preloading">
                    <ClipLoader color="#ffffff" size="60px"></ClipLoader>
                </div>

                <router-view v-else></router-view>
            </transition>
        </div>

        <!-- <slideout-panel></slideout-panel> -->
    </main>
</template>

<style lang="scss" scoped>
.sidebar {
    overflow: auto;
}

.preloading {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#k_logo {
    margin-right: 14px;

    img {
        position: absolute;
        width: 14px;
        height: auto;
        top: -10px;
        right: 10px;
        z-index: 0;
    }
}

#screen__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

@media (min-width: 1620px) {
    .img-logo {
        display: none;
    }
}

@media (max-width: 1620px) {
    .img-logo-full {
        display: none;
    }
}
</style>

<style lang="scss">
#screen__software {
    .content {
        & > * {
            // Pour la transition slide left/right
            transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
        }
    }

    .theme-switcher {
        .dropdown-item {
            padding: 0;
        }
        .dropdown-item-theme {
            display: block;
            padding: 0.5rem 1.5rem;
        }
    }
}
</style>
