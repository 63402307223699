import { sendRequest } from './fetch'

const url = `${process.env.API_URL}/${process.env.SUBSCRIPTION_PATH}`,
    is_valid_subscription = token => {
        if (!token) return Promise.reject()
        return new Promise((resolve, reject) => sendRequest(`${url}/validate`, { token }).then(is_valid => (!!is_valid ? resolve() : reject())).catch(reject))
    },
    sync_premium_boards = token => {
        if (!token) return Promise.reject()
        return new Promise((resolve, reject) => sendRequest(`${url}/premium`, { token }).then(resolve).catch(reject))
    }

export { is_valid_subscription, sync_premium_boards }