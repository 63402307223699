<script>
import { pickTextColorBasedOnBgColor } from '/renderer/lib/color'

const MAX_SEED = 10
const INTERVAL_TIME = 20

export default {
    methods: {
        rand() {
            let x = 0
            let interval = setInterval(() => {
                this.number = parseInt(Math.random() * 100, 10)
                if (x++ === MAX_SEED) window.clearInterval(interval)
            }, INTERVAL_TIME)
        },
    },
    computed: {
        style() {
            return {
                backgroundColor: this.bgColor,
                color: pickTextColorBasedOnBgColor(
                    this.bgColor,
                    this.colors.text_light,
                    this.colors.text_dark
                ),
            }
        },
    },
    data() {
        return {
            number: 0,
        }
    },
}
</script>

<template>
    <button class="btn btn-light btn-sm" @click="rand">
        <i class="zmdi zmdi-rotate-ccw"></i>
        <slot name="button-text"></slot>
        <span class="h6 ml-2">
            {{ number }}
        </span>
    </button>
</template>
