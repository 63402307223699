const CARD_COLOR_CODES = ['h', 'd', 's', 'c'], // Heart / Spike / Clover / Diamond
    OCCURENCE_MAP = { OFFSUIT: 12, SUIT: 4, PAIR: 6 },
    CODE_MAP = { OFFSUIT: 'o', SUIT: 's', PAIR: '' },
    REGEX = /\[(.*?)](.*?)\[\/.*?]/gm,
    /**
     * Eg.: Jh9h, Jd9d, Js9s, Jc9c
     */
    buildSuit = (figure_A, figure_B) => {
        const result = []
        CARD_COLOR_CODES.forEach(color_code =>
            result.push(figure_A + color_code + figure_B + color_code)
        )
        return result.join(', ')
    },
    /**
     * Eg.: 6d3h, 6s3h, 6c3h, 6h3d, 6s3d, 6c3d, 6h3s, 6d3s, 6c3s, 6h3c, 6d3c, 6s3c
     */
    buildOffsuit = (figure_A, figure_B) => {
        const result = []

        CARD_COLOR_CODES.forEach(color_code =>
            CARD_COLOR_CODES.filter(cc => cc !== color_code).forEach(
                color_code2 =>
                    result.push(figure_A + color_code2 + figure_B + color_code)
            )
        )

        return result.join(', ')
    },
    /**
     * Eg.: KdKh, KsKh, KcKh, KsKd, KcKd, KcKs
     */
    buildPair = figure => {
        const result = []
        let start_index = 1

        CARD_COLOR_CODES.forEach(color_code => {
            for (let i = start_index; i < CARD_COLOR_CODES.length; i++)
                result.push(figure + CARD_COLOR_CODES[i] + figure + color_code)

            start_index++
        })

        return result.join(', ')
    },
    buildString = object => {
        let results = []

        Object.keys(object)
            .reverse()
            .forEach(weight => {
                const is_full = parseInt(weight, 10) === 1,
                    w = (parseFloat(weight) * 100).toFixed(2),
                    prefix_string = is_full ? '' : `[${w}]`,
                    suffix_string = is_full ? '' : `[/${w}]`

                let substring = ''

                object[weight].forEach((cell_name, index) => {
                    if (index > 0) substring += ', '
                    if (cell_name.length < 3)
                        substring += buildPair(cell_name[0])
                    else if (cell_name[2] === 'o')
                        substring += buildOffsuit(cell_name[0], cell_name[1])
                    else substring += buildSuit(cell_name[0], cell_name[1])
                })

                results.push(prefix_string + substring + suffix_string)
            })

        return results.join(', ')
    },
    buildCellColor = (
        cells_with_paint_pivot_data,
        cells,
        figure_combination_name,
        weight
    ) => {
        const { id } = cells.find(c => c.name === figure_combination_name)
        cells_with_paint_pivot_data.push(
            Object.assign({ cell_id: id }, { mode: 'fill', weight })
        )
    },
    importFromClipboard = (cells, content) => {

        if (content.length === 0) return undefined

        let match,
            results = {},
            cells_with_paint_pivot_data = [],
            lastIndex = 0

        while ((match = REGEX.exec(content)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (match.index === REGEX.lastIndex) REGEX.lastIndex++
            /**
             * Match 1 = Weight tag
             * Match 2 = Content inside weight tag
             */
            const weight = (parseFloat(match[1]) / 100).toFixed(4)
            results[weight] = match[2].split(', ')
            lastIndex = REGEX.lastIndex
        }

        // End of string for Weight 1
        let last_string = content.slice(lastIndex)
        if (last_string.length > 0) {
            if (last_string.startsWith(', '))
                last_string = last_string.substring(2, last_string.length)
            results[1] = last_string.split(', ')
        }

        const weight_list = Object.keys(results)

        if (weight_list.length === 0) return undefined

        weight_list.forEach(weight => {
            const list = results[weight].sort((a, b) =>
                a[0] + a[2] > b[0] + b[2] ? 1 : -1
            )

            let occurence = 0,
                last_figure_pair,
                index = 0

            do {
                const figure_A = list[index][0],
                    figure_B = list[index][2],
                    current_figure_pair = figure_A + figure_B,
                    is_last_index = index === list.length - 1

                if (
                    !last_figure_pair ||
                    last_figure_pair === current_figure_pair ||
                    is_last_index
                )
                    occurence++

                // console.log(list, current_figure_pair, last_figure_pair, occurence, is_last_index)

                if (
                    (!!last_figure_pair &&
                        last_figure_pair !== current_figure_pair) ||
                    is_last_index
                ) {
                    switch (occurence) {
                        case OCCURENCE_MAP.SUIT:
                            // console.log(last_figure_pair + " SUIT", weight)
                            buildCellColor(
                                cells_with_paint_pivot_data,
                                cells,
                                last_figure_pair + CODE_MAP.SUIT,
                                weight
                            )
                            break
                        case OCCURENCE_MAP.OFFSUIT:
                            // console.log(last_figure_pair + " OFFSUIT", weight)
                            buildCellColor(
                                cells_with_paint_pivot_data,
                                cells,
                                last_figure_pair + CODE_MAP.OFFSUIT,
                                weight
                            )
                            break
                        case OCCURENCE_MAP.SUIT + OCCURENCE_MAP.OFFSUIT:
                            // console.log(last_figure_pair + " OFFSUIT + SUIT", weight)
                            buildCellColor(
                                cells_with_paint_pivot_data,
                                cells,
                                last_figure_pair + CODE_MAP.SUIT,
                                weight
                            )
                            buildCellColor(
                                cells_with_paint_pivot_data,
                                cells,
                                last_figure_pair + CODE_MAP.OFFSUIT,
                                weight
                            )
                            break
                        case OCCURENCE_MAP.PAIR:
                            // console.log(last_figure_pair + " PAIR", weight)
                            buildCellColor(
                                cells_with_paint_pivot_data,
                                cells,
                                last_figure_pair + CODE_MAP.PAIR,
                                weight
                            )
                            break
                        default:
                            throw new Error('pioConverter: occurence issue')
                    }
                    occurence = 1
                }

                last_figure_pair = current_figure_pair
                index++
            } while (index < list.length)
        })

        return cells_with_paint_pivot_data
    },
    exportToClipboard = (cells, { color }) => {
        const objectToClipboard = {}

        cells.forEach(cell => {
            cell.colors.forEach(range_color => {
                const { pivot } = range_color

                if (range_color.color !== color || pivot.mode !== 'fill') return
                if (!objectToClipboard[pivot.weight])
                    objectToClipboard[pivot.weight] = []

                objectToClipboard[pivot.weight].push(cell.name)
            })
        })

        navigator.clipboard.writeText(buildString(objectToClipboard))
    }

export { importFromClipboard, exportToClipboard }
