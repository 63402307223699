<script>
import { setupVueI18nMessages } from '/renderer/config/i18n'
import GetColorPresetsQuery from '/renderer/apollo/color_preset/GetColorPresetsQuery.graphql'
import AddColorPresetMutation from '/renderer/apollo/color_preset/AddColorPresetMutation.graphql'
import UpdateColorPresetMutation from '/renderer/apollo/color_preset/UpdateColorPresetMutation.graphql'
import DeleteColorPresetMutation from '/renderer/apollo/color_preset/DeleteColorPresetMutation.graphql'

import fr from '/renderer/lang/components/screens/software/dashboard/fr.json'
import en_GB from '/renderer/lang/components/screens/software/dashboard/en_GB.json'
const translations = { fr, en_GB }

const getDefaultModel = () => ({ name: null, palette: [] })

export default {
    i18n: setupVueI18nMessages(translations),
    props: {
        active: { type: Boolean, default: false },
    },
    apollo: {
        color_presets: { query: GetColorPresetsQuery },
    },
    computed: {
        is_update() {
            return !!this.model.id
        },
        column_list() {
            return [
                { label: this.$t('common.name'), field: 'name' },
                { label: this.$t('common.colors'), field: 'color' },
                { label: '', field: 'action-cell' },
            ]
        },
        column_preset_list() {
            return [
                { label: this.$t('common.legend'), field: 'legend' },
                { label: this.$t('common.color'), field: 'color' },
                { label: '', field: 'action-cell' },
            ]
        },
    },
    methods: {
        showForm(model) {
            if (!!model)
                this.model = Object.assign({}, model, {
                    palette: JSON.parse(model.palette),
                })

            delete this.model.originalIndex
            delete this.model.vgt_id
            delete this.model.__typename

            this.show_form = true
        },
        submitForm() {
            const doMutation = this.is_update
                    ? this.mutateColorPresetUpdate
                    : this.mutateColorPresetAdd,
                model = Object.assign({}, this.model, {
                    palette: JSON.stringify(this.model.palette),
                })

            this.saving = true
            doMutation(model)
                .then(() => {
                    this.show_form = false
                    this.model = getDefaultModel()
                })
                .catch(this.$notify.error)
                .finally(() => (this.saving = false))
        },
        cancelForm() {
            this.show_form = false
            this.model = getDefaultModel()
        },
        addPaletteColor() {
            this.$refs.modalForm.show()
            this.$refs.modalForm.onSubmit(model => {
                delete model.originalIndex
                delete model.vgt_id

                this.model.palette.push(model)
                this.$refs.modalForm.close()
            })
        },
        updatePaletteColor(palette_color) {
            this.$refs.modalForm.show(palette_color)
            this.$refs.modalForm.onSubmit(model => {
                delete model.originalIndex
                delete model.vgt_id

                const index = this.model.palette.findIndex(
                    ({ color }) => color === palette_color.color
                )
                this.model.palette.splice(index, 1, model)
                this.$refs.modalForm.close()
            })
        },
        deletePaletteColor(palette_color) {
            this.$refs.modalDelete.show(palette_color)
            this.$refs.modalDelete.onDelete(palette_color => {
                const index = this.model.palette.findIndex(
                    ({ color }) => color === palette_color.color
                )
                this.model.palette.splice(index, 1)
                this.$refs.modalForm.close()
            })
        },
        deletePreset(preset) {
            this.$refs.modalDelete.show(preset)
            this.$refs.modalDelete.onDelete(({ id }) => {
                this.mutateColorPresetDelete(id).catch(this.$notify.error)
            })
        },
        getForbiddenColors({ color }) {
            return this.model.palette
                .filter(p => p.color !== color)
                .map(p => p.color)
        },
        mutateColorPresetAdd(input) {
            return this.$apollo.mutate({
                mutation: AddColorPresetMutation,
                variables: { input },
                update: (cache, { data }) => {
                    const query = GetColorPresetsQuery
                    cache.modify({
                        fields: {
                            color_presets(existingItems = []) {
                                const newItemRef = cache.writeQuery({
                                    query,
                                    data,
                                })
                                return [...existingItems, newItemRef]
                            },
                        },
                    })
                },
            })
        },
        mutateColorPresetUpdate(input) {
            const promise = this.$apollo.mutate({
                mutation: UpdateColorPresetMutation,
                variables: { input },
            })

            promise.then(this.reloadCache)

            return promise
        },
        mutateColorPresetDelete(id) {
            const promise = this.$apollo.mutate({
                mutation: DeleteColorPresetMutation,
                variables: { id },
                update: cache => {
                    cache.modify({
                        fields: {
                            color_presets(existingItemRefs, { readField }) {
                                return existingItemRefs.filter(
                                    itemRef => id !== readField('id', itemRef)
                                )
                            },
                        },
                    })
                },
            })

            promise.then(this.reloadCache)

            return promise
        },
        reloadCache() {
            console.debug('$apollo.resetStore')
            const apolloClient = this.$apollo.provider.defaultClient
            apolloClient.resetStore().then(() => apolloClient.cache.gc())
        },
    },
    data() {
        return {
            color_presets: [],
            show_form: false,
            saving: false,
            model: getDefaultModel(),
        }
    },
}
</script>

<template>
    <b-tab :title="$t('setting.color_palette_preset.tab')" :active="active">
        <transition name="fade" mode="out-in">
            <!-- Color preset list -->
            <DataList
                ref="color_preset_datalist"
                name="color_presets"
                v-if="!show_form"
                :title="
                    $t('common.of.total', {
                        item: $t('setting.color_palette_preset.list.total'),
                    })
                        | lowercase
                        | capitalize({ onlyFirstLetter: true })
                "
                :columns="column_list"
                :list="color_presets"
                :total="color_presets.length"
                :isDynamic="false"
            >
                <template slot="action-bar">
                    <a
                        href="javascript:void(0)"
                        class="actions__item zmdi zmdi-plus"
                        :title="$t('setting.color_palette_preset.list.add')"
                        @click.prevent="showForm()"
                    >
                    </a>
                </template>

                <template slot="color" slot-scope="props">
                    <div
                        v-for="palette in JSON.parse(props.row.palette)"
                        :key="palette.color"
                        class="btn btn-link btn-color"
                        :style="{ backgroundColor: palette.color }"
                    ></div>
                </template>

                <template slot="action-cell" slot-scope="props">
                    <button
                        class="btn btn-sm btn-outline-primary"
                        @click.prevent="showForm(props.row)"
                    >
                        <i class="zmdi zmdi-edit"></i>
                    </button>
                    <button
                        class="btn btn-sm btn-outline-danger"
                        @click.prevent="deletePreset(props.row)"
                    >
                        <i class="zmdi zmdi-delete"></i>
                    </button>
                </template>
            </DataList>

            <!-- Color Palette form -->
            <div v-else>
                <div class="form-group">
                    <label for="color_preset__name" class="card-body__title">{{
                        $t('common.name')
                    }}</label>
                    <input
                        id="color_preset__name"
                        class="form-control"
                        type="text"
                        name="name"
                        required
                        v-model="model.name"
                    />
                    <i class="form-group__bar"></i>
                </div>

                <div class="form-group">
                    <DataList
                        ref="color_palette_datalist"
                        name="color_palettes"
                        :title="
                            $t('common.of.total', {
                                item: $t(
                                    'setting.color_palette_preset.list_palette.total'
                                ),
                            })
                                | lowercase
                                | capitalize({ onlyFirstLetter: true })
                        "
                        :columns="column_preset_list"
                        :list="model.palette"
                        :total="model.palette.length"
                        :isDynamic="false"
                    >
                        <template slot="action-bar">
                            <a
                                href="javascript:void(0)"
                                class="actions__item zmdi zmdi-plus"
                                :title="
                                    $t(
                                        'setting.color_palette_preset.list_palette.add'
                                    )
                                "
                                @click.prevent="addPaletteColor"
                            >
                            </a>
                        </template>

                        <template slot="color" slot-scope="props">
                            <div
                                class="btn btn-link"
                                :style="{ backgroundColor: props.row.color }"
                            ></div>
                        </template>

                        <template slot="action-cell" slot-scope="props">
                            <button
                                class="btn btn-sm btn-outline-primary"
                                @click.prevent="updatePaletteColor(props.row)"
                            >
                                <i class="zmdi zmdi-edit"></i>
                            </button>
                            <button
                                class="btn btn-sm btn-outline-danger"
                                @click.prevent="deletePaletteColor(props.row)"
                            >
                                <i class="zmdi zmdi-delete"></i>
                            </button>
                        </template>
                    </DataList>
                </div>

                <div
                    class="
                        form-group
                        text-right
                        software__container--offset-element
                    "
                >
                    <button
                        type="button"
                        class="btn btn-link"
                        @click="cancelForm"
                    >
                        <i class="zmdi zmdi-arrow-left"></i>
                        {{ $t('common.action.cancel') }}
                    </button>

                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        @click="submitForm"
                    >
                        <transition name="fade" mode="out-in">
                            <ClipLoader
                                v-if="saving"
                                color="#007bff"
                                :size="'14px'"
                            ></ClipLoader>
                        </transition>
                        <template v-if="is_update">
                            {{ $t('common.action.edit') }}
                        </template>
                        <template v-else>
                            {{ $t('common.action.add') }}
                        </template>
                    </button>
                </div>
            </div>
        </transition>

        <!-- TODO : To refactor with Setting ColorPalette.vue component -->
        <ModalForm
            ref="modalForm"
            size="lg"
            :title="$t('setting.color_palette_preset.list_palette.modal')"
        >
            <template #form-content="{ model }">
                <div class="row">
                    <div class="col-6">
                        <label>{{ $t('common.legend') }}</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="model.legend"
                        />
                    </div>
                    <div class="col-6">
                        <InputColor
                            v-model="model.color"
                            :forbidden-colors="getForbiddenColors(model)"
                            inline
                        ></InputColor>
                    </div>
                </div>
            </template>
        </ModalForm>

        <ModalDeleteConfirmation ref="modalDelete"></ModalDeleteConfirmation>
    </b-tab>
</template>
