import Vue from 'vue'
import Router from 'vue-router'
import tokenService from '../lib/keychain/token'

import { setupVueI18nMessages } from './i18n'

import fr from '/renderer/lang/config/router/fr.json'
import en_GB from '/renderer/lang/config/router/en_GB.json'
const translations = { fr, en_GB }

setupVueI18nMessages(translations)

Vue.use(Router)

export default new Router({
    routes: [
        {
            // Software contain the default layout
            path: '/',
            component: require('rm-layouts/Software').default,
            beforeEnter: (to, from, next) => {
                if (['logout', 'login'].includes(to.name)) {
                    next()
                    return
                }

                tokenService.get().then(token => {
                    if (!!token) next()
                    else next({ name: 'login' })
                })
            },
            children: [
                {
                    name: 'dashboard',
                    path: '', // root of software
                    components: {
                        default:
                            require('rm-screens/software/dashboard/Dashboard')
                                .default,
                        menu: require('rm-screens/software/dashboard/Menu')
                            .default,
                    },
                    children: [
                        {
                            name: 'gameboard',
                            path: 'gameboard/:id/:refresh?', // refresh is a random number, to update the route with the same id
                            props: true,
                            component: require('rm-screens/software/Gameboard')
                                .default,
                        },
                        {
                            name: 'dashboard-loading',
                            path: 'dashboard/loading',
                            component:
                                require('rm-screens/software/dashboard/Loading')
                                    .default,
                        },
                        {
                            name: 'settings',
                            path: '/settings',
                            component:
                                require('rm-screens/software/setting/Setting')
                                    .default,
                        },
                    ],
                },
            ],
        },
        {
            name: 'login',
            path: '/login',
            component: require('rm-screens/authentification/Login').default,
        },
        {
            path: '/error',
            component: require('rm-layouts/Error').default,
            children: [
                {
                    path: '404',
                    component: require('rm-screens/error/NotFound').default,
                },
            ],
        },
        { path: '*', redirect: '/error/404' },
    ],
})
