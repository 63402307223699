<script>
import { setupVueI18nMessages } from '/renderer/config/i18n'
import fr from '/renderer/lang/plugins/modal/fr.json'
import en_GB from '/renderer/lang/plugins/modal/en_GB.json'
const translations = { fr, en_GB }

export default {
    i18n: setupVueI18nMessages(translations),
    props: {
        title: { type: String },
        size: { type: String, default: 'sm' },
    },
    components: {},
    computed: {
        modal_title() {
            return this.title || this.$t('modal.form.title')
        },
        is_form_valid() {
            return true
        },
    },
    methods: {
        onSubmit(callback) {
            this.callback = callback
        },
        show(data, edit) {
            if (!!data) this.model = JSON.parse(JSON.stringify(data))
            this.edit = edit != undefined ? edit : !!data
            this.showModal = true
        },
        close() {
            this.model = {}
            this.showModal = false
            this.edit = false
        },
        handleSubmit() {
            const model = JSON.parse(JSON.stringify(this.model))

            this.callback(model)
            this.$emit('on-submit', model)
        },
    },
    data() {
        return {
            edit: false,
            showModal: false,
            model: {},
            callback: () => {},
        }
    },
}
</script>

<template>
    <b-modal
        class="modal__form"
        :title="modal_title"
        :size="size"
        v-model="showModal"
        hide-header-close
    >
        <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <slot name="form-content" :model="model"></slot>
            </div>
        </form>

        <template #modal-footer>
            <button type="button" class="btn btn-link" @click.prevent="close">
                {{ $t('common.action.cancel') }}
            </button>
            <button
                type="button"
                class="btn btn-outline-primary"
                @click.prevent="handleSubmit"
            >
                {{ $t(`common.action.${edit ? 'edit' : 'add'}`) }}
            </button>
        </template>
    </b-modal>
</template>

<style lang="scss"></style>
