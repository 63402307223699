<script>
import { setupVueI18nMessages } from '/renderer/config/i18n'

import tokenService from '/renderer/lib/keychain/token'
import { sync_premium_boards } from '/renderer/lib/api/subscription'

import GetProductsQuery from '/renderer/apollo/GetProductsQuery.graphql'
import GetGameboardsQuery from '/renderer/apollo/gameboard/GetGameboardsQuery.graphql'
import NewGameboardMutation from '/renderer/apollo/gameboard/NewGameboardMutation.graphql'
import UpdateGameboardMutation from '/renderer/apollo/gameboard/UpdateGameboardMutation.graphql'
import DeleteGameboardMutation from '/renderer/apollo/gameboard/DeleteGameboardMutation.graphql'

import ExportDataGameboardQuery from '/renderer/apollo/data/ExportDataGameboardQuery.graphql'
import ImportDataGameboardMutation from '/renderer/apollo/data/ImportDataGameboardMutation.graphql'

import { processGameboardData } from '/renderer/lib/dataImporter'

import fr from '/renderer/lang/components/screens/software/dashboard/fr.json'
import en_GB from '/renderer/lang/components/screens/software/dashboard/en_GB.json'

const translations = { fr, en_GB }
    , PRODUCT_MODEL = "App\\Models\\Product" // TODO: export to env

export default {
    i18n: setupVueI18nMessages(translations),
    apollo: {
        exportData: {
            query: ExportDataGameboardQuery,
            manual: true,
            fetchPolicy: 'no-cache',
            variables() {
                return { gameboard_id: parseInt(this.selectedBoardId, 10) }
            },
            skip() {
                return !this.runExportQuery
            },
            result({ data: { positions } }) {
                this.saveFile(positions)
            },
        },
        products: {
            query: GetProductsQuery,
            error(msg) {
                this.handleFetchError(msg)
            }
        },
        gameboards: {
            query: GetGameboardsQuery,
            watchLoading(isLoading) {
                this.handleLoading(isLoading)
            },
            result(payload) {
                this.handleResult(payload.data.gameboards)
            },
            error(msg) {
                this.handleFetchError(msg)
            },
        },
    },
    computed: {
        is_form_valid() {
            return this.canvasName.length > 0
        },
        selected_gameboard() {
            if (!this.selectedBoardId) return null
            return this.gameboards.find(gameboard => parseInt(gameboard.id, 10) === parseInt(this.selectedBoardId, 10))
        },
        products_by_sku() { // TODO : Do it from server directly
            return this.products.reduce((new_products, product) => {
                if (new_products.findIndex(p => p.sku === product.sku) === -1)
                    new_products.push(product)

                return new_products
            }, [])
        },
        my_gameboards() {
            return this.gameboards.filter(g => !g.is_premium)
        },
        my_gameboards_count() {
            return this.my_gameboards.length
        },
        premium_gameboards() {
            return this.gameboards.filter(g => g.is_premium)
        },
        premium_gameboards_count() {
            return this.premium_gameboards.length
        },
        premium_board_shop_url() {
            return process.env.PREMIUM_BOARD_SHOP_URL
        }
    },
    methods: {
        isGameboardActive(gameboard_id) {
            return (
                this.isPageActive('gameboard') &&
                parseInt(this.selectedBoardId, 10) ===
                    parseInt(gameboard_id, 10)
            )
        },
        isPageActive(page_name) {
            return page_name === this.$route.name
        },
        doPremiumSync() {
            if (this.premium_syncing) return
            this.premium_syncing = true

            tokenService
                .get()
                .then(sync_premium_boards)
                .then(() => {
                    this.premium_syncing = false
                    this.$notify.success(this.$t('dashboard.canvas.success.premium'))
                })
                .then(this.resetGameboards)
                .catch(error => this.$notify.error(error))
        },
        resetGameboards() {
            this.selectedBoardId = null
            this.$store.commit('SET_GAMEBOARD_PREMIUM', false)
            this.$apollo.queries.gameboards.refetch()
        },
        handleResult(gameboards) {
            if (!Array.isArray(gameboards)) return

            if (this.selectedBoardId !== null || gameboards.length === 0) return

            const config_selected_gameboard_id = this.$configuration.get(`selected_gameboard`),
                is_gameboard_from_config_exist = !!gameboards.find(
                    g =>
                        parseInt(g.id, 10) ===
                        parseInt(config_selected_gameboard_id, 10)
                )

            this.selectedBoardId = is_gameboard_from_config_exist
                ? config_selected_gameboard_id
                : parseInt(gameboards[0].id, 10)

            this.$store.commit('SET_GAMEBOARD_PREMIUM', this.selected_gameboard.is_premium)

            if (this.$route.name !== 'gameboard')
                this.$router.push({
                    name: 'gameboard',
                    params: { id: this.selectedBoardId },
                })
        },
        handleLoading(loading) {
            this.loading = loading
            if (!!loading) this.$router.push({ name: 'dashboard-loading' })
            else if (this.$route.name === 'dashboard-loading')
                this.$router.push({ name: 'dashboard' })
        },
        handleFetchError(err) {
            console.debug('Menu.handleFetchError', err)

            this.$notify.error(this.$t('dashboard.canvas.error.fetch'))
            this.$router.push({ name: 'dashboard' })
        },
        mutateGameboardAdd({ product_sku, name, is_premium }) {
            this.$apollo
                .mutate({
                    mutation: NewGameboardMutation,
                    variables: {
                        input: {
                            product_sku,
                            name,
                            is_premium,
                        }
                    },
                    update: (cache, { data }) => {
                        const query = GetGameboardsQuery
                        cache.modify({
                            fields: {
                                gameboards(existingItems = []) {
                                    const newItemRef = cache.writeQuery({
                                        query,
                                        data,
                                    })
                                    return [...existingItems, newItemRef]
                                },
                            },
                        })
                    },
                })
                .then(() =>
                    this.$notify.success(
                        this.$t('dashboard.canvas.success.add')
                    )
                )
                .catch(this.handleError)

            this.$refs.modalCanvasForm.close()
        },
        mutateGameboardEdit({ id, product_sku, name, is_premium }) {
            this.$apollo
                .mutate({
                    mutation: UpdateGameboardMutation,
                    variables: {
                        input: {
                            id,
                            product_sku,
                            name,
                            is_premium
                        }
                    },
                })
                .then(() =>
                    this.$notify.success(
                        this.$t('dashboard.canvas.success.edit')
                    )
                )
                .catch(this.handleError)

            this.$refs.modalCanvasForm.close()
        },
        mutateGameboardDelete({ id }) {
            this.$apollo
                .mutate({
                    mutation: DeleteGameboardMutation,
                    variables: { id },
                    update: cache => {
                        cache.modify({
                            fields: {
                                gameboards(existingItemRefs, { readField }) {
                                    return existingItemRefs.filter(
                                        itemRef =>
                                            id !== readField('id', itemRef)
                                    )
                                },
                            },
                        })
                    },
                })
                .then(() => {
                    this.selectedBoardId =
                        this.gameboards.length > 0
                            ? parseInt(this.gameboards[0].id, 10)
                            : null

                    this.$configuration.set(
                        `selected_gameboard`,
                        this.selectedBoardId
                    )

                    if (!this.selectedBoardId)
                        this.$router.push({ name: 'dashboard' })
                    else {
                        this.$store.commit('SET_GAMEBOARD_PREMIUM', this.selected_gameboard.is_premium)
                        this.$router.push({
                            name: 'gameboard',
                            params: { id: this.selectedBoardId },
                        })
                    }
                })
                .catch(this.handleError)
        },
        handleError(error) {
            this.$notify.error(error)
        },
        confirmDeletion(gameboard) {
            this.$refs.modalDelete.show(gameboard)
            this.$refs.modalDelete.onDelete(() =>
                this.mutateGameboardDelete(gameboard)
            )
        },
        showCanvasFormAdd() {
            this.$refs.modalCanvasForm.show()
            this.$refs.modalCanvasForm.onSubmit(this.mutateGameboardAdd)
        },
        showCanvasFormEdit(gameboard) {
            this.$refs.modalCanvasForm.show(gameboard)
            this.$refs.modalCanvasForm.onSubmit(this.mutateGameboardEdit)
        },
        exportGameBoard() {
            this.runExportQuery = true
        },
        importGameBoard() {
            this.$refs.importDataInputFile.click()
        },
        saveFile(data) {
            if (!this.$app.user.is_admin && this.selected_gameboard.is_premium) return

            this.runExportQuery = false

            var dataUri =
                'data:application/octet-stream;charset=utf-8,' +
                encodeURIComponent(JSON.stringify(data))
            var link = document.createElement('a')

            link.setAttribute('href', dataUri)
            link.setAttribute('download', `${this.selected_gameboard.name}.json`)
            link.click()
        },
        processUploadFile({ target: { files } }) {
            const file = files[0] || undefined

            if (!file) {
                this.$notify.error(
                    '[Menu.processUploadFile] There is no file to process'
                )
                return
            } else if (file.type != 'application/json') {
                this.$notify.error(
                    "[Menu.processUploadFile] File is not valid. '.json' file is required"
                )
                return
            }

            const reader = new FileReader()
            reader.readAsText(file, 'UTF-8')
            reader.onload = ({ target: { result } }) => this.saveData(result)
            reader.onerror = () =>
                this.$notify.error(
                    '[Menu.processUploadFile] Error reading file'
                )
        },
        saveData(data) {
            data = processGameboardData({
                id: this.selectedBoardId,
                positions: JSON.parse(data),
            })

            this.$apollo
                .mutate({
                    mutation: ImportDataGameboardMutation,
                    variables: { input: data },
                })
                .then(() => {
                    this.$notify.success(
                        this.$t('dashboard.canvas.success.import')
                    )
                    this.$router.push({
                        name: 'gameboard',
                        params: {
                            id: this.selectedBoardId,
                            refresh: Math.random(),
                        },
                    })
                })
                .catch(err => this.$notify.error(err))

            this.$refs.importDataInputFile.value = ''
        },
        selectGameboard(gameboard) {
            this.selectedBoardId = parseInt(gameboard.id, 10)
            this.$store.commit('SET_GAMEBOARD_PREMIUM', this.selected_gameboard.is_premium)
            this.$configuration.set(`selected_gameboard`, this.selectedBoardId)
        },
    },
    data() {
        return {
            GetGameboardsQuery,
            runExportQuery: false,
            loading: false,
            premium_syncing: false,
            canvasName: '',
            selectedBoardId: null,
            gameboards: [],
            saveOptions: {
                defaultPath: 'export.json',
                filters: [{ name: 'json', extensions: ['json'] }],
            },
        }
    },
}
</script>

<template>
    <div class="sidebar-menu">
        <ul v-if="loading" class="navigation">
            <li>
                <PulseLoader color="#fff"></PulseLoader>
            </li>
        </ul>
        <span v-else>
            <ul class="navigation">
                <li class="navigation__category">
                    PREMIUMS ({{ premium_gameboards_count }})
                    <a :href="premium_board_shop_url" target="_blank" :title="$t('dashboard.menu.premium.buy')">
                        <i class="zmdi zmdi-shopping-cart"></i>
                    </a>
                    <a href="#" target="_blank" :title="$t('dashboard.menu.premium.sync')" @click.prevent="doPremiumSync">
                        <i class="zmdi zmdi-refresh-alt" v-if="!premium_syncing"></i>
                        <i class="zmdi zmdi-rotate-right zmdi-hc-spin" v-else></i>
                    </a>
                </li>
                <li
                    v-for="gameboard in premium_gameboards"
                    @click="selectGameboard(gameboard)"
                    :class="{ navigation__active: isGameboardActive(gameboard.id) }"
                    :key="gameboard.id"
                >
                    <router-link
                        :to="{
                            name: 'gameboard',
                            params: { id: parseInt(gameboard.id, 10) },
                        }"
                    >
                        {{ gameboard.name | lowercase | capitalize }}
                    </router-link>
    
                    <b-dropdown
                        v-if="$app.user.is_admin || !gameboard.is_premium"
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        v-show="isGameboardActive(gameboard.id)"
                    >
                        <template #button-content class="text-white">
                            <i class="zmdi zmdi-more-vert"></i>
                        </template>
    
                        <b-dropdown-item
                            href="#"
                            @click="showCanvasFormEdit(gameboard)"
                        >
                            {{ $t('common.action.edit') }}
                            <i class="zmdi zmdi-edit float-right"></i>
                        </b-dropdown-item>
    
                        <b-dropdown-item
                            href="#"
                            @click="confirmDeletion(gameboard)"
                        >
                            {{ $t('common.action.delete') }}
                            <i class="zmdi zmdi-delete text-danger float-right"></i>
                        </b-dropdown-item>
    
                        <b-dropdown-divider></b-dropdown-divider>
    
                        <b-dropdown-item href="#" @click="exportGameBoard">
                            {{ $t('common.action.export') }}
                            <i class="zmdi zmdi-upload float-right"></i>
                        </b-dropdown-item>
    
                        <b-dropdown-item href="#" @click="importGameBoard">
                            {{ $t('common.action.import') }}
                            <i class="zmdi zmdi-download float-right"></i>
                        </b-dropdown-item>
                    </b-dropdown>
                </li>
            </ul>
            <ul class="navigation">
                <li class="navigation__category">
                    {{ $t('dashboard.menu.myboard') | uppercase }} ({{ my_gameboards_count }})
                </li>
                <li
                    v-for="gameboard in my_gameboards"
                    @click="selectGameboard(gameboard)"
                    :class="{ navigation__active: isGameboardActive(gameboard.id) }"
                    :key="gameboard.id"
                >
                    <router-link
                        :to="{
                            name: 'gameboard',
                            params: { id: parseInt(gameboard.id, 10) },
                        }"
                    >
                        {{ gameboard.name | lowercase | capitalize }}
                    </router-link>
    
                    <b-dropdown
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        v-show="isGameboardActive(gameboard.id)"
                    >
                        <template #button-content class="text-white">
                            <i class="zmdi zmdi-more-vert"></i>
                        </template>
    
                        <b-dropdown-item
                            href="#"
                            @click="showCanvasFormEdit(gameboard)"
                        >
                            {{ $t('common.action.edit') }}
                            <i class="zmdi zmdi-edit float-right"></i>
                        </b-dropdown-item>
    
                        <b-dropdown-item
                            href="#"
                            @click="confirmDeletion(gameboard)"
                        >
                            {{ $t('common.action.delete') }}
                            <i class="zmdi zmdi-delete text-danger float-right"></i>
                        </b-dropdown-item>
    
                        <b-dropdown-divider></b-dropdown-divider>
    
                        <b-dropdown-item href="#" @click="exportGameBoard">
                            {{ $t('common.action.export') }}
                            <i class="zmdi zmdi-upload float-right"></i>
                        </b-dropdown-item>
    
                        <b-dropdown-item href="#" @click="importGameBoard">
                            {{ $t('common.action.import') }}
                            <i class="zmdi zmdi-download float-right"></i>
                        </b-dropdown-item>
                    </b-dropdown>
                </li>
            </ul>
        </span>

        <ul class="navigation">
            <li class="add-canvas-item">
                <a href="#" @click="showCanvasFormAdd">
                    <i class="zmdi zmdi-plus"></i>
                    {{ $t('dashboard.canvas.new') }}
                </a>
            </li>
        </ul>

        <ul class="navigation">
            <li :class="{ navigation__active: isPageActive('settings') }">
                <router-link :to="{ name: 'settings' }">
                    <i class="zmdi zmdi-settings"></i>
                    {{ $t('dashboard.menu.settings') }}
                </router-link>
            </li>
        </ul>

        <ModalForm ref="modalCanvasForm" :title="$t('dashboard.canvas.title')">
            <template #form-content="{ model }">
                <div class="form-group">
                    <label>{{ $t('dashboard.canvas.form.name') }}</label>
                    <input type="text" class="form-control" v-model="model.name" />
                </div>

                <template v-if="$app.user.is_admin">
                    <div class="form-group">
                        <label class="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                v-model="model.is_premium"
                            />
                            <span class="custom-control-indicator"></span>
                            <span class="custom-control-description">
                                {{ $t('dashboard.canvas.form.premium') }}
                            </span>
                        </label>
                    </div>

                    <transition name="fade" mode="out-in">
                        <div class="form-group" v-if="model.is_premium">
                            <label>{{ $t('dashboard.canvas.form.product.title') }}</label>
                            <select
                                class="form-control"
                                v-model="model.product_sku"
                            >
                                <option :value="null" key="-1">
                                    {{ $t('dashboard.canvas.form.product.select') }}
                                </option>
                                <option
                                    v-for="(product, i) in products_by_sku"
                                    :key="i"
                                    :value="product.sku"
                                >
                                    {{ product.title }} - {{ product.price }}€
                                </option>
                            </select>
                        </div>
                    </transition>
                </template>
            </template>
        </ModalForm>

        <ModalDeleteConfirmation ref="modalDelete"></ModalDeleteConfirmation>

        <input
            ref="importDataInputFile"
            type="file"
            style="display: none"
            @change="processUploadFile"
        />
    </div>
</template>

<style lang="scss" scoped>

.navigation > li.navigation__category {
    color: #CCA200;
}

.navigation > li.navigation__category > a {
    display: inline;
    padding: 0;
    margin: 0.85rem 0;
}

.navigation > li.navigation__category > a > i {
    margin-left: 0.6rem;
    margin-right: 0;
}

li.add-canvas-item > a {
    border: #fff dashed 2px;
}

.dropdown {
    display: inline-block;
    position: relative;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    & > button {
        height: 100%;
    }
}
</style>

<style lang="scss">
.sidebar-menu {
    .navigation li {
        position: relative;
    }

    .dropdown {
        & > button {
            height: 100%;
            color: white;
        }
    }
}
</style>
